// export function* defaultParameters(communityRequired = false, others = {}) {
//   let params: Record<string, string> = {
//     device_id: yield select(AppSelector.selectDeviceId),
//     user_id: yield select(AppSelector.selectUserId),
//   };
//
//   if (communityRequired) {
//     const community = yield select(AppSelector.selectActiveCommunity);
//     params = {
//       ...params,
//       condition: community.name,
//     };
//   }
//
//   return {
//     ...params,
//     ...Object.keys(others)
//       .filter((k) => others[k] !== undefined)
//       .reduce((obj, key) => ({ ...obj, [key]: others[key] }), {}),
//   };
// }

const jsonToForm = (
  json: Record<string, string | number | undefined>,
  form?: FormData,
): FormData => {
  const formData = form || new FormData();

  Object.keys(json || {}).forEach((key) => {
    if (json[key]) {
      formData.append(key, json[key] as string | Blob);
    }
  });

  return formData;
};

export default {
  jsonToForm,
};
