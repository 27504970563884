import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Document, Page, pdfjs } from 'react-pdf';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-pdf/dist/esm/Page/TextLayer.css';

const PdfPreview: React.FC<{
  pdfUrl?: string;
  width?: number | string;
  icon?: () => React.ReactNode;
  title?: string;
  label?: string;
}> = ({ pdfUrl, width, icon, title, label }) => {
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(2);

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    return () => {
      pdfjs.GlobalWorkerOptions.workerSrc = null;
    };
  }, []);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="overflow-y-auto">
      {label && (
        <div className="flex items-center p-4 uppercase caption text-on-background">
          <div
            style={{
              width: '14px',
              height: '14px',
              marginRight: '8px',
            }}
          >
            {icon && icon()}
          </div>
          {label}
        </div>
      )}
      {title && <h1 className="pl-4 h1">{title}</h1>}
      <Document
        // options={{ workerSrc: 'pdf.worker.js' }}
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(err) => console.log(err)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={width}
          />
        ))}
      </Document>
    </div>
  );
};

export default PdfPreview;
