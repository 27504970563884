// @flow
import * as React from 'react';
import { IconButton, Input, MenuItem, Select } from '@mui/material';
import PlusIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Close';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import CompressIcon from '@mui/icons-material/ExpandLess';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';
import { DraftContext, PollDraft } from '../../hooks/useDraftActions';

const MakePoll: React.FC<unknown> = () => {
  const defaultValue = React.useContext(DefaultValueContext) as PollDraft;
  const [options, _setOptions] = React.useState(
    defaultValue?.options?.map((option) => option.text) || ['', ''],
  );

  const [endTimeSelectorExpanded, setEndTimeSelectorExpanded] =
    React.useState(false);

  const [endTime, _setEndTime] = React.useState<{
    day: number;
    hour: number;
    minute: number;
  }>({
    day: 1,
    hour: 1,
    minute: 0,
  });

  const { setOptions, setEndTime } = React.useContext(DraftContext);

  React.useEffect(() => {
    setOptions(options);
  }, [options, setOptions]);

  React.useEffect(() => {
    setEndTime(endTime);
  }, [endTime, setEndTime]);

  const handleChangeOption =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const newOptions = [...options];
      newOptions[index] = e.target.value.replace('\n', '');
      _setOptions(newOptions);
    };

  const handleAddOption = () => {
    if (options.length < 4) _setOptions([...options, '']);
  };

  const handleDeleteOption = () => {
    if (options.length > 2) _setOptions(options.slice(0, -1));
  };

  const renderOptions = () => {
    const renderedOptions = options.map((option, index) => {
      return (
        <div key={`option-${index}`} className="flex w-full pb-3 pl-3">
          <Input
            disableUnderline
            autoFocus
            // value={option}
            placeholder={`Choice ${index + 1}`}
            fullWidth
            inputProps={{
              maxLength: 70,
            }}
            multiline
            maxRows={2}
            defaultValue={option}
            onChange={handleChangeOption(index)}
            endAdornment={
              <div className="ml-2 h6 text-on-secondary">
                {70 - option.length}
              </div>
            }
            classes={{
              input: '!text-on-primary !subtitle1 placeholder:opacity-60',
              root: 'border border-secondary-background rounded-[5px] pl-4 pr-4',
            }}
            className="w-[410px] h-[46px]"
          />

          <div className="flex-1 h-full flex-col-center">
            {index === 1 && options.length < 4 && (
              <IconButton onClick={handleAddOption}>
                <PlusIcon className="text-brand-primary font-[13px]" />
              </IconButton>
            )}
            {index === options.length - 1 && options.length > 2 && (
              <IconButton onClick={handleDeleteOption}>
                <DeleteIcon className="text-on-secondary font-[13px]" />
              </IconButton>
            )}
          </div>
        </div>
      );
    });
    return renderedOptions;
  };

  const renderTimeSelector = (type: 'day' | 'hour' | 'minute') => {
    return (
      <Select
        value={endTime[type]}
        onChange={(e) => {
          _setEndTime({ ...endTime, [type]: e.target.value });
        }}
        sx={{
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
      >
        {type === 'day' &&
          [...new Array(6)].map((_, day) => {
            return (
              <MenuItem value={day + 1} key={`${day + 1} day`}>
                {day + 1} day{day + 1 > 1 && 's'}
              </MenuItem>
            );
          })}
        {type === 'hour' &&
          [...new Array(24)].map((_, hour) => {
            return (
              <MenuItem key={`hour-${hour}`} value={hour}>
                {hour} hour{hour > 1 && 's'}
              </MenuItem>
            );
          })}
        {type === 'minute' &&
          [...new Array(60)].map((_, minute) => {
            return (
              <MenuItem key={`min-${minute}`} value={minute}>
                {minute} minute{minute > 1 && 's'}
              </MenuItem>
            );
          })}
      </Select>
    );
  };

  const renderEndTimeSelector = () => {
    return (
      <div className="flex flex-col w-full mt-3">
        <div className="flex w-full pl-3">
          <div className="flex justify-between h6 text-on-primary w-[410px]">
            <h6>Poll length</h6>
            <h6>
              {endTime.day} day{endTime.day > 1 && 's'}
              {endTime.hour > 0 &&
                ` ${endTime.hour} hour${endTime.hour > 1 ? 's' : ''}`}
              {endTime.minute > 0 &&
                ` ${endTime.minute} minute${endTime.minute > 1 ? 's' : ''}`}
            </h6>
          </div>
          <div className="flex-1 h-full -mt-2 flex-col-center">
            <IconButton
              onClick={() =>
                setEndTimeSelectorExpanded(!endTimeSelectorExpanded)
              }
            >
              {endTimeSelectorExpanded ? (
                <CompressIcon className="text-on-primary font-[13px]" />
              ) : (
                <ExpandIcon className="text-on-primary font-[13px]" />
              )}
            </IconButton>
          </div>
        </div>
        {endTimeSelectorExpanded && (
          <div className="flex w-full">
            {renderTimeSelector('day')}
            {renderTimeSelector('hour')}
            {renderTimeSelector('minute')}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full  pl-4 pr-4 mb-2">
      <div className="w-full h-full pt-2 border rounded-lg border-secondary-background">
        {renderOptions()}
        {renderEndTimeSelector()}
      </div>
    </div>
  );
};
export default MakePoll;
