import IconButton from '@mui/material/IconButton';
import { SvgIcon } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface NavItemProps {
  pathname?: string;
  to?: string;
  icon?: React.ElementType | null;
  label: string;
  // hidden?: boolean;
  onClick?: () => void;
}

const MobileNavItem: React.FC<NavItemProps> = ({
  pathname,
  label,
  to,
  icon,
  onClick,
}) => {
  const isActive = pathname === to;

  const activeIndicatorClass = `${
    isActive
      ? ' block absolute h-full w-1 bg-brand-primary rounded-r-3xl top-0 left-0'
      : 'hidden'
  }`;
  const iconButtonClass = `!p-0 w-full h-full  flex justify-start text-brand-primary rounded-none`;
  const labelClass = `${
    isActive ? 'text-brand-primary h5' : 'text-on-primary subtitle1'
  } ml-5`;

  const navigate = useNavigate();

  const handleOnItemClick = () => () => {
    if (onClick) onClick();
    else navigate(to || '#');
  };

  return (
    <li
      role="presentation"
      className="relative w-full pt-4 pb-4 pl-6"
      onClick={handleOnItemClick()}
    >
      <div className={activeIndicatorClass} />
      <IconButton className={iconButtonClass}>
        <>
          {icon && <SvgIcon component={icon} />}
          <p className={labelClass}>{label}</p>
        </>
      </IconButton>
    </li>
  );
};

MobileNavItem.defaultProps = {
  pathname: '',
  to: '',
  icon: null,
  // hidden: true,
  onClick: undefined,
};

export default MobileNavItem;
