// @flow
import * as React from 'react';
import { Button, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useParams } from 'react-router-dom';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import SubmittingDialog from '@shared/components/dialogs/submittion-dialog/SubmittingDialog';
import CreateEventSharingOptions from '@view/create-event/components/CreateEventSharingOptions';
import CreateEventCoverUploader from '@view/create-event/components/CreateEventCoverUploader';
import CreateEventInfo from '@view/create-event/components/CreateEventInfo';
import EventDateAndTimePicker from '@view/create-event/components/EventDateAndTimePicker';
import CreateEventTermsAndAgreement from '@view/create-event/components/CreateEventTermsAndAgreement';
import useDefaultValues, {
  EventDefaultValueContext,
} from '@view/create-event/hooks/useDefaultValues';
import useDraftEvent, { EventDraftContext } from './hooks/useDraftEvent';

const CreateEvent: React.FC<any> = () => {
  const { t } = useTranslation();
  const { eventId } = useParams();

  const defaultValues = useDefaultValues(eventId);

  const [editMode, setEditMode] = React.useState(Boolean(eventId));

  const { contextValue, onSubmit, isSubmitting } = useDraftEvent(eventId);

  const [isValidated, setIsValidated] = React.useState({
    info: false,
    date: false,
    terms: false,
    cover: false,
  });

  const validate = React.useCallback(
    (key: string) => (value: boolean) => {
      setIsValidated((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    },
    [],
  );

  return (
    <WithSideBar>
      <SubmittingDialog
        show={isSubmitting}
        message="Submitting your event..."
      />
      <div className="flex justify-center w-full h-full">
        <Card className="flex flex-col items-center justify-start w-full pt-5 mt-5 h-fit max-w-[540px] overflow-auto">
          <EventDraftContext.Provider value={contextValue}>
            <EventDefaultValueContext.Provider value={defaultValues}>
              <div className="flex items-center justify-between w-full pl-5 pr-6 h3 text-on-primary mb-[55px]">
                {editMode ? t(TextKeys.EditEvent) : t(TextKeys.CreateAnEvent)}
                {!editMode && <CreateEventSharingOptions />}
              </div>
              <CreateEventCoverUploader validate={validate('cover')} />
              <CreateEventInfo validate={validate('info')} />
              <EventDateAndTimePicker validate={validate('date')} />
              <CreateEventTermsAndAgreement validate={validate('terms')} />
              <div className="w-full flex-col-center pb-[30px]">
                <Button
                  className="w-[130px] mt-[30px]"
                  variant="contained"
                  color="primary"
                  onClick={onSubmit}
                  disabled={
                    !isValidated.cover ||
                    !isValidated.date ||
                    !isValidated.info ||
                    !isValidated.terms
                  }
                >
                  {t(TextKeys.Create)}
                </Button>
              </div>
            </EventDefaultValueContext.Provider>
          </EventDraftContext.Provider>
        </Card>
      </div>
    </WithSideBar>
  );
};
export default CreateEvent;
