import * as React from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from '@data/useAppDispatch';
import { SocialEventsSelector } from '@data/social-events/socialEvents.selector';
import {
  SocialEvent,
  SocialEventMember,
} from '@data/social-events/socialEvents.model';
import {
  deleteSocialEvent,
  fetchSocialEvent,
  joinSocialEvent,
  leaveSocialEvent,
} from '@data/social-events/socialEvents.thunk';
import { AppSelector } from '@data/app/app.selector';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@lib/configureStore';
import { DropDownItem } from '@shared/components/dropDown-menu/DropDownMenu';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import { LoadingState } from '../../../models/Loading';

export type useSocialEventDetailsReturnType = {
  title: string;
  cover: string;
  description: string;
  location: string;
  numberOfMembers: number;
  admin: string;
  members: SocialEventMember[];
  userIsJoined: boolean;
  userIsAdmin: boolean;
  onJoin: () => () => void;
  onLeave: () => () => void;
  onLoading: boolean;
  time: {
    startTime: string;
    endTime: string;
  };
  options: {
    label: string;
  }[];
  onOptionClick: (option: DropDownItem) => void;
  loadSocialEvent: LoadingState;
};

const useSocialEventDetails = (
  id?: string,
): useSocialEventDetailsReturnType => {
  const dispatch = useAppDispatch();
  const [onLoading, setOnLoading] = React.useState(false);

  const socialEvent: SocialEvent | undefined = useSelector((state: RootState) =>
    SocialEventsSelector.selectById(state, id || ''),
  );

  const userId = useSelector(AppSelector.selectUserId);
  const userIsAdmin = React.useMemo(
    () => socialEvent?.adminId === userId,
    [socialEvent, userId],
  );

  const navigate = useNavigate();

  const [loadSocialEvent, setLoadSocialEvent] = React.useState<LoadingState>(
    LoadingState.IDLE,
  );

  React.useEffect(() => {
    if (loadSocialEvent === LoadingState.IDLE && id) {
      setLoadSocialEvent(LoadingState.LOADING);
      dispatch(fetchSocialEvent({ eventId: id }))
        .then(() => {
          setLoadSocialEvent(LoadingState.SUCCEEDED);
        })
        .catch(() => {
          setLoadSocialEvent(LoadingState.FAILED);
        });
    }
  }, [dispatch, id, loadSocialEvent]);

  const onJoin = () => (): void => {
    if (!id) return;
    setOnLoading(true);
    dispatch(joinSocialEvent({ eventId: id })).then(() => {
      setOnLoading(false);
    });
  };

  const onLeave = () => () => {
    setOnLoading(true);
    dispatch(leaveSocialEvent({ eventId: Number(id) })).then(() => {
      setOnLoading(false);
    });
  };

  const options = React.useMemo(() => {
    return [
      {
        label: 'Edit Event Information',
      },
      {
        label: 'Delete Event',
      },
    ];
  }, []);

  const onOptionClick = React.useCallback(
    (option) => {
      if (option.includes('Delete')) {
        // @TODO: add confirmation modal and pending dialog
        setOnLoading(true);
        dispatch(deleteSocialEvent({ eventId: Number(id) }))
          .then(() => {
            navigate('/');
            setOnLoading(false);
          })
          .catch(() => {
            setOnLoading(false);
          });
      } else if (option.includes('Edit')) {
        navigate(endpointIDReplacer(Endpoints.EDIT_EVENT, id));
      }
    },
    [dispatch, id, navigate],
  );

  return {
    title: socialEvent?.info.title || '',
    cover: socialEvent?.info.cover || '',
    description: socialEvent?.info.description || '',
    location: socialEvent?.info.location || 'unset',
    numberOfMembers: socialEvent?.info.numberOfParticipants || 0,
    admin: socialEvent?.admin || 'unknown',
    members: socialEvent?.members || [],
    userIsJoined: socialEvent?.userIsJoined || false,
    userIsAdmin,
    onJoin,
    onLeave,
    onLoading,
    time: {
      startTime: socialEvent?.time.startTime || '',
      endTime: socialEvent?.time.endTime || '',
    },
    options,
    onOptionClick: onOptionClick as (option: DropDownItem) => void,
    loadSocialEvent,
  };
};

export default useSocialEventDetails;
