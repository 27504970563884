import { SupportGroup } from '@data/support-groups/supportGroups.model';
import { ReactComponent as GroupSvg } from '@assets/icons/group.svg';
import React from 'react';
import { GroupedDropDownItems } from '@shared/components/dropDown-menu/models/DropDownItems';
import { makePostSharingOptions } from '@shared/components/make-post/models/MakePostDraft';
import { FeedSharingOption } from '../../../../../models/Feed';

const useSharingOptions = (myGroups: SupportGroup[], channelId?: number) => {
  const [sharingOptions, setSharingOptions] = React.useState<
    GroupedDropDownItems<FeedSharingOption | number>
  >(makePostSharingOptions);

  React.useEffect(() => {
    if (myGroups && myGroups.length > 0) {
      setSharingOptions((prev) => ({
        ...prev,
        'My Groups': myGroups.reduce((groups, group) => {
          return {
            ...groups,
            [group.channelId]: {
              label: group.info.title,
              key: group.channelId,
              Icon: <GroupSvg className="flex-1 h-5 fill-on-secondary" />,
            },
          };
        }, {}),
      }));
    }
  }, [myGroups]);

  const defaultSharingOption =
    channelId === 0 || !channelId
      ? `unassigned-${FeedSharingOption.PUBLIC}`
      : `My Groups-${channelId}`;

  const getSharingOptionKey = React.useCallback(
    (selectedSharingOption) => {
      try {
        const parent = selectedSharingOption?.split('-')[0];
        const child = selectedSharingOption?.split('-')[1];

        if (parent === 'My Groups')
          return [
            FeedSharingOption.CHANNEL,
            sharingOptions[parent][child]?.key as number,
          ];

        return [sharingOptions[parent][child]?.key, undefined];
      } catch (e) {
        return [FeedSharingOption.PUBLIC, undefined];
      }
    },
    [sharingOptions],
  );

  return { sharingOptions, defaultSharingOption, getSharingOptionKey };
};

export default useSharingOptions;
