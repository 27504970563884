// @flow
import * as React from 'react';
import { Card } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReactComponent as StarSvg } from '@assets/icons/star.svg';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import LoadingSpinner from '@hooks/use-loading/useLoading/components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import { SupportGroupMember } from '@data/support-groups/supportGroups.model';
import {
  SocialEvent,
  SocialEventMember,
} from '@data/social-events/socialEvents.model';
import { LoadingButton } from '@mui/lab';
import Members from '../../../components/group-card/components/Members';
import SocialEventDateAndTitle from '../../../components/social-event/SocialEventDateAndTitle';
import Avatar from '../../../components/avatar';
import DropDownMenu, {
  DropDownItem,
} from '../../../components/dropDown-menu/DropDownMenu';

type InfoProps = {
  type?: 'group' | 'event';
  title?: string;
  description?: string;
  numberOfMembers?: number;
  admins?: string[];
  admin?: string;
  members?: SupportGroupMember[] | SocialEventMember[];
  userIsJoined?: boolean;
  onJoin: () => () => void;
  onLeave: () => () => void;
  onLoading?: boolean;
  time?: {
    startTime: string;
    endTime: string;
  };
  location?: string;
  loading?: boolean;
  userIsAdmin?: boolean;
  options?: DropDownItem[];
  onOptionClick?: (option) => void;
};

const Info: React.FC<InfoProps> = (props) => {
  const {
    title,
    description,
    numberOfMembers,
    userIsJoined,
    onLoading,
    type = 'group',
    time,
    location,
    loading,
    userIsAdmin,
    options = [],
    onOptionClick,
  } = props;

  const { t } = useTranslation();

  const renderTitle = () => {
    if (loading)
      return (
        <Skeleton
          variant="text"
          width="100%"
          height={50}
          className="absolute w-full pl-6 h-[50px] max-w-[540px]  rounded-md  top-[100px]"
        />
      );
    return (
      <h1 className="absolute flex items-center w-full pl-6 h-[50px] max-w-[540px] bg-white/90 rounded-md h3 top-[100px]">
        {title}
      </h1>
    );
  };

  const renderEventDetails = () => {
    if (type !== 'event') return null;
    return (
      <div className="flex flex-col -mt-2 -ml-5">
        <SocialEventDateAndTitle
          event={
            {
              info: {
                title,
              },
              time,
            } as SocialEvent
          }
          loading={loading}
        />
        <div className="flex mt-6 mb-5 ml-5">
          <LocationOnIcon className="!text-[#70D0C6] !w-4" />
          {loading ? (
            <Skeleton variant="text" width="10%" height={20} />
          ) : (
            <a
              href={location}
              target="_blank"
              className="mt-1 ml-1 text-link body1"
              rel="noreferrer"
            >
              {location}
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderOptions = () => {
    if (!userIsAdmin || options.length === 0) return null;
    return (
      <div className="absolute top-4 right-2">
        <DropDownMenu options={options} onClick={onOptionClick} />
      </div>
    );
  };

  const renderAbout = () => {
    return (
      <Card className="relative w-full pt-2 pb-5 pl-5 pr-8 mt-8 sm:pt-5">
        {renderOptions()}
        {renderEventDetails()}
        {!loading ? (
          <h1 className="h3">{t(TextKeys.About)}</h1>
        ) : (
          <Skeleton variant="text" width={70} />
        )}
        <p className="h-full mt-2 sm:overflow-auto body1 text-on-primary">
          {!loading ? (
            description
          ) : (
            <Skeleton variant="text" width="100%" height={20} />
          )}
        </p>
      </Card>
    );
  };

  const renderMembers = () => {
    const { members } = props;

    return (
      <Card className="relative w-full pt-4 pb-4 pl-5 pr-5 mt-5 max-h-[100%] sm:max-h-[50%]">
        {!loading ? (
          <h1 className="mb-3 h5">
            {type === 'event'
              ? t(TextKeys.AttendingThisEvent)
              : t(TextKeys.InThisGroup)}
          </h1>
        ) : (
          <Skeleton variant="text" width={90} />
        )}
        <Members
          numberOfMembers={numberOfMembers || 0}
          loading={loading}
          className="border border-on-primary border-[2px] right-5 top-4"
        />
        {userIsJoined && (
          <div className="flex flex-wrap justify-start w-full h-full overflow-auto ">
            {members && !loading ? (
              members?.map((member: SupportGroupMember | SocialEventMember) => (
                <Avatar
                  src={member.avatar}
                  key={`member-${member.id}`}
                  withOnlineSign
                  online={member.isOnline}
                  nickName={member.nickname}
                  isAdmin={member.isAdmin}
                  isFriend={member.isFriend}
                  className="m-7"
                />
              ))
            ) : (
              <Stack spacing={2} className="p-4" direction="row">
                <Skeleton variant="circular" width={70} height={70} />
                <Skeleton variant="circular" width={70} height={70} />
                <Skeleton variant="circular" width={70} height={70} />
                <Skeleton variant="circular" width={70} height={70} />
              </Stack>
            )}
          </div>
        )}
      </Card>
    );
  };

  const renderAdmins = () => {
    const { admins, admin } = props;
    if (!admins && !admin) return null;
    const adminsList = admins?.length > 0 ? admins : [admin];
    return (
      <Card className="w-full pt-4 pb-5 pl-10 pr-6 mt-8 sm:mb-8 flex-col-center">
        <div className="flex items-center w-full h-full body1 text-on-secondary">
          <StarSvg className="w-5 h-5 mr-2" />
          {!loading ? (
            adminsList?.map((admin, index) => {
              return (
                <div key={admin}>
                  <p className="text-brand-primary">{admin}</p>
                  {index !== adminsList.length - 1 && ','}
                  {adminsList.length > 1 ? ' are ' : ' is '}
                  the admin
                  {adminsList?.length > 1 ? 's ' : ' '}
                  {type === 'group' ? 'of this group' : 'of this event'}
                </div>
              );
            })
          ) : (
            <Skeleton variant="text" width="100%" height={20} />
          )}
        </div>
        <p className="block pt-5 pb-2 button text-brand-primary sm:hidden">
          {onLoading ? (
            <LoadingSpinner />
          ) : (
            <> {userIsJoined ? 'Leave group' : 'Join group'}</>
          )}
        </p>
      </Card>
    );
  };

  const renderJoinLeaveButton = () => {
    if (loading)
      return <Skeleton variant="rectangular" width={200} height={40} />;

    if (userIsAdmin) return null;

    const { userIsJoined, onJoin, onLeave } = props;

    return (
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={userIsJoined ? onLeave() : onJoin()}
        className="hidden font-bold w-[200px] h-[40px] !bg-white text-on-secondary border-on-primary border-[2px] sm:block hover:text-white"
      >
        {userIsJoined ? 'Leave' : 'Join'}
      </LoadingButton>
    );
  };

  return (
    <div className="flex flex-col-reverse justify-center w-full sm:flex-row sm:h-full">
      <div className="flex flex-col w-full h-full pb-4 overflow-auto sm:ml-4 sm:mr-4 sm:max-w-[540px]">
        {renderTitle()}
        {renderAbout()}
        {renderMembers()}
      </div>
      <div className="flex flex-col items-center w-full sm:ml-4 sm:mr-4 sm:max-w-[300px]">
        {renderAdmins()}
        {renderJoinLeaveButton()}
      </div>
    </div>
  );
};

Info.defaultProps = {
  type: 'group',
  title: '',
  description: '',
  numberOfMembers: 0,
  admins: [],
  admin: '',
  members: [],
  userIsJoined: false,
  onLoading: false,
  time: undefined,
  location: '',
  loading: false,
  userIsAdmin: false,
  options: [],
  onOptionClick: undefined,
};

export default Info;
