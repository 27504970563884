import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ContentsSelectors } from '@data/contents/Contents.selectors';
import * as React from 'react';
import { getContent } from '@data/contents/Contents.thunk';
import useAppDispatch from '@data/useAppDispatch';
import useContent from '@view/content-management-system/hooks/useContent';
import { Content, ContentTypes } from '@data/contents/Contents.model';
import ArticleView from '@view/discover-page/components/ArticleView';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import SeriesView from '@view/discover-page/components/SeriesView';
import ArticleViewSkeleton from '@view/discover-page/components/ArticleViewSkeleton';
import SeriesViewSkeleton from '@view/discover-page/components/SeriesViewSkeleton';

const ContentView: React.FC<unknown> = () => {
  const { contentId } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [contentIsFetched, setContentIsFetched] = React.useState(false);

  const content: Content = useSelector((state: any) =>
    ContentsSelectors.contents.selectById(state.contents, contentId || -1),
  ) as Content;

  React.useEffect(() => {
    if (!contentIsFetched && contentId) {
      dispatch(
        getContent({
          id: contentId,
        }),
      ).then(() => {
        setIsLoading(false);
        setContentIsFetched(true);
      });
    }
  }, [contentId, contentIsFetched, dispatch, setIsLoading]);

  const {
    id,
    contentType,
    title,
    cover,
    readTime,
    noOfSteps,
    description,
    steps,
    isStarted,
    noOfCompletedSteps,
    contentMark,
    bookmarked,
    seriesDetails,
  } = useContent(content);

  return (
    <WithSideBar>
      <div className="flex flex-col items-center justify-start w-full h-full pt-4 overflow-auto">
        {contentType.value === ContentTypes.ARTICLE &&
          (isLoading ? (
            <ArticleViewSkeleton />
          ) : (
            <ArticleView
              title={title}
              cover={cover}
              htmlContent={content.htmlContent}
              contentType={contentType}
              articleType={content.articleType}
              pdfContent={content.pdfContent}
              readTime={readTime}
              id={id}
              contentMark={contentMark}
              bookmarked={bookmarked}
              seriesDetails={seriesDetails}
            />
          ))}
        {contentType.value === ContentTypes.SERIES &&
          (isLoading ? (
            <SeriesViewSkeleton />
          ) : (
            <SeriesView
              cover={cover}
              title={title}
              numOfSteps={noOfSteps}
              contentType={contentType}
              description={description}
              steps={steps}
              isStarted={isStarted}
              numOfCompletedSteps={noOfCompletedSteps}
              id={id}
              contentMark={contentMark}
              bookmarked={bookmarked}
            />
          ))}
      </div>
    </WithSideBar>
  );
};

export default ContentView;
