import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '@data/useAppDispatch';
import { activateCommunity, logoutUser } from '@data/app/app.thunk';

const useCommunitySelection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = React.useCallback(
    () => () => {
      dispatch(logoutUser()).then(() => {
        navigate('/');
      });
    },
    [dispatch, navigate],
  );

  const setActiveCommunity = React.useCallback(
    (communityKey: string) => () => {
      dispatch(activateCommunity(communityKey)).then(() => {
        navigate('/');
      });
    },
    [dispatch, navigate],
  );

  return {
    onLogout,
    setActiveCommunity,
  };
};

export default useCommunitySelection;
