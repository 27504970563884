import * as React from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from '@data/useAppDispatch';
import SupportGroupSelector from '@data/support-groups/supportGroup.selector';
import {
  joinSupportGroup,
  leaveSupportGroup,
} from '@data/support-groups/supportGroup.thunk';
import {
  SupportGroup,
  SupportGroupMember,
} from '@data/support-groups/supportGroups.model';
import { RootState } from '@lib/configureStore';

interface supportGroupDetails {
  title?: string;
  cover?: string;
  description?: string;
  numberOfMembers?: number;
  admins?: string[];
  members?: SupportGroupMember[];
  userIsJoined?: boolean;
  onJoin: () => () => void;
  onLeave: () => () => void;
  onLoading: boolean;
  channelId?: number;
}

const useSupportGroupDetails = (id: string): supportGroupDetails => {
  const dispatch = useAppDispatch();
  const [onLoading, setOnLoading] = React.useState(false);

  const supportGroup: SupportGroup | undefined = useSelector(
    (state: RootState) =>
      SupportGroupSelector.selectById(state.supportGroups.all, `all-${id}`),
  );

  const onJoin = () => () => {
    setOnLoading(true);
    dispatch(joinSupportGroup({ groupId: id })).then(() => {
      setOnLoading(false);
    });
  };

  const onLeave = () => () => {
    setOnLoading(true);
    dispatch(leaveSupportGroup({ groupId: id })).then(() => {
      setOnLoading(false);
    });
  };

  return {
    title: supportGroup?.info.title,
    cover: supportGroup?.info.coverImage,
    description: supportGroup?.info.description,
    numberOfMembers: supportGroup?.members.numberOfMembers,
    admins: supportGroup?.admins.map((admin) => admin.nickname),
    members: supportGroup?.members?.members,
    userIsJoined: supportGroup?.status?.userIsJoined,
    onJoin,
    onLeave,
    onLoading,
    channelId: supportGroup?.channelId,
  };
};

export default useSupportGroupDetails;
