import { Middleware, Reducer } from 'redux';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from '@lib/redux/root.reducer';
import { configureStore as configureStoreToolkit } from '@reduxjs/toolkit';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';

type configureStoreAndHistoryType = {
  store: EnhancedStore;
  persistor: Persistor;
};

export const configureStoreAndHistory = (): configureStoreAndHistoryType => {
  const persistedReducer: Reducer = persistReducer(
    {
      key: 'Curatio',
      storage,
      version: 3,
      migrate: (state, version) => {
        // eslint-disable-next-line no-underscore-dangle
        if (state?._persist.version !== version)
          return Promise.resolve(undefined);
        return Promise.resolve(state);
      },
    },
    rootReducer(),
  );

  // attach any dev middleware in the array
  const devMiddleware =
    process.env.REACT_APP_ENVIRONMENT === 'development' ||
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? [createLogger({ diff: false })]
      : [];

  const middlewares: Middleware[] = [thunk, ...devMiddleware];

  const store = configureStoreToolkit({
    reducer: persistedReducer,
    devTools:
      process.env.REACT_APP_ENVIRONMENT === 'development' ||
      process.env.NODE_ENV === 'development' ||
      process.env.REACT_APP_ENVIRONMENT === 'staging',
    middleware: middlewares,
  });

  // const composeEnhancers =
  //     (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  //
  // const enhancer: StoreEnhancer = composeEnhancers(
  //     applyMiddleware(...middlewares),
  //     // sentryReduxEnhancer,
  // );

  // const store: AppStore = createStore(persistedReducer, enhancer);

  const persistor: Persistor = persistStore(store);

  // return the store and history objects
  return {
    store,
    persistor,
  };
};

const { store, persistor } = configureStoreAndHistory();

export const configureStore = (): configureStoreAndHistoryType => {
  return { store, persistor };
};

export type RootState = ReturnType<typeof store.getState>;
