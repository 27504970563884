// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from '@data/useAppDispatch';
import { NotificationSelector } from '@data/notifications/Notification.selector';
import {
  fetchNotifications,
  markNotificationAsRead,
} from '@data/notifications/Notification.thunk';
import Notification from '../notification/Notification';
import LoadingPage from '../loading-page';

const NotificationsList: React.FC<{ open: boolean }> = ({ open }) => {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = React.useState(false);

  const notifications = useSelector((state: any) =>
    NotificationSelector.selectIds(state.notifications),
  );
  const unreadNotifications = useSelector(NotificationSelector.getUnreadCount);

  const [markAsRead, setMarkAsRead] = React.useState(false);

  React.useEffect(() => {
    if (!isLoaded) {
      dispatch(fetchNotifications()).then(() => {
        setIsLoaded(true);
      });
    }
  }, [dispatch, isLoaded]);

  React.useEffect(() => {
    if (
      open &&
      notifications.length > 0 &&
      unreadNotifications > 0 &&
      !markAsRead
    )
      dispatch(
        markNotificationAsRead({
          notificationId: notifications[0] as string,
        }),
      ).then(() => {
        setMarkAsRead(true);
      });
  }, [open, notifications, dispatch, unreadNotifications, markAsRead]);

  if (!isLoaded) return <LoadingPage />;

  return (
    <>
      {notifications.map((key) => {
        return <Notification key={`notif-${key}`} notificationId={key} />;
      })}
    </>
  );
};
export default NotificationsList;
