import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { FeedPublishStatus, FeedTypes } from '../../models/Feed';
import { Feed, TimeLines } from './timeline.model';
import {
  addExternalCaseID,
  deleteComment,
  deleteFeed,
  fetchAEPosts,
  fetchBase64,
  fetchBookmarkedPosts,
  fetchDrafts,
  fetchFlaggedPosts,
  fetchGroupTimeline,
  fetchReviewedAEPosts,
  fetchScheduledPosts,
  fetchTimeline,
  fetchUserPosts,
  getFeed,
} from './timeline.thunk';
import {
  convertTimelineResponse,
  filterTimeline,
  removeComment,
  replaceImageURLWithBase64,
  updateCommentStatus,
  updateFeedStatus,
  upsertComment,
} from './timeline.factory';
import { joinSocialEvent } from '../social-events/socialEvents.thunk';

const adapterOptions = (prefix: string) => ({
  selectId: (feed: Feed) => `${prefix}-${feed.id}`,
  sortComparer: (a, b) => b.id - a.id,
});

export const timelineAdapter = {
  published: createEntityAdapter<Feed>(adapterOptions('p')),
  drafted: createEntityAdapter<Feed>(adapterOptions('d')),
  scheduled: createEntityAdapter<Feed>(adapterOptions('s')),
  bookmarked: createEntityAdapter<Feed>(adapterOptions('b')),
  flagged: createEntityAdapter<Feed>(adapterOptions('f')),
  adverseEvent: createEntityAdapter<Feed>(adapterOptions('ae')),
  AECases: createEntityAdapter<Feed>({
    selectId: (feed: Feed) => `${feed.adverseEvent?.reportedDate}-${feed.id}`,
    sortComparer: (a, b) => String(b.id).localeCompare(String(a.id)),
  }),
  groups: createEntityAdapter<Feed>({
    selectId: (feed: Feed) => `${feed.info.channelId}-${feed.id}`,
    sortComparer: (a, b) => b.id - a.id,
  }),
  users: createEntityAdapter<Feed>({
    selectId: (feed: Feed) => `${feed.publisher.id}-${feed.id}`,
    sortComparer: (a, b) => a.id - b.id,
  }),
};

const getInitialState = () => ({
  published: timelineAdapter.published.getInitialState({
    page: 1,
    total: -1,
  }),
  drafted: timelineAdapter.published.getInitialState({ page: 1, total: -1 }),
  scheduled: timelineAdapter.scheduled.getInitialState({
    page: 1,
    total: -1,
  }),
  bookmarked: timelineAdapter.bookmarked.getInitialState({
    page: 1,
    total: -1,
  }),
  flagged: timelineAdapter.flagged.getInitialState({ page: 1, total: -1 }),
  adverseEvent: timelineAdapter.adverseEvent.getInitialState({
    page: 1,
    total: -1,
  }),
  AECases: timelineAdapter.adverseEvent.getInitialState({
    page: 1,
    total: -1,
  }),
  groups: timelineAdapter.groups.getInitialState({ page: 1, total: -1 }),
  users: timelineAdapter.users.getInitialState({ page: 1, total: -1 }),
});

const isFulfilledActionOnFeed = (action: AnyAction): boolean =>
  action.type?.includes('timeline/action') &&
  action.type?.includes('fulfilled');
const isFulfilledActionOnComment = (action: AnyAction): boolean =>
  action.type?.includes('timeline/comment/action') &&
  action.type?.includes('fulfilled');
const isFulfilledFetchTimeline = (action: AnyAction): boolean =>
  action.type?.includes('loadTimeline') && action.type?.includes('fulfilled');
const isFulfilledFetchFlagged = (action: AnyAction): boolean =>
  action.type?.includes('loadFlaggedPosts') &&
  action.type?.includes('fulfilled');
const isFulfilledFetchGroupTimeline = (action: AnyAction): boolean =>
  action.type?.includes('group/fetch') && action.type?.includes('fulfilled');
const isFulfilledFetchScheduledTimeline = (action: AnyAction): boolean =>
  action.type?.includes('loadScheduledPosts') &&
  action.type?.includes('fulfilled');
const isFulfilledFetchDraftsTimeline = (action: AnyAction): boolean =>
  action.type?.includes('loadDrafts') && action.type?.includes('fulfilled');
const isFulfilledFetchBookmarksTimeline = (action: AnyAction): boolean =>
  action.type?.includes('loadBookmarkedPosts') &&
  action.type?.includes('fulfilled');

const isFulfilledFetchAdverseEventsTimeline = (action: AnyAction): boolean =>
  action.type?.includes('loadAEPosts') && action.type?.includes('fulfilled');
const isFulfilledFetchAECases = (action: AnyAction): boolean =>
  action.type?.includes('loadAECases') && action.type?.includes('fulfilled');
const isFulfilledUsersTimeline = (action: AnyAction): boolean =>
  action.type?.includes('loadUserPosts') && action.type?.includes('fulfilled');

const isCreateOrUpdate = (action: AnyAction): boolean =>
  action.type?.includes('timeline') &&
  action.type?.includes('fulfilled') &&
  (action.type?.includes('create') ||
    action.type?.includes('read') ||
    action.type?.includes('update'));
const isCreateOrUpdateCommentFulfilled = (action: AnyAction): boolean =>
  action.type?.includes('comment') &&
  action.type?.includes('fulfilled') &&
  (action.type?.includes('publish') || action.type?.includes('update'));
const isCreateOrUpdateCommentPending = (action: AnyAction): boolean =>
  action.type?.includes('comment') &&
  action.type?.includes('pending') &&
  (action.type?.includes('publish') || action.type?.includes('update'));
const isSubmitReviewFulfilled = (action: AnyAction): boolean =>
  action.type?.includes('reviews') && action.type?.includes('fulfilled');

const getPubStatus = (action: AnyAction): FeedPublishStatus =>
  action?.meta?.arg?.pubStatus;
const getAssetType = (action: AnyAction): FeedTypes =>
  action?.meta?.arg?.category;
const isPublished = (action: AnyAction): boolean =>
  getPubStatus(action) === FeedPublishStatus.PUBLISHED;
const isDraft = (action: AnyAction): boolean =>
  getPubStatus(action) === FeedPublishStatus.DRAFT;
const isScheduled = (action: AnyAction): boolean =>
  getPubStatus(action) === FeedPublishStatus.SCHEDULED;
const isPoll = (action: AnyAction): boolean =>
  getAssetType(action) === FeedTypes.POLL;
const isNotPoll = (action: AnyAction): boolean => !isPoll(action);

const combineMatchers =
  (matchers: ((a: AnyAction) => boolean)[]): ((action: AnyAction) => boolean) =>
  (action: AnyAction) =>
    matchers.map((m) => m(action)).every(Boolean);

const sharedReducers = {
  updatePageAndTotal: (state, action, key) => {
    if (action.meta?.arg?.page)
      return {
        ...state,
        [key]: {
          ...state[key],
          page: action.meta?.arg?.page,
          total: action.payload.total_count,
        },
      };
    return state;
  },
};
// @ts-ignore
const timelineSlice = createSlice({
  name: 'timeline',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    /* Fetch timeline */
    builder
      .addCase(fetchTimeline.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.published.setAll
          : timelineAdapter.published.setMany)(
          state.published,
          filterTimeline(
            action.payload.timeline.map((t) =>
              convertTimelineResponse(t?.asset),
            ),
          ),
        );
      })
      .addCase(fetchDrafts.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.drafted.setAll
          : timelineAdapter.drafted.setMany)(
          state.drafted,
          action.payload.timeline.map((t) => convertTimelineResponse(t?.asset)),
        );
      })
      .addCase(fetchScheduledPosts.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.scheduled.setAll
          : timelineAdapter.scheduled.setMany)(
          state.scheduled,
          action.payload.timeline.map((t) => convertTimelineResponse(t?.asset)),
        );
      })
      .addCase(fetchBookmarkedPosts.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.bookmarked.setAll
          : timelineAdapter.bookmarked.setMany)(
          state.bookmarked,
          action.payload.timeline.map((t) => convertTimelineResponse(t?.asset)),
        );
      })
      .addCase(fetchFlaggedPosts.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.flagged.setAll
          : timelineAdapter.flagged.setMany)(
          state.flagged,
          action.payload.timeline.map((t) => convertTimelineResponse(t?.asset)),
        );
      })
      .addCase(fetchUserPosts.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.users.setAll
          : timelineAdapter.users.setMany)(
          state.users,
          action.payload.timeline.map((t) => convertTimelineResponse(t?.asset)),
        );
      })
      .addCase(fetchAEPosts.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.adverseEvent.setAll
          : timelineAdapter.adverseEvent.setMany)(
          state.adverseEvent,
          action.payload.assets.map((t) =>
            convertTimelineResponse({ ...t, isAPotentialAE: true }),
          ),
        );
      })
      .addCase(fetchBase64.fulfilled, (state, action) => {
        const data = action.payload;

        const groupedDate = {};
        data.forEach((d) => {
          if (!groupedDate[d.postId]) groupedDate[d.postId] = [];
          groupedDate[d.postId].push({
            base64: d.base64,
            propsIndex: d.propsIndex,
          });
        });

        const changes = Object.keys(groupedDate)
          .map((postId) => {
            const post = state.adverseEvent.entities[`ae-${postId}`];
            if (!post) return null;
            return {
              id: `ae-${postId}`,
              changes: {
                attachments: replaceImageURLWithBase64(
                  post.attachments,
                  groupedDate[postId],
                ),
              },
            };
          })
          .filter((p) => p !== null);

        // @ts-ignore
        timelineAdapter.adverseEvent.updateMany(state.adverseEvent, changes);
      })
      .addCase(fetchReviewedAEPosts.fulfilled, (state, action) => {
        const reportedDate = `${action.meta.arg.year}-${action.meta.arg.month}`;
        (action?.meta.arg.page <= 1
          ? timelineAdapter.AECases.setAll
          : timelineAdapter.AECases.setMany)(
          state.AECases,
          action.payload.pharmaco_vigilance_cases.map((t) =>
            convertTimelineResponse({ ...t.asset, ...t, reportedDate }),
          ),
        );
      })
      .addCase(fetchGroupTimeline.pending, (state, action) => {
        if (action?.meta.arg.page === 1) {
          timelineAdapter.groups.setAll(state.groups, []);
        }
      })
      .addCase(fetchGroupTimeline.fulfilled, (state, action) => {
        (action?.meta.arg.page <= 1
          ? timelineAdapter.groups.setAll
          : timelineAdapter.groups.setMany)(
          state.groups,
          action.payload.timeline.map((t) =>
            convertTimelineResponse(
              { ...t.asset, ...t },
              action.meta.arg.channelId,
            ),
          ),
        );
      })
      .addCase(addExternalCaseID.fulfilled, (state, action) => {
        timelineAdapter.AECases.upsertOne(
          state.AECases,
          convertTimelineResponse({
            ...action.payload.asset,
            ...action.payload,
          }),
        );
      })

      /* Remove feed */
      .addCase(deleteFeed.fulfilled, (state, action) => {
        if (action.payload.pub_status === FeedPublishStatus.PUBLISHED)
          timelineAdapter.published.removeOne(
            state.published,
            `p-${action.payload.id}`,
          );
        else if (action.payload.pub_status === FeedPublishStatus.DRAFT)
          timelineAdapter.drafted.removeOne(
            state.drafted,
            `d-${action.payload.id}`,
          );
        else if (action.payload.pub_status === FeedPublishStatus.SCHEDULED)
          timelineAdapter.scheduled.removeOne(
            state.scheduled,
            `s-${action.payload.id}`,
          );
      })
      .addCase(getFeed.fulfilled, (state, action) => {
        action.payload.assets.forEach((asset) => {
          if (asset.pub_status === FeedPublishStatus.SCHEDULED)
            timelineAdapter.scheduled.upsertOne(
              state.scheduled,
              convertTimelineResponse(asset),
            );
          else if (asset.pub_status === FeedPublishStatus.DRAFT)
            timelineAdapter.drafted.upsertOne(
              state.drafted,
              convertTimelineResponse(asset),
            );
          else
            timelineAdapter.published.upsertOne(
              state.published,
              convertTimelineResponse(asset),
            );
        });
      })
      .addCase(joinSocialEvent.fulfilled, (state, action) => {
        if (action.meta.arg.feedId) {
          const feed = timelineAdapter.published
            .getSelectors()
            .selectById(state.published, `p-${action.meta.arg.feedId}`);
          if (feed) {
            // @ts-ignore
            timelineAdapter.published.updateOne(state.published, {
              id: `p-${action.meta.arg.feedId}`,
              changes: {
                event: {
                  ...feed.event,
                  joinedEvent: true,
                  joinedEventCount: (feed?.event?.joinedEventCount || 0) + 1,
                },
              },
            });
          }
        }
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        const feedId = `p-${
          action.meta?.arg?.grandParentID || action.meta?.arg?.parentID
        }`;
        const feedState = timelineAdapter.published
          .getSelectors()
          .selectById(state.published, feedId);
        timelineAdapter.published.updateOne(state.published, {
          id: feedId,
          changes: {
            comments: removeComment(
              feedState,
              action.meta.arg,
              action.payload.id,
            ),
          },
        });
      })
      .addMatcher(isSubmitReviewFulfilled, (state, action) => {
        return {
          ...state,
          flagged: getInitialState().flagged,
        };
      })
      .addMatcher(isCreateOrUpdateCommentPending, (state, action) => {
        const feedId = `p-${
          action.meta?.arg?.grandParentID || action.meta?.arg?.parentID
        }`;
        const feedState = timelineAdapter.published
          .getSelectors()
          .selectById(state.published, feedId);
        const commentId = action.meta?.arg?.commentID || -2;
        timelineAdapter.published.updateOne(state.published, {
          id: feedId,
          changes: {
            comments: upsertComment(
              {
                comments: removeComment(feedState, action.meta.arg, commentId),
              },
              action.meta.arg,
              { id: -1 },
            ),
          },
        });
      })
      .addMatcher(isCreateOrUpdateCommentFulfilled, (state, action) => {
        const feedId = `p-${
          action.meta?.arg?.grandParentID || action.meta?.arg?.parentID
        }`;
        const feedState = timelineAdapter.published
          .getSelectors()
          .selectById(state.published, feedId);
        const { payload } = action;

        timelineAdapter.published.updateOne(state.published, {
          id: feedId,
          changes: {
            comments: upsertComment(
              {
                comments: removeComment(feedState, action.meta.arg, -1),
              },
              action.meta.arg,
              convertTimelineResponse(
                payload.assets
                  ? {
                      ...payload.assets,
                      user: payload.user,
                    }
                  : payload,
              ),
            ),
          },
        });
      })
      /* Like, Bookmark, Flag */
      .addMatcher(isFulfilledActionOnFeed, (state, action) => {
        let adapter = timelineAdapter.published;
        let idPrefix = 'p';
        let targetState = state.published;
        if (action.meta.arg.timeline === TimeLines.BOOKMARKS) {
          adapter = timelineAdapter.bookmarked;
          idPrefix = 'b';
          targetState = state.bookmarked;
        } else if (action.meta.arg.timeline === TimeLines.GROUP_TIMELINE) {
          adapter = timelineAdapter.groups;
          idPrefix = action.meta.arg.channelId;
          targetState = state.groups;
        }

        if (action?.payload?.action?.visible === false)
          adapter.removeOne(
            targetState,
            `${idPrefix}-${action.payload.action.asset_id}`,
          );
        else
          adapter.updateOne(targetState, {
            id: `${idPrefix}-${action.payload.action.asset_id}`,
            changes: {
              status: updateFeedStatus(
                adapter
                  .getSelectors()
                  .selectById(
                    targetState,
                    `${idPrefix}-${action.payload.action.asset_id}`,
                  ),
                action.payload.action,
              ),
            },
          });
      })
      .addMatcher(
        combineMatchers([isCreateOrUpdate, isPublished, isNotPoll]),
        (state, action) => {
          const { payload } = action;
          if (action.meta.arg.timeline === TimeLines.GROUP_TIMELINE) {
            timelineAdapter.groups.upsertOne(
              state.groups,
              convertTimelineResponse(
                payload.assets
                  ? {
                      ...payload.assets,
                      user: payload.user,
                    }
                  : payload,
              ),
            );
          } else {
            timelineAdapter.published.upsertOne(
              state.published,
              convertTimelineResponse(
                payload.assets
                  ? {
                      ...payload.assets,
                      user: payload.user,
                    }
                  : payload,
              ),
            );
          }
        },
      )
      .addMatcher(
        combineMatchers([isCreateOrUpdate, isDraft, isNotPoll]),
        (state, action) => {
          const { payload } = action;
          timelineAdapter.drafted.upsertOne(
            state.drafted,
            convertTimelineResponse(
              payload.assets
                ? {
                    ...payload.assets,
                    user: payload.user,
                  }
                : payload,
            ),
          );
        },
      )
      .addMatcher(
        combineMatchers([isCreateOrUpdate, isScheduled, isNotPoll]),
        (state, action) => {
          const { payload } = action;
          timelineAdapter.scheduled.upsertOne(
            state.scheduled,
            convertTimelineResponse(
              payload.assets
                ? {
                    ...payload.assets,
                    user: payload.user,
                  }
                : payload,
            ),
          );
        },
      )
      .addMatcher(isFulfilledFetchTimeline, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'published'),
      )
      .addMatcher(isFulfilledUsersTimeline, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'users'),
      )
      .addMatcher(isFulfilledFetchFlagged, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'flagged'),
      )
      .addMatcher(isFulfilledFetchGroupTimeline, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'groups'),
      )
      .addMatcher(isFulfilledFetchScheduledTimeline, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'scheduled'),
      )
      .addMatcher(isFulfilledFetchDraftsTimeline, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'drafted'),
      )
      .addMatcher(isFulfilledFetchAECases, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'AECases'),
      )
      .addMatcher(isFulfilledFetchBookmarksTimeline, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'bookmarked'),
      )
      .addMatcher(isFulfilledFetchAdverseEventsTimeline, (state, action) =>
        sharedReducers.updatePageAndTotal(state, action, 'adverseEvent'),
      )
      .addMatcher(isFulfilledActionOnComment, (state, action) => {
        const feedId = `p-${
          action.meta?.arg?.grandParent || action.meta?.arg?.parent
        }`;
        const feedState = timelineAdapter.published
          .getSelectors()
          .selectById(state.published, feedId);

        // @ts-ignore
        timelineAdapter.published.updateOne(state.published, {
          id: feedId,
          changes: {
            comments: updateCommentStatus(
              feedState,
              action.meta.arg,
              action.payload.action,
            ),
          },
        });
      }),
});

export default {
  reducers: timelineSlice.reducer,
  adapter: timelineAdapter,
  getInitialState,
};
