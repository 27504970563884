import { createSelector } from '@reduxjs/toolkit';
import { supportGroupAdapter } from './supportGroup.reducer';

const SupportGroupSelector = {
  ...supportGroupAdapter.all.getSelectors(),
  selectSuggestedGroups: createSelector(
    [
      (state: any) =>
        supportGroupAdapter.all
          .getSelectors()
          .selectAll(state.supportGroups.all),
    ],
    (groups) =>
      groups.filter(
        (group) => group.status.isActive && !group.status.userIsJoined,
      ),
  ),
  selectMyGroups: createSelector(
    [
      (state: any) =>
        supportGroupAdapter.all
          .getSelectors()
          .selectAll(state.supportGroups.all),
    ],
    (groups) =>
      groups.filter(
        (group) => group.status.userIsJoined && group.status.isActive,
      ),
  ),
  selectHiddenGroups: createSelector(
    [
      (state: any) =>
        supportGroupAdapter.all
          .getSelectors()
          .selectAll(state.supportGroups.all),
    ],
    (groups) => groups.filter((group) => !group.status.isActive),
  ),
  selectPendingRequests: createSelector(
    [
      (state: any) =>
        supportGroupAdapter.pending
          .getSelectors()
          .selectAll(state.supportGroups.pending),
    ],
    (requests) => requests,
  ),
  selectApprovedRequests: createSelector(
    [
      (state: any) =>
        supportGroupAdapter.approved
          .getSelectors()
          .selectAll(state.supportGroups.approved),
    ],
    (requests) => requests,
  ),
  selectRejectedRequests: createSelector(
    [
      (state: any) =>
        supportGroupAdapter.rejected
          .getSelectors()
          .selectAll(state.supportGroups.rejected),
    ],
    (requests) => requests,
  ),
  selectPendingGroupsCount: createSelector(
    (state) => state.supportGroups.pending,
    (state: any) =>
      supportGroupAdapter.pending.getSelectors().selectTotal(state),
  ),
};

export default SupportGroupSelector;
