import React, { useEffect, useState } from 'react';
import Loading from './components/Loading';

export const useLoading = () => {
  const [loadingId, setLoadingId] = useState(0);

  useEffect(() => {
    if (loadingId === 0) setLoadingId(Math.floor(Math.random() * 1000));
  }, [loadingId]);

  const isLoading = false;

  const LoadingComponent = React.useMemo(() => {
    const LoadingComponent: React.FC<
      React.PropsWithChildren<Record<string, unknown>>
    > = ({ children, ...props }) => {
      if (isLoading) return <Loading {...props} />;
      if (children) return children as unknown as JSX.Element;
      return null;
    };
    return LoadingComponent;
  }, [isLoading]);

  return {
    LoadingComponent,
    isLoading,
    loadingId,
  };
};
