import * as React from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  createSocialEvent,
  editSocialEvent,
} from '@data/social-events/socialEvents.thunk';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import { FeedSharingOption } from '../../../models/Feed';

type EventDraft = {
  title: string;
  location: string;
  description: string;
  startTime?: moment.Moment;
  endTime?: moment.Moment;
  cover: string;
  isCommunityGuidelinesChecked: boolean;
  shareWith?: FeedSharingOption;
  channelId?: string;
};

export type EventDraftAction = {
  setTitle: (title: string) => void;
  setLocation: (location: string) => void;
  setDescription: (description: string) => void;
  setStartTime: (startTime: moment.Moment) => void;
  setEndTime: (endTime: moment.Moment) => void;
  setCover: (cover: string) => void;
  setIsCommunityGuidelinesChecked: (
    isCommunityGuidelinesChecked: boolean,
  ) => void;
  setShareWith: (shareWith: FeedSharingOption) => void;
  setChannelId: (channelId: string) => void;
};

export type EventDraftValidationAction = {
  validateInfo: (info: Partial<EventDraft>) => boolean;
  validateCover: (cover: Partial<EventDraft>) => boolean;
  validateTime: (time: Partial<EventDraft>) => boolean;
  validateAgreement: (agreement: Partial<EventDraft>) => boolean;
};

export const EventDraftContext = React.createContext<
  (EventDraftAction & EventDraftValidationAction) | null
>(null);

const getEventDraftAction = (
  key: string,
  value: unknown,
  setInfo: (
    value: ((prevState: EventDraft) => EventDraft) | EventDraft,
  ) => void,
) => {
  setInfo((d) => ({ ...d, [key]: value }));
};

const getEventDraftValidationAction = (): EventDraftValidationAction => ({
  validateInfo: (info: Partial<EventDraft>) => {
    return Boolean(
      info?.title?.length &&
        info?.location?.length &&
        info?.description?.length &&
        info?.title?.length > 0 &&
        info?.location?.length > 0 &&
        info?.description?.length > 0,
    );
  },
  validateCover: (cover: Partial<EventDraft>) => {
    return Boolean(cover?.cover?.length && cover?.cover?.length > 0);
  },
  validateTime: (time: Partial<EventDraft>) => {
    return Boolean(
      time.startTime &&
        time.endTime &&
        time.startTime.isAfter(moment(new Date())) &&
        time.endTime.isAfter(moment(new Date())),
    );
  },
  validateAgreement: (agreement: Partial<EventDraft>) => {
    return Boolean(agreement?.isCommunityGuidelinesChecked);
  },
});

const getEventDraftActions = (setInfo) => ({
  setTitle: (title) => getEventDraftAction('title', title, setInfo),
  setLocation: (location) => getEventDraftAction('location', location, setInfo),
  setDescription: (description) =>
    getEventDraftAction('description', description, setInfo),
  setStartTime: (startTime) =>
    getEventDraftAction('startTime', startTime, setInfo),
  setEndTime: (endTime) => getEventDraftAction('endTime', endTime, setInfo),
  setCover: (cover) => getEventDraftAction('cover', cover, setInfo),
  setIsCommunityGuidelinesChecked: (isCommunityGuidelinesChecked) =>
    getEventDraftAction(
      'isCommunityGuidelinesChecked',
      isCommunityGuidelinesChecked,
      setInfo,
    ),
  setShareWith: (shareWith) =>
    getEventDraftAction('shareWith', shareWith, setInfo),
  setChannelId: (channelId) =>
    getEventDraftAction('channelId', channelId, setInfo),
});

const useDraftEvent = (eventId?: string) => {
  const navigate = useNavigate();

  // info
  const [info, setInfo] = React.useState<EventDraft>({
    title: '',
    location: '',
    description: '',
    startTime: undefined,
    endTime: undefined,
    cover: '',
    isCommunityGuidelinesChecked: false,
  });

  const contextValue = React.useMemo<
    EventDraftAction & EventDraftValidationAction
  >(
    () => ({
      ...getEventDraftActions(setInfo),
      ...getEventDraftValidationAction(),
    }),
    [],
  );

  const [status, setStatus] = React.useState<'writing' | 'submitting'>();

  // submit
  const dispatch = useAppDispatch();
  const onSubmit = React.useCallback(async () => {
    const endtime = info.endTime?.isAfter(info.startTime)
      ? info.endTime?.toISOString()
      : info.endTime?.add(1, 'day').toISOString();

    const startTime = info.startTime?.toISOString();

    // if (isValidated) {
    //   setIsSubmitting(true);

    // @TODO check if event can be edited (ended or not)
    if (!eventId)
      dispatch(
        createSocialEvent({
          relation: info.shareWith,
          channelId: info.channelId,
          title: info.title,
          location: info.location,
          description: info.description,
          startDate: startTime || '',
          endDate: endtime || '',
          cover: info.cover,
        }),
      )
        .then(({ payload }) => {
          navigate(endpointIDReplacer(Endpoints.SOCIAL_EVENT, payload.id));
        })
        .catch(() => setStatus('writing'));
    else
      dispatch(
        editSocialEvent({
          eventId,
          title: info.title,
          location: info.location,
          description: info.description,
          startDate: startTime || '',
          endDate: endtime || '',
          cover: info.cover,
        }),
      )
        .then((payload) => {
          navigate(endpointIDReplacer(Endpoints.SOCIAL_EVENT, payload.id));
        })
        .catch(() => setStatus('writing'));
  }, [
    dispatch,
    eventId,
    info.channelId,
    info.cover,
    info.description,
    info.endTime,
    info.location,
    info.shareWith,
    info.startTime,
    info.title,
    navigate,
  ]);

  React.useEffect(() => {
    if (status === 'submitting') onSubmit();
  }, [onSubmit, status]);

  return {
    onSubmit: () => setStatus('submitting'),
    isSubmitting: status === 'submitting',
    contextValue,
  };
};

export default useDraftEvent;
