import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  bookmarkContent,
  unbookmarkContent,
} from '@data/contents/Contents.thunk';

const BookmarkContentIcon: React.FC<{
  contentId: string;
  bookmarked: boolean;
}> = ({ contentId, bookmarked }) => {
  const dispatch = useDispatch();
  const toggleBookmark = React.useCallback(() => {
    if (!bookmarked) {
      dispatch(bookmarkContent({ id: contentId }));
    } else {
      dispatch(unbookmarkContent({ id: contentId }));
    }
  }, [bookmarked, dispatch, contentId]);

  return (
    <IconButton
      onClick={toggleBookmark}
      className="absolute w-6 h-6 top-4 right-6 text-[#4D565F]"
    >
      {!bookmarked ? (
        <BookmarkBorderIcon className="w-[22px] h-[22px] text-on-secondary" />
      ) : (
        <BookmarkOutlinedIcon className="w-[22px] h-[22px] text-brand-primary" />
      )}
    </IconButton>
  );
};

export default BookmarkContentIcon;
