// @flow
import * as React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import { TextKeys } from '@lib/i18n/configureI18n';
import Endpoints from '@view/routes/endpoints';
import { useContentListFilter } from '@view/content-management-system/hooks';
import {
  ContentListHeader,
  ContentListTabs,
  ContentListView,
} from '@view/content-management-system/components';

const DiscoverPage: React.FC<unknown> = () => {
  const { ContentListFilterProvider, filteredList, loadContents } =
    useContentListFilter();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToCreateContent = React.useCallback(() => {
    navigate(Endpoints.DISCOVER_CREATE_SELECTION);
  }, [navigate]);

  return (
    <WithSideBar>
      <div className="flex justify-center w-full h-full">
        <div className="flex flex-col w-full h-full max-w-[1075px] pt-9">
          <div className="flex items-center justify-between">
            <h1 className="ml-5 h1 text-on-primary">{t[TextKeys.Projects]}</h1>
            <Button
              className="rounded-full w-[200px]"
              color="primary"
              variant="contained"
              onClick={navigateToCreateContent}
            >
              {t(TextKeys.CreateContent)}
            </Button>
          </div>
          <ContentListFilterProvider>
            <ContentListTabs />
            {filteredList.length > 0 && <ContentListHeader />}
            <ContentListView list={filteredList} loading={loadContents} />
          </ContentListFilterProvider>
        </div>
      </div>
    </WithSideBar>
  );
};
export default DiscoverPage;
