import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const ContentCardSkeleton: React.FC<{
  index: number;
}> = ({ index }) => {
  return (
    <Skeleton
      variant="rounded"
      className={`relative flex flex-col mb-4 mr-[13px] w-[273px] h-[294px] ${
        index % 3 === 0 ? 'mr-4.5' : ''
      }`}
    />
  );
};

export default ContentCardSkeleton;
