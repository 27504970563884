import {
  RequestedSupportGroup,
  SupportGroup,
  SupportGroupMember,
} from './supportGroups.model';

export const convertSupportGroupResponse = (
  group: any,
  myGroupsIds?: string[],
  userId?: number,
): SupportGroup => ({
  id: group.id,
  channelId: group.channel_id,
  info: {
    title: group.title,
    description: group.description,
    coverImage: group.img_url,
  },
  status: {
    isActive: group.status === 'active',
    isHidden: group.status === 'suspended',
    isPrivate: group.is_private,
    userIsJoined: (myGroupsIds || []).includes(group.id),
    userIsAdmin:
      group?.admins?.filter((admin) => admin.id === userId).length > 0,
  },
  members: {
    numberOfMembers: group.member_count,
    maxNumberOfMembers: group.max_member_count,
  },
  posts: {
    numberOfPosts: group.post_count,
  },
  admins: group?.admins?.map((admin: any) => ({
    isAmbassador: admin.ambassador_user,
    avatar: admin.img_url,
    nickname: admin.nick_name,
    id: admin.id,
  })),
});
export const convertGroupsResponse = (
  allGroups: any[],
  myGroups: any[],
  userId: number,
): SupportGroup[] => {
  const myGroupsIds: string[] = (myGroups || []).map((group) => group.id);
  return allGroups?.map((group: any) =>
    convertSupportGroupResponse(group, myGroupsIds, userId),
  );
};

export const convertRequestedGroupsResponse = (
  groups: any[],
  userId?: number,
): RequestedSupportGroup[] => {
  return (
    groups
      // .filter((group) => Boolean(group.support_group))
      .map((group: any) => {
        return {
          id: group.id,
          group: convertSupportGroupResponse(
            group.support_group || {
              title: group.title,
              description: group.description,
              img_url: group.img_url,
            },
            [],
            userId,
          ),
          isApproved: group.is_approved,
          request: {
            requestType: group.request_type,
            requestAt: group.requested_at,
            requester: {
              id: group.requester.id,
              nickname: group.requester.nick_name,
              avatar: group.requester.img_url,
            },
          },
          review: {
            reviewedAt: group.reviewed_at,
            reviewer: {
              id: group.reviewer.id,
              nickname: group.reviewer.nick_name,
              avatar: group.reviewer.img_url,
            },
            message: group.reviewer_message,
          },
        };
      })
  );
};

export const convertSupportGroupMembersResponse = (
  members: any[],
): SupportGroupMember[] => {
  return members.map((member: any) => ({
    id: member.user_id,
    avatar: member.img_url,
    nickname: member.nick_name,
    isFriend: member.friendship_state === 'friends',
    isOnline: member.online_status === 'active',
    isAdmin: member.role === 'admin',
  }));
};
