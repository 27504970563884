import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as React from 'react';

type ContentFilterProps = {
  label: string;
  ratio: number;
  selected?: boolean;
  index: number;
  onSortChanged: (i: number) => () => void;
  direction?: 'a' | 'd';
};

const ContentFilter: React.FC<ContentFilterProps> = ({
  label,
  ratio,
  selected,
  index,
  onSortChanged,
  direction,
}) => (
  <IconButton
    className={`caption  flex items-center justify-start  rounded-none
    ${selected ? 'text-brand-primary font-bold' : ' text-black'}`}
    style={{ width: `${ratio * 100}%` }}
    onClick={onSortChanged(index)}
  >
    {label}
    {direction === 'a' && selected ? (
      <KeyboardArrowUpIcon />
    ) : (
      <KeyboardArrowDownIcon />
    )}
  </IconButton>
);

ContentFilter.defaultProps = {
  selected: false,
  direction: undefined,
};

export default ContentFilter;
