/**
 * Size naming convention
 *
 * Use these values when referring to sizing in any component
 */
export enum Size {
  XXXSMALL = 'xxxSmall',
  XXSMALL = 'xxSmall',
  XSMALL = 'xSmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xLarge',
  CUSTOM = 'custom',
}

export default Size;
