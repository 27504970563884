import { TextField } from '@mui/material';
import * as React from 'react';
import {
  EventDraftAction,
  EventDraftContext,
  EventDraftValidationAction,
} from '@view/create-event/hooks/useDraftEvent';
import { EventDefaultValueContext } from '@view/create-event/hooks/useDefaultValues';

const CreateEventInfo: React.FC<{
  validate: (value: boolean) => void;
}> = ({ validate }) => {
  const defaultValues = React.useContext(EventDefaultValueContext);

  const [title, setTitle] = React.useState(defaultValues?.title || '');
  const [location, setLocation] = React.useState(defaultValues?.location || '');
  const [description, setDescription] = React.useState(
    defaultValues?.description || '',
  );

  const draftContext = React.useContext(EventDraftContext) as EventDraftAction &
    EventDraftValidationAction;

  React.useEffect(() => {
    draftContext.setTitle(title);
    draftContext.setLocation(location);
    draftContext.setDescription(description);
    validate(
      draftContext.validateInfo({
        title,
        location,
        description,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, location, description, draftContext]);

  return (
    <div className="w-full pt-4 pb-4 pl-5 pr-5 flex-col-center">
      <TextField
        className="w-full mb-3 "
        id="event-title-input"
        placeholder="Add the event title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        variant="outlined"
      />
      <TextField
        className="w-full mb-3"
        id="event-location-input"
        placeholder="Add the location to this event"
        value={location}
        onChange={(e) => setLocation(e.target.value)}
        variant="outlined"
      />
      <TextField
        className="w-full "
        rows={4}
        id="event-description-input"
        placeholder="Add the description for this event"
        multiline
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </div>
  );
};

export default CreateEventInfo;
