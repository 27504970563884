import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { Notification } from './Notification.model';
import {
  fetchNotifications,
  markNotificationAsRead,
} from './Notification.thunk';
import { convertNotificationsResponse } from './Notification.factory';

export const notificationAdapter = createEntityAdapter<Notification>({
  selectId: (notification: Notification) => notification.id,
  sortComparer: (b: Notification, a: Notification) =>
    (Number(a.isUnread) + 1) * a.createdAt -
    (Number(a.isUnread) + 1) * b.createdAt,
});

const isFulfilledFetchNotifications = (action: AnyAction): boolean =>
  action.type === fetchNotifications.fulfilled.type;
const isFulfilledMarkNotification = (action: AnyAction): boolean =>
  action.type === markNotificationAsRead.fulfilled.type;

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: notificationAdapter.getInitialState({ unreadCount: 0 }),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        const unreadNotifications =
          action.payload.unreadNotifications?.activities.map(
            (notification: any) => {
              return notification.id;
            },
          );

        notificationAdapter.setAll(
          state,
          [
            convertNotificationsResponse(
              action.payload.readNotifications?.activities,
              unreadNotifications,
            ),
          ].flat(),
        );
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        const lastSeenNotification = Number(
          action.meta.arg.notificationId || Number.MAX_SAFE_INTEGER,
        );
        notificationAdapter.updateMany(
          state,
          state.ids
            .filter((id) => id <= lastSeenNotification)
            .map((id) => {
              return {
                id,
                changes: {
                  isUnread: false,
                },
              };
            }),
        );
      })
      .addMatcher(isFulfilledFetchNotifications, (state, action) => {
        return {
          ...state,
          unreadCount:
            action.payload.unreadNotifications?.activities?.length ?? 0,
        };
      })
      .addMatcher(isFulfilledMarkNotification, (state, action) => {
        const lastSeenNotification = Number(
          action.meta.arg.notificationId || Number.MAX_SAFE_INTEGER,
        );
        return {
          ...state,
          // @ts-ignore
          unreadCount: state.ids.filter(
            (notificationId) => notificationId > lastSeenNotification,
          ).length,
        };
      }),
});

export default {
  reducers: notificationSlice.reducer,
  adapter: notificationAdapter,
  getInitialState: () =>
    notificationAdapter.getInitialState({ unreadCount: 0 }),
};
