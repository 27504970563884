import React, { RefObject } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configureApp from '@lib/configureApp';
import { configureSentry } from '@lib/configureSentry';
import { configureFirebase } from '@lib/configureFirebase';
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { configureAxios } from '@lib/configureAxios';
import { configureStore } from '@lib/configureStore';
import * as serviceWorker from './serviceWorker';

import './main.css';
import '@lib/i18n/configureI18n';
import 'typeface-roboto';
import AppPage from './view/app-page';
//
// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

// declare module '@mui/styles/defaultTheme' {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

/**
 * Configure Redux Store & Application Libraries
 */

export const { store, persistor, firebase } = configureApp(
  configureSentry,
  configureStore,
  configureAxios,
  configureFirebase,
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#008AAC',
    },
    secondary: {
      main: '#F5A623',
    },
  },
});

// add action to all snackbars
const notistackRef: RefObject<SnackbarProvider> = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef?.current?.closeSnackbar(key);
};

const container = document.getElementById('root');

if (container !== null) {
  const root = createRoot(container);
  if (root !== null)
    root.render(
      <ReduxProvider store={store}>
        <PersistGate persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                maxSnack={3}
                ref={notistackRef}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                action={(key) => (
                  <div
                    className="text-white shadow-none cursor-pointer"
                    onClick={onClickDismiss(key)}
                  >
                    DISMISS
                  </div>
                )}
              >
                <BrowserRouter>
                  <AppPage firebaseConfig={firebase} />
                </BrowserRouter>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </ReduxProvider>,
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.registerFirebaseWorker();
