import * as React from 'react';
import stripesBg from '@assets/images/stripes.svg';

const Header: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = ({ children }) => {
  return (
    <div className="relative w-full bg-secondary-background/30 h-[260px]">
      <img src={stripesBg} className="w-full h-full" alt="" />
      <div className="absolute object-cover w-full top-[20%]">
        {children && children}
      </div>
    </div>
  );
};

export default Header;
