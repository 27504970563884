import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RequestedSupportGroup, SupportGroup } from './supportGroups.model';
import {
  deleteSupportGroup,
  fetchApprovedSupportGroups,
  fetchPendingSupportGroups,
  fetchRejectedSupportGroups,
  fetchSupportGroup,
  fetchSupportGroupMembers,
  fetchSupportGroups,
  hideSupportGroup,
  joinSupportGroup,
  leaveSupportGroup,
  unhideSupportGroup,
  updateSupportGroup,
} from './supportGroup.thunk';
import {
  convertGroupsResponse,
  convertRequestedGroupsResponse,
  convertSupportGroupMembersResponse,
  convertSupportGroupResponse,
} from './supportGroups.factory';

const adapterOptions = (
  prefix: string,
  comparer: 'id' | 'request' | 'review' = 'id',
) => ({
  selectId: (group: SupportGroup | RequestedSupportGroup) =>
    `${prefix}-${group.id}`,
  sortComparer: (a, b) => {
    if (comparer === 'request') {
      return b.request.requestAt.localeCompare(a.request.requestAt);
    }
    if (comparer === 'review') {
      return b.review.reviewedAt.localeCompare(a.review.reviewedAt);
    }
    return a.id - b.id;
  },
});

export const supportGroupAdapter = {
  all: createEntityAdapter<SupportGroup>(adapterOptions('all')),
  pending: createEntityAdapter<RequestedSupportGroup>(
    adapterOptions('pending', 'request'),
  ),
  approved: createEntityAdapter<RequestedSupportGroup>(
    adapterOptions('approved', 'review'),
  ),
  rejected: createEntityAdapter<RequestedSupportGroup>(
    adapterOptions('rejected', 'review'),
  ),
};

const getInitialState = () => ({
  all: supportGroupAdapter.all.getInitialState(),
  pending: supportGroupAdapter.pending.getInitialState(),
  approved: supportGroupAdapter.approved.getInitialState(),
  rejected: supportGroupAdapter.rejected.getInitialState(),
});

const sharedReducers = {
  updateOne: () => (state, action) => {
    const id = `all-${action.meta.arg.groupId}`;
    const group = state.all.entities[id];
    if (group) {
      supportGroupAdapter.all.updateOne(state.all, {
        id,
        changes: {
          ...convertSupportGroupResponse(
            action.payload.group,
            [],
            action.payload.userId,
          ),
        },
      });
    }
  },
};

const supportGroupSlice = createSlice({
  name: 'supportGroup',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchSupportGroups.fulfilled, (state, action) => {
        supportGroupAdapter.all.setAll(
          state.all,
          convertGroupsResponse(
            action.payload.allGroups,
            action.payload.myGroups,
            action.payload.userId,
          ),
        );
      })
      .addCase(fetchPendingSupportGroups.fulfilled, (state, action) => {
        supportGroupAdapter.pending.setAll(
          state.pending,
          convertRequestedGroupsResponse(
            action.payload.support_group_requests || [],
          ),
        );
      })
      .addCase(fetchApprovedSupportGroups.fulfilled, (state, action) => {
        supportGroupAdapter.approved.setAll(
          state.approved,
          convertRequestedGroupsResponse(
            action.payload.support_group_requests || [],
          ),
        );
      })
      .addCase(fetchRejectedSupportGroups.fulfilled, (state, action) => {
        supportGroupAdapter.rejected.setAll(
          state.rejected,
          convertRequestedGroupsResponse(
            action.payload.support_group_requests || [],
          ),
        );
      })
      .addCase(fetchSupportGroupMembers.fulfilled, (state, action) => {
        const id = `all-${action.meta.arg.groupId}`;
        const group = state.all.entities[id];
        const members = convertSupportGroupMembersResponse(
          action.payload.members,
        );
        if (group) {
          supportGroupAdapter.all.updateOne(state.all, {
            id,
            changes: {
              status: {
                ...group.status,
                userIsJoined: members.some(
                  (member) => member.id === action.payload.userId,
                ),
              },
              members: {
                ...group.members,
                members,
              },
            },
          });
        }
      })
      .addCase(joinSupportGroup.fulfilled, (state, action) => {
        const id = `all-${action.meta.arg.groupId}`;
        const group = state.all.entities[id];
        if (group) {
          supportGroupAdapter.all.updateOne(state.all, {
            id,
            changes: {
              status: {
                ...group.status,
                userIsJoined: true,
              },
              members: {
                ...group.members,
                numberOfMembers: group.members.numberOfMembers + 1,
              },
            },
          });
        }
      })
      .addCase(leaveSupportGroup.fulfilled, (state, action) => {
        const id = `all-${action.meta.arg.groupId}`;
        const group = state.all.entities[id];
        if (group) {
          supportGroupAdapter.all.updateOne(state.all, {
            id,
            changes: {
              status: {
                ...group.status,
                userIsJoined: false,
              },
              members: {
                ...group.members,
                numberOfMembers: group.members.numberOfMembers - 1,
              },
            },
          });
        }
      })
      .addCase(updateSupportGroup.fulfilled, sharedReducers.updateOne())
      .addCase(hideSupportGroup.fulfilled, sharedReducers.updateOne())
      .addCase(unhideSupportGroup.fulfilled, sharedReducers.updateOne())
      .addCase(deleteSupportGroup.fulfilled, (state, action) => {
        const id = `all-${action.meta.arg.groupId}`;
        supportGroupAdapter.all.removeOne(state.all, id);
      })
      .addCase(fetchSupportGroup.fulfilled, (state, action) => {
        const id = `all-${action.meta.arg.groupId}`;
        const group = state.all.entities[id];

        if (group) {
          supportGroupAdapter.all.upsertOne(state.all, {
            ...convertSupportGroupResponse(
              action.payload,
              [],
              action.meta.arg.userId,
            ),
          });
        }
      }),
});

export default {
  reducers: supportGroupSlice.reducer,
  adapter: supportGroupAdapter,
};
