import React from 'react';

import { useSelector } from 'react-redux';
import useAppDispatch from '@data/useAppDispatch';
import { fetchProfile } from '@data/profiles/Profile.thunk';
import { ProfileSelector } from '@data/profiles/Profile.selector';
import { LoadingState } from '../../../models/Loading';

const useProfile = (userId: string) => {
  const [loadUserInfo, setLoadUserInfo] = React.useState<LoadingState>(
    LoadingState.IDLE,
  );

  const profile = useSelector((state) =>
    ProfileSelector.selectById(state, userId),
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (loadUserInfo === LoadingState.IDLE) {
      setLoadUserInfo(LoadingState.LOADING);
      dispatch(
        fetchProfile({
          userId,
        }),
      )
        .then(() => {
          setLoadUserInfo(LoadingState.SUCCEEDED);
        })
        .catch(() => {
          setLoadUserInfo(LoadingState.FAILED);
        });
    }
  }, [userId, loadUserInfo, dispatch]);

  const getAgeRange = React.useCallback(() => {
    if (!profile?.age.from && !profile?.age.to) return undefined;
    return `${profile?.age.from || ''} - ${profile?.age.to || ''}`;
  }, [profile]);

  const getInfo = React.useCallback(() => {
    let info = {};
    if (!profile) return info;
    if (profile.story) info = { ...info, 'My Story': profile.story };
    if (profile.diagnoses.length)
      info = {
        ...info,
        'My Primary Diagnosis': profile.diagnoses.join(', '),
      };
    if (profile.interests.length)
      info = { ...info, 'My Interests': profile.interests.join(', ') };

    return info;
  }, [profile]);

  return {
    avatar: profile?.avatar,
    nickname: profile?.nickname,
    country: profile?.country,
    karmaPoint: profile?.karma,
    ageRange: getAgeRange(),
    info: getInfo(),
  };
};

export default useProfile;
