// @flow
import * as React from 'react';
import StarIcon from '@mui/icons-material/Star';
import Skeleton from '@mui/material/Skeleton';

type MembersProps = {
  numberOfMembers: number;
  isEmptyCard?: boolean;
  className?: string;
  loading?: boolean;
};

const Members: React.FC<MembersProps> = ({
  numberOfMembers,
  isEmptyCard = false,
  className,
  loading,
}) => {
  const id = React.useId();

  if (loading)
    return (
      <Skeleton
        variant="text"
        width={146}
        height={25}
        className="absolute right-2 top-4 rounded-[50px]"
      />
    );

  return (
    <div
      className={`absolute flex items-center justify-center bg-white rounded-[50px] h-[25px] w-[146px] text-on-secondary tags
                 ${className}`}
    >
      {isEmptyCard ? (
        <div>
          {[...new Array(3)].map((_, index) => (
            <StarIcon
              className="!w-[12px] !h-[12px] !mr-1 !ml-1 !text-brand-primary"
              key={`star-${id}-${index}`}
            />
          ))}
        </div>
      ) : (
        `${numberOfMembers} MEMBER${numberOfMembers || 0 > 1 ? 'S' : ''}`
      )}
    </div>
  );
};
export default Members;
