// @flow
import * as React from 'react';
import EventsViewHeader from '@view/home-page/components/event-view/EventsViewHeader';
import EventsList from '@view/home-page/components/event-view/EventsList';

const EventsView: React.FC<unknown> = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div className="w-full h-full mt-3 bg-white max-w-[540px]">
      <EventsViewHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      <EventsList activeTab={activeTab} />
    </div>
  );
};
export default EventsView;
