import { createAsyncThunk } from '@reduxjs/toolkit';

import MD5 from 'crypto-js/md5';
import axios from 'axios';
import { FileUtils } from '@utils/index';
import { ApiEndpoint } from '@utils/types';
import { AppSelector } from '../app/app.selector';

export const getEmbedRock = createAsyncThunk(
  'attachment/getEmbedRock',
  async (link: string, { getState }) => {
    const state = getState() as any;
    const { embedrockKey } = state.app.auth;
    const response = await axios.get(`${ApiEndpoint.EMBED_ROCKS}`, {
      params: {
        url: link,
        key: embedrockKey,
      },
    });

    if (response.data.type === 'error') return null;
    return response.data;
  },
);

export const getAttachmentRequest = createAsyncThunk(
  'shared/getAttachments',
  async (
    {
      attachments,
      link,
      key,
      isComment,
    }: {
      attachments: ({ file: File; content: string } | string)[];
      link?: string;
      key?: string;
      isComment?: boolean;
    },
    { getState, dispatch },
  ) => {
    const userEmail = AppSelector.selectUserEmail(getState() as any).email;
    const form = new FormData();

    await FileUtils.filesToForm(
      (
        attachments.filter((a) => typeof a === 'object') as {
          file: File;
          content: string;
        }[]
      ).map((a) => FileUtils.fileToAttachment(a.file, a.content)),
      form,
      MD5(userEmail),
      isComment,
      key,
    );

    if (link) {
      form.append('postOrder[]', 'W0');
      form.append('postTypeMeta[W0][url]', link);
      const embedRocksData = await dispatch(getEmbedRock(link)).unwrap();
      form.append(
        'postTypeMeta[W0][embed_rocks_data]',
        JSON.stringify(embedRocksData),
      );
    }

    return form;
  },
);
