import React, { Suspense } from 'react';
import LoadingPage from '@shared/components/loading-page';

const CreateContent = React.lazy(() => import('./CreateContent'));

export default () => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <CreateContent />
    </Suspense>
  );
};
