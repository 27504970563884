import * as React from 'react';

import { TextKeys } from '@lib/i18n/configureI18n';
import { useTranslation } from 'react-i18next';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import Tabs from '@shared/components/tabs/Tabs';

import { EventsView, TimelineView } from '@view/home-page/components';

/**
 * Home View
 */
const HomePage: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const { t } = useTranslation();

  const tabs = [t(TextKeys.Feed), t(TextKeys.Events)];
  const scroller = React.useRef<HTMLDivElement>(null);

  return (
    <WithSideBar>
      <div
        className="flex flex-col items-center w-full h-full pl-3 pr-3 overflow-y-auto sm:pl-0"
        ref={scroller}
      >
        <div className="flex flex-col items-center w-full pt-6 pb-5 sm:h-full max-w-[540px] h-fit">
          <Tabs tabs={tabs} onTabChanged={setSelectedTab} />
          {selectedTab === 0 ? (
            <TimelineView scroller={scroller} />
          ) : (
            <EventsView />
          )}
        </div>
      </div>
    </WithSideBar>
  );
};

export default HomePage;
