import * as React from 'react';
import { Card, IconButton } from '@mui/material';
import { ReactComponent as EmptyViewSvg } from '@assets/images/no_community.svg';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';

const NotFoundError = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex-col-center">
      <Card className="flex pt-[73px] pr-[62px] pl-[100px] pb-[60px]">
        <div className="max-w-[370px]">
          <h1 className="mb-10 h1 text-brand-primary">Oops!</h1>
          <p className="body2 ">{t(TextKeys.ERROR_PAGE_NOT_FOUND)}</p>
          <div className="w-full flex-col-center">
            <IconButton className="text-white w-[156px] h-[40px] bg-brand-primary rounded-[3px] body1 mt-7">
              <a href="/">{t(TextKeys.GO_TO_FEED)}</a>
            </IconButton>
          </div>
        </div>
        <EmptyViewSvg className="-mt-7 -ml-7 h-[256px]" />
      </Card>
    </div>
  );
};

export default NotFoundError;
