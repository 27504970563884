import React from 'react';
import { Card } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import DatePicker from './components/DatePicker';
import TimePicker from './components/TimePicker';
import {
  DraftActions,
  DraftContext,
  DraftShareActions,
} from '../../hooks/useDraftActions';

type SchedulePostDialogProps = {
  isOpen: boolean;
  onClose: () => () => void;
};

const SchedulePostDialog: React.FC<SchedulePostDialogProps> = (props) => {
  const { isOpen, onClose } = props;

  const { setScheduledTime } = React.useContext(DraftContext) as DraftActions;
  const { onSchedule } = React.useContext(DraftContext) as DraftShareActions;

  const scheduleButton = {
    label: 'Schedule',
    disabled: false,
    action: () => () => {
      onSchedule();
    },
  };

  const [date, setDate] = React.useState<Date>(moment().toDate());
  const [day, setDay] = React.useState<Date | null>(new Date());
  const [time, setTime] = React.useState<{
    hour: number;
    minute: number;
    timezone: string;
  }>({
    hour: moment().add(1, 'hour').hour(),
    minute: moment().minute(),
    timezone: moment.tz.guess(),
  });

  React.useEffect(() => {
    setDate(
      moment(day)
        .set('hour', time.hour)
        .set('minute', time.minute)
        .set('second', 0)
        .tz(moment.tz(time.timezone).format('z'))
        .toDate(),
    );
  }, [day, time]);

  React.useEffect(() => {
    setScheduledTime(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const renderHeader = () => (
    <div className="flex items-center -ml-3">
      <IconButton className="mr-2 !text-on-primary" onClick={onClose()}>
        <ArrowBackIcon />
      </IconButton>
      <div className="h3 text-on-primary">Schedule your post</div>
    </div>
  );

  const renderScheduledTime = () => (
    <div className="w-full subtitle1 text-success mt-[28px] mb-[14px]">
      {`This post will be shared on ${moment(date).format(
        'dddd, MMMM D, YYYY [at] hh:mm A z ',
      )}
            ${moment.tz(time.timezone).format('z')}.`}
    </div>
  );

  return (
    <Card className="w-full h-auto pt-6 pb-5 sm:w-[540px] sm:h-[470px] pr-[30px] pl-[30px]">
      {renderHeader()}
      {renderScheduledTime()}
      <DatePicker setDate={setDay} />
      <TimePicker setTime={setTime} />
      <Button
        disabled={scheduleButton.disabled}
        variant="contained"
        classes={{
          root: "!w-[156px] !h-10 !bg-brand-primary !font-medium !text-sm !font-['Roboto'] !absolute !bottom-[20px] !right-[30px]",
        }}
        onClick={scheduleButton.action()}
      >
        {scheduleButton.label}
      </Button>
    </Card>
  );
};

export default SchedulePostDialog;
