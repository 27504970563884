import { TextKeys } from '@lib/i18n/configureI18n';
import { Button as MuiButton } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import useBreakpoint from '@hooks/use-breakpoint/useBreakpoints';
import { useTranslation } from 'react-i18next';
import Endpoints from '@view/routes/endpoints';
import { isDesktopBreakpoint } from '../../../../utils';

type EventsViewHeaderProps = {
  activeTab: number;
  setActiveTab: (activeTab: number) => void;
};

const EventsViewHeader: React.FC<EventsViewHeaderProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint();
  const { t } = useTranslation();

  const changeTab = React.useCallback(
    (index): (() => void) =>
      () =>
        setActiveTab(index),
    [setActiveTab],
  );

  return (
    <div className="flex items-center justify-between w-full pt-5 pl-4 pr-4">
      <div>
        {[t(TextKeys.MyEvents), t(TextKeys.Recommended)].map((tab, index) => {
          return (
            <MuiButton
              className={`!h-[40px]  mr-6 ${
                index === activeTab ? '!bg-[#D3E5EC]' : ''
              }`}
              onClick={changeTab(index)}
              key={tab}
            >
              {tab}
            </MuiButton>
          );
        })}
      </div>
      <Button
        onClick={(): void => navigate(Endpoints.CREATE_EVENT)}
        variant="contained"
        color="primary"
        className="!h-[40px] w-[40px] sm:w-[157px] flex-col-center"
      >
        {isDesktopBreakpoint(breakpoint) ? (
          t(TextKeys.CreateEvent)
        ) : (
          <AddIcon />
        )}
      </Button>
    </div>
  );
};

export default EventsViewHeader;
