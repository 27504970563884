import React from 'react';

import InsertLinkIcon from '@mui/icons-material/InsertLink';

const useLink = (props?: {
  title?: string;
  url?: string;
  description?: string;
}) => {
  const LinkPreview = React.useMemo(() => {
    if (!props) return () => null;

    const { title, url, description } = props;

    // const isMobile = useIsMobile();
    const Component: React.FC = () => (
      <div className="flex items-center justify-between w-full h-10 cursor-pointer sm:h-20 bg-secondary-background opacity-90">
        <div className="flex flex-col justify-around w-8/12 h-full pt-1 pb-1 pl-4">
          <span className="text-xs truncate text-on-secondary sm:bottom-nav">
            {url}
          </span>
          <span className="truncate text-on-primary h6 sm:h5">{title}</span>
          <span className="hidden truncate text-on-secondary body2 sm:block">
            {description}
          </span>
        </div>
        <div className="mr-4 rounded-full opacity-25 w-7 sm:w-14 h-[25px] sm:h-[50px] bg-on-primary flex-col-center">
          <InsertLinkIcon className="w-5 h-5 !fill-on-terciary sm:!w-10 sm:!h-10" />
        </div>
      </div>
    );

    return Component;
  }, [props]);

  return { LinkPreview };
};

export default useLink;
