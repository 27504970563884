import React from 'react';
import Logo from '../../../../../components/logo/Logo';

const MenuDrawerHeader: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => {
  return (
    <div className="w-full h-[74px] bg-lighter">
      <Logo className="w-32 h-10 m-5" />
    </div>
  );
};

export default MenuDrawerHeader;
