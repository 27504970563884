import * as React from 'react';
import useBreakpoint from '@hooks/index';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import WithBanner from '@shared/templates/with-banner/WithBanner';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import useAuth from './hooks/useAuth';
import { isKeyboardOpen } from '../../utils';
import { loginErrors } from '../../models/constants/messages/errors';

/**
 * Auth Page (Login & Reset Password)
 *
 * Login and Reset Password form holder
 */
const AuthView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    handleSubmit,
    handleForgotPasswordClick,
    onChangePassword,
    onChangeEmail,
    error,
    disabledSubmitButton,
    loading,
  } = useAuth();

  const { dimensions } = useBreakpoint();
  const app = useSelector(AppSelector.selectActiveApp);

  const renderLogo = () => {
    if (isKeyboardOpen(dimensions)) return null;
    return (
      <img
        src={app.imgUrl}
        className="absolute h-auto w-[200px] sm:w-52 xl:w-28 inset-5 sm:inset-7 xl:inset-12"
      />
    );
  };

  return (
    <WithBanner>
      <div className="full">
        {renderLogo()}
        <div className="flex flex-col items-center justify-center full">
          <p className="h1 text-primary mb-3.5 sm:mb-6 xk:mb-3.5">Log in</p>
          <p className="mb-5 text-center subtitle1 text-secondary w-72 xl:w-full sm:mb-6 xl:mb-12">
            Login with the data you <br className="block xl:hidden" />
            used during your registration
          </p>
          <form className="flex flex-col items-center justify-center w-full">
            <TextField
              type="email"
              label="Email"
              required
              error={error?.email}
              variant="outlined"
              placeholder="Insert here your email"
              className="mb-5 w-80 sm:w-1/2"
              autoComplete="email"
              onChange={onChangeEmail()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit()();
                }
              }}
            />
            <TextField
              type="password"
              label="Password"
              error={error?.password}
              variant="outlined"
              className=" w-80 sm:w-1/2"
              placeholder="Password"
              autoComplete="current-password"
              onChange={onChangePassword()}
              required
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit()();
                }
              }}
            />
            <p className="flex items-center justify-center invisible w-1/2 h-16 error sm:visible">
              {error && (error.message || loginErrors.WRONG_CREDENTIAL)}
            </p>
            <div className="flex flex-col items-center justify-center">
              <LoadingButton
                disabled={disabledSubmitButton}
                loading={loading}
                variant="contained"
                type="button"
                onClick={handleSubmit()}
                color="primary"
                className="mb-5 sm:mb-14 xl:mb-10"
              >
                LogIn
              </LoadingButton>
              <p className="block h-12 mb-1 text-center error w-80 sm:hidden">
                {error && loginErrors.WRONG_CREDENTIAL}
              </p>
              <Button
                type="button"
                className="mt-4 link"
                onClick={handleForgotPasswordClick()}
                aria-label="Forgot password"
              >
                Forgot your password?
              </Button>
            </div>
          </form>
        </div>
      </div>
    </WithBanner>
  );
};

export default AuthView;
