// @flow
import * as React from 'react';
import { useContext } from 'react';
import {
  ContentListFilterContext,
  ContentListFilterContextValue,
} from '@view/content-management-system/context/ContentListFilterContext';
import ContentFilter from '@view/content-management-system/components/ContentFilter';

const ContentListHeader: React.FC<unknown> = () => {
  const { sorts } = useContext(
    ContentListFilterContext,
  ) as ContentListFilterContextValue;

  const [sort, setSort] = React.useState<
    | {
        index: number;
        direction: 'a' | 'd';
      }
    | undefined
  >(undefined);

  React.useEffect(() => {
    sorts.setValue(sort);
  }, [sort, sorts]);

  const onChangeSort = (index: number) => () => {
    if (index === sort?.index && sort.direction === 'd')
      setSort({ index, direction: 'a' });
    else if (index === sort?.index && sort.direction === 'a')
      setSort(undefined);
    else setSort({ index, direction: 'd' });
  };

  return (
    <div className="flex w-full pl-5 pr-6 mb-3">
      {sorts.options.map((filter, index) => (
        <ContentFilter
          key={index}
          label={filter.label}
          ratio={filter.ratio}
          selected={index === sort?.index}
          onSortChanged={onChangeSort}
          direction={sort?.direction}
          index={index}
        />
      ))}
    </div>
  );
};
export default ContentListHeader;
