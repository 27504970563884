import React from 'react';
import Header from './components/header';

export interface WithHeaderProps {
  HeaderContent?: React.ReactNode;
}

const WithHeader: React.FC<React.PropsWithChildren<WithHeaderProps>> = ({
  HeaderContent,
  children,
}) => {
  return (
    <>
      <Header>{HeaderContent}</Header>
      <div>{children}</div>
    </>
  );
};

WithHeader.defaultProps = {
  HeaderContent: null,
};

export default WithHeader;
