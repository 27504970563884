import { Friend } from './MyCircle.model';

export const convertFriendResponse = (response: any): Friend => {
  return {
    id: response.id,
    info: {
      nickname: response.profile.nick_name,
      avatar: response.profile.img_url,
      age: {
        from: response.profile.age_from,
        to: response.profile.age_to,
      },
      country: response.profile.country,
    },
    isOnline: response.status === 'Active',
  };
};

export const convertMyCirceResponse = (response: any[]) => {
  return response.map((r) => convertFriendResponse(r.receiver));
};
