// @flow
import React, { Suspense, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useAppDispatch from '@data/useAppDispatch';
import { getFeed } from '@data/timeline/timeline.thunk';

import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import { TimeLines } from '@data/timeline/timeline.model';
import Endpoints from '@view/routes/endpoints';
import LoadingPage from '@shared/components/loading-page';
import MakePostDialogProvider from '@shared/components/make-post/MakePostDialogProvider';
import { MakePostDialogOpeningModeAction } from '@shared/components/make-post/models/MakePostAction';

type PostDetailsProps = any;

const Post = React.lazy(() => import('@shared/components/post/Post'));

const PostDetails: React.FC<PostDetailsProps> = () => {
  const { feedId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] =
    React.useState<MakePostDialogOpeningModeAction>({
      mode: 'close',
    });

  const handleEditPost = () => {
    setOpenDialog({ mode: 'open', id: `p-${feedId}` });
  };

  const handleCloseMakePostDialog = React.useCallback(
    () => () => {
      setOpenDialog({ mode: 'close' });
    },
    [],
  );

  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    dispatch(getFeed({ feedID: Number(feedId) }))
      .then((p) => {
        setLoading(false);
      })
      .catch((e) => {
        if (e?.code === 4012) navigate(Endpoints.NOT_FOUND);
      });
  }, [dispatch, feedId, navigate, setLoading]);

  return (
    <WithSideBar>
      <div className="flex items-start justify-center w-full h-full pt-5 pb-5">
        <MakePostDialogProvider
          open={openDialog}
          closeDialog={handleCloseMakePostDialog()}
        />
        <Suspense fallback={<LoadingPage />}>
          <Post
            postKey={`p-${feedId}`}
            key={`p-${feedId}`}
            showComments
            loading={loading}
            timeline={TimeLines.DETAILS}
            onEditPost={handleEditPost}
          />
        </Suspense>
      </div>
    </WithSideBar>
  );
};

export default PostDetails;
