import { ReactReduxContext, useDispatch } from 'react-redux';
import React, { useContext } from 'react';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';

const useAppDispatch = (): ((
  action: AsyncThunkAction<any, any, any>,
) => Promise<any>) => {
  const { store } = useContext(ReactReduxContext);
  const dispatch = useDispatch<typeof store.dispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const BLACK_LIST = React.useMemo(() => [4012, 4002, 4000], []);

  const enqueueError = React.useCallback(
    (message: string, code: number) => {
      if (!BLACK_LIST.includes(code))
        enqueueSnackbar(message, { variant: 'error' });
    },
    [BLACK_LIST, enqueueSnackbar],
  );

  const appDispatch = React.useMemo(
    () => (action: AsyncThunkAction<any, any, any>) => {
      return new Promise((resolve: (_: any) => any, reject) => {
        // @ts-ignore
        dispatch(action)
          .unwrap()
          .then((data: any) => {
            resolve(data);
          })
          .catch((err) => {
            try {
              const error = JSON.parse(err.message);
              enqueueError(error.message, error.code);
              reject(error);
            } catch (e) {
              // enqueueError('Something went wrong!', 500)
              reject(err);
            }
          });
      });
    },
    [dispatch, enqueueError],
  );

  return appDispatch;
};

export default useAppDispatch;
