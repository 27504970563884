/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useCallback } from 'react';
import { ReactComponent as StarSvg } from '@assets/icons/star.svg';
import { ReactComponent as MyFriendSvg } from '@assets/icons/friends.svg';
import { useNavigate } from 'react-router-dom';
import { pathOr } from 'ramda';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import Size from '../../../../models/constants/size';

export const avatarSizes = {
  [Size.XSMALL]: 2.5,
  [Size.SMALL]: 3.7,
  [Size.MEDIUM]: 4,
  [Size.LARGE]: 6,
  [Size.XLARGE]: 9,
};

export interface AvatarProps {
  src?: string;
  size?: Size;
  withOnlineSign?: boolean;
  online?: boolean;
  className?: string;
  userId?: string | number;
  username?: string;
  nickName?: string;
  isAdmin?: boolean;
  isFriend?: boolean;
}

export const defaultAvatar =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAMFBMVEXFxcX////CwsLGxsb7+/vT09PJycn19fXq6urb29ve3t7w8PDOzs7n5+f5+fnt7e30nlkBAAAFHUlEQVR4nO2dC5qqMAyFMTwUBdz/bq+VYYrKKJCkOfXmXwHna5uTpA+KwnEcx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3Ecx3EcA2iO9cdIc5PUdO257y+BU39u66b4HplE3fk6VIcnqmNfl1+gksr6+iIucjl3WYukor7+re6Hoe1y1UhNO3zUd+fUFRmKpOa0Tt6dY5ubRCrOG/QFLk1WGmnt/JxzykcjdZ/jyxJDLlOV2l36AtcsJJb9boG3YcR3DuqODIE3ztYKPkDdmwRmpUToUaSaq++AvRgZMWbOpbQW8hdCAm8ZDugoikzREdCJ2okJPBx6azFLNOwoOgcxojJ98JkaTSJxMpklKrCAKhZGI0drTY/wU5lXoJYibannV9NYy4oozNEAkPHTjop+DTDxVGkIgYJNoyQQJtiIW+EMjGAjm649AjGIaqswcEFQKJ2QPlJbqytki6ZXAAZRJ52J2McaUowzAfs+uFzrYhnzaapphiPWdaJWShqxjqa6kTTQ205TVbsfMa6htL0iYOsXpJrQjHSmCkv1QGPtiHqlYcQ21Gj7fcDU8xOEUuNgSltPzexh+HqFlanCBHZ4OLhCV+gK/3OF6vWvucLv98MUOY2pwu/PS/+D2qJU7pYGbOvDFDW+bbON9p3o3oRxn0bfLgZTgSn6pSfrtr56qLHemtHPTK2319SzGvtjQ9qeb39WgS66Cm073nd0U1PzDdJCO3Gzn6TKpl9Zq7ujGWsQhlA3NwWIMwG9zM08Y/tBrR9VWeczv5CSQuuUNKIUTk23ZJ5RKfVhjnkXotfWIlgX2BSCDYbZR+QTcLhb3dKZDUY2M0d4KWItwhHRah/zsrOgKw4wycwjcgEVcgQDQo23CqSiWEJkFAfod2oE1uIFdA1OsCPqFXYNTjCfb8Ez+iX2x5sKLlVbhtqdDcar9ZevhnbZxoBUD35k23t0d304LYs1ELVbnfFaZ/REJJX9niP8Q19moZGo3m8XR/yBvOnjFfsXcI2c8ZuNo7WMP5HQh6yRGrlmFOJTnyTcT+zRlqPUBI2gTVWNUzUna1ERgecgF4GpNBQ38jGqxVLzQA1A31Rrhk6Yz9QEh/WND0GnuG9huhiTXJkxfAizTHLr6cbJKN6UCU6x/2DTRE1xEeEXi3O0ZUqBN4nJRzHhFB1JPlFTBZlI2kQ8zc3KJ1Le8DIRmFJiknuVS6RK4Ej/JtBfJErDSzOBiY4wJHX6Z1I4v1GUmdCPNirnLLeg3oJLcbX5PcpHNbRvOa1A956QmRPOUXVF+zkaUJynpkYR0bOMJH2nNej1pqyV/aKkz9jr5yj5vrXXz1F5SQLACiMapmierj2ikLyleKdlA/I/2oFxiglxx9B+mHwz0lf34IZQfhDRhlD6bhvgEAoPYooHkTczSIZTLC+cEExsoNKZiGBiY9cCfo/Y/SjIOBMQizWWTe73CMUasJx7jlD+DdKdWUKoY4PRYFtGpO0G1Lx4RaadgTtJhf4fiGqGIwKWCGuGIwKWqP+7IxYCzygjR9IAO5pC7Da9g70TBVpWRNgFBlgT8RV2WxHbKwJMv4BOaEaYaU2K16yZMN/qgV+G7IWIvwyZCxHeDQMsR8wg0DBDDXB5H2EV+hkEGmaoySHQsEJNFoGGFWrAq98JRhUMX1iMMMqLLEIpK5jCbd4vw9nSt/72lewXiN6jmdjfq8Hdknlk92ZwJnbIMMRM7JBhiFlUFoHd1UWaP1QKsPsHA5mkNB+Smn9JqV3wskatnQAAAABJRU5ErkJggg==';

const Avatar: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<AvatarProps>>
> = ({
  src,
  size = Size.MEDIUM,
  withOnlineSign = false,
  online = false,
  className,
  userId,
  username,
  nickName,
  isAdmin,
  isFriend,
}) => {
  /**
   * Avatar
   */

  const classes = `inline-block rounded-full border border-solid border-[#e6e6e6] shadow-md ${className} ${
    withOnlineSign ? 'relative' : ''
  } ${userId !== undefined ? 'cursor-pointer' : ''}`;
  const navigate = useNavigate();

  const handleImageLoadingError = useCallback(
    () =>
      ({ currentTarget }) => {
        // eslint-disable-next-line no-param-reassign
        currentTarget.onerror = null; // prevents looping
        // eslint-disable-next-line no-param-reassign
        currentTarget.src = defaultAvatar;
      },
    [],
  );

  const getSize = (size: Size) => {
    if (size === Size.CUSTOM) return {};
    const a = `${pathOr(avatarSizes[Size.MEDIUM], [size], avatarSizes)}rem`;
    return {
      width: a,
      height: a,
    };
  };

  return (
    <div
      className={classes}
      onClick={() => {
        if (userId) {
          navigate(endpointIDReplacer(Endpoints.PROFILE_ID, userId));
        }
      }}
      role="button"
      style={getSize(size)}
    >
      <img
        src={src || defaultAvatar}
        className="w-full h-full rounded-full"
        onError={handleImageLoadingError()}
        alt={`${username}'s Profile Picture`}
      />
      {withOnlineSign ? (
        <div
          className={`absolute ${
            !nickName ? 'top-3/4' : 'top-[10%]'
          } left-3/4 ${online ? 'bg-brand-primary' : 'bg-on-secondary'}
                 w-3 h-3 border-2 rounded-full border-white`}
        />
      ) : null}
      {nickName && (
        <div className="absolute pt-1 pb-1 pl-4 pr-4 bg-white border rounded-full bottom-nav text-on-primary border-secondary-background w-[100px] -bottom-4 -right-5 flex-col-center">
          <span className="w-full h-full text-center truncate">{nickName}</span>
        </div>
      )}

      {isAdmin && (
        <StarSvg className="absolute w-7 h-7 -right-[10px] top-[30%]" />
      )}
      {isFriend && !isAdmin && (
        <MyFriendSvg className="absolute w-7 h-7 -right-[10px] top-[30%]" />
      )}
    </div>
  );
};

export default React.memo(Avatar, (prev, next) => {
  return prev.src === next.src;
});
