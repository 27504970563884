import * as React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ExitIcon } from '@assets/icons/exit.svg';
import IconButton from '@mui/material/IconButton';

export interface CommunitySelectorProps {
  activeCommunityName?: string;
}

const CommunitySelector: React.FC<CommunitySelectorProps> = ({
  activeCommunityName,
}) => {
  return (
    <IconButton className="rounded-none">
      <Link
        className="flex items-end pl-4 pr-4 text-black rounded cursor-pointer h6"
        to="/portal"
        type="button"
      >
        <div className="flex flex-col items-start mr-4">
          <span className="inline-block text-grey-dark caption opacity-70 !leading-3">
            Community
          </span>
          {activeCommunityName}
        </div>
        <div className="w-6 h-6 mb-1 !fill-on-primaryÏ">
          <ExitIcon />
        </div>
      </Link>
    </IconButton>
  );
};

CommunitySelector.defaultProps = {
  activeCommunityName: 'Unknown Community',
};

export default CommunitySelector;
