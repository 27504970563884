import { useEffect } from 'react';

export enum NavigateKeys {
  UP,
  DOWN,
  RIGHT,
  LEFT,
  ENTER,
}

const useNavigateKey = (action) => {
  useEffect(() => {
    const keyPress = (e) => {
      if (e.keyCode === 37) {
        // move left or wrap
        action(NavigateKeys.LEFT);
        return false;
      }
      if (e.keyCode === 38) {
        // move up
        action(NavigateKeys.UP);
        return false;
      }
      if (e.keyCode === 39) {
        // move right or wrap
        action(NavigateKeys.RIGHT);
        return false;
      }
      if (e.keyCode === 40) {
        // move down
        action(NavigateKeys.DOWN);
        return false;
      }
      if (e.key === 'Enter') {
        // move down
        action(NavigateKeys.ENTER);
        return false;
      }
      return true;
    };
    window.addEventListener('keyup', keyPress);
    return () => window.removeEventListener('keyup', keyPress);
  }, [action]);
};

export default useNavigateKey;
