// @flow
import * as React from 'react';
import { useParams } from 'react-router-dom';
import GroupEventDetailsView from '@shared/templates/group-event-view/GroupEventDetailsView';
import useAppDispatch from '@data/useAppDispatch';
import { fetchSocialEventParticipants } from '@data/social-events/socialEvents.thunk';
import { DropDownItem } from '@shared/components/dropDown-menu/DropDownMenu';
import useSocialEventDetails from './hooks/useSocialEventDetails';
import { LoadingState } from '../../models/Loading';

const SocialEventDetails: React.FC<unknown> = () => {
  const { eventId } = useParams();
  const socialEventDetails = useSocialEventDetails(eventId);

  const [membersLoaded, setMembersLoaded] = React.useState(false);
  const dispatch = useAppDispatch();

  const { loadSocialEvent } = socialEventDetails;

  React.useEffect(() => {
    if (
      !membersLoaded &&
      socialEventDetails.numberOfMembers &&
      eventId &&
      socialEventDetails.userIsJoined
    ) {
      dispatch(
        fetchSocialEventParticipants({
          eventId: Number(eventId),
          totalNumberOfParticipants: socialEventDetails.numberOfMembers,
        }),
      ).finally(() => {
        setMembersLoaded(true);
      });
    }
  }, [
    membersLoaded,
    socialEventDetails.numberOfMembers,
    eventId,
    dispatch,
    socialEventDetails.userIsJoined,
  ]);

  const loading = React.useMemo(
    () =>
      loadSocialEvent === LoadingState.LOADING ||
      loadSocialEvent === LoadingState.IDLE,
    [loadSocialEvent],
  );

  return (
    <GroupEventDetailsView
      loading={loading}
      type="event"
      title={socialEventDetails.title}
      cover={socialEventDetails.cover}
      description={socialEventDetails.description}
      onJoin={socialEventDetails.onJoin}
      onLeave={socialEventDetails.onLeave}
      time={socialEventDetails.time}
      admin={socialEventDetails.admin}
      members={socialEventDetails.members}
      numberOfMembers={socialEventDetails.numberOfMembers}
      userIsJoined={socialEventDetails.userIsJoined}
      location={socialEventDetails.location}
      onLoading={socialEventDetails.onLoading}
      userIsAdmin={socialEventDetails.userIsAdmin}
      options={socialEventDetails.options as DropDownItem[]}
      onOptionClick={socialEventDetails.onOptionClick}
    />
  );
};
export default SocialEventDetails;
