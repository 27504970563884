import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Card } from '@mui/material';

const ArticleViewSkeleton: React.FC = () => {
  return (
    <Card className="flex flex-col w-full h-full overflow-auto sm:w-[66%] md:w-1/2 xl:w-1/3 max-w-[760px]">
      <Skeleton
        variant="rectangular"
        className="object-cover w-full h-[29%] max-h-[278px]"
      />
      <div className="relative flex-1 pl-6 pr-6">
        <div className="flex items-center justify-start w-full mt-4 caption text-[#4D565F]">
          <Skeleton variant="rectangular" className="w-4 h-4 mr-2" />
          <Skeleton variant="text" className="w-8 h-4 mr-2" />
          <Skeleton variant="text" className="w-4 h-4 mr-2" />
        </div>
        <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
        <div>
          <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
          <Skeleton variant="rounded" width={210} height={100} />
          <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
        </div>
      </div>
    </Card>
  );
};

export default ArticleViewSkeleton;
