/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Drawer, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Endpoints from '@view/routes/endpoints';
import NotificationsList from '../../../../components/notifications-list/NotificationsList';

type propsType = {
  direction?: 'left' | 'top' | 'right' | 'bottom';
  open?: boolean;
  onClose: () => void;
};

const NotificationsDrawer: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<propsType>>
> = ({ direction = 'right', open = false, onClose }) => {
  const navigate = useNavigate();

  const seeAllNotifications = (): void => {
    navigate(Endpoints.NOTIFICATIONS);
    onClose();
  };

  return (
    <Drawer
      className="w-[424px] shrink-0"
      classes={{ paper: 'w-[424px]', root: 'z-[900]' }}
      anchor={direction}
      open={open}
      onClose={onClose}
    >
      <Toolbar />
      <div className="flex flex-col h-full overflow-hidden w-[424px]">
        <div className="flex items-center justify-between w-full p-5 h-[66px]">
          Notification
          <div onClick={onClose} className="cursor-pointer">
            Close Panel
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <NotificationsList open={open} />
        </div>
        <div
          className="flex items-center justify-center h-6 cursor-pointer"
          onClick={seeAllNotifications}
        >
          SEE ALL
        </div>
      </div>
    </Drawer>
  );
};

NotificationsDrawer.defaultProps = {
  direction: 'right',
  open: false,
};

export default NotificationsDrawer;
