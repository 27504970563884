// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import NotificationsList from '@shared/components/notifications-list/NotificationsList';

const NotificationsView: React.FC<unknown> = () => {
  const { t } = useTranslation();

  return (
    <WithSideBar>
      <div className="flex flex-col items-center justify-start w-full h-full">
        <div className="w-full h-full pt-5 max-w-[760px]">
          <h3 className="mt-2 ml-3 h3 text-on-primary mb-7 sm:ml-0 sm:mt-0">
            {t(TextKeys.Notifications)}
          </h3>
          <div className="w-full overflow-auto bg-white h-[95%]">
            <NotificationsList open />
          </div>
        </div>
      </div>
    </WithSideBar>
  );
};
export default NotificationsView;
