import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { Friend } from './MyCircle.model';
import { fetchMyCircle } from './MyCircle.thunk';
import { convertMyCirceResponse } from './MyCircle.factory';

export const MyCircleAdapter = createEntityAdapter<Friend>();

const isFulfilledFetch = (action: AnyAction): boolean =>
  action.type?.includes('my-circle/fetch') &&
  action.meta.requestStatus === 'fulfilled';

export const MyCircleSlice = createSlice({
  name: 'myCircle',
  initialState: MyCircleAdapter.getInitialState({ page: 1, total: -1 }),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchMyCircle.fulfilled, (state, action) => {
        if (action.meta.arg.page === 1)
          MyCircleAdapter.setAll(
            state,
            convertMyCirceResponse(action.payload.contacts),
          );
        else
          MyCircleAdapter.addMany(
            state,
            convertMyCirceResponse(action.payload.contacts),
          );
      })
      .addMatcher(isFulfilledFetch, (state, action) => {
        return {
          ...state,
          total: action.payload.count,
          page: action.meta.arg,
        };
      }),
});

export default {
  reducers: MyCircleSlice.reducer,
  adapter: MyCircleAdapter,
  getInitialState: () =>
    MyCircleAdapter.getInitialState({ page: 1, total: -1 }),
};
