// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';

import moment from 'moment';
import { Input, InputLabel } from '@mui/material';
import { RequestedSupportGroup } from '@data/support-groups/supportGroups.model';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { ReviewSupportGroupsContext } from '@view/support-group-requests/context/ReviewSupportGroupsContext';

type RequestGroupCardProps = {
  request?: RequestedSupportGroup;
  loading?: boolean;
};

const RequestGroupCard: React.FC<RequestGroupCardProps> = (props) => {
  const { request, loading } = props;
  const { group } = request || { group: undefined };

  const [status, setStatus] = React.useState<
    'accepted' | 'rejected' | 'underReview'
  >('underReview');
  const [comment, setComment] = React.useState('');

  const { t } = useTranslation();
  const [loadingCoverImage, setLoadingCoverImage] = React.useState(false);
  const context = React.useContext(ReviewSupportGroupsContext);

  React.useEffect(() => {
    if (request && context)
      if (status === 'rejected') context.onReject(request.id, comment);
      else if (status === 'accepted') {
        context.onApprove(request.id);
      }
  }, [status, context, request, comment]);

  React.useEffect(() => {
    if (request && context)
      if (status === 'rejected') {
        context.onReject(request.id, comment);
      }
  }, [comment, context, request, status]);

  const renderButtons = (): React.ReactNode => {
    return (
      <div className="flex items-center justify-between w-full mt-5 mb-5 ">
        <Button
          className={`w-[220px] h-[40px] !bg-[#C84F4F] ${
            status === 'rejected' ? 'opacity-40 !text-white' : ''
          }`}
          variant="contained"
          onClick={(): void => setStatus('rejected')}
        >
          {t(TextKeys.groupRequestReject)}
        </Button>
        <Button
          className={`w-[220px] h-[40px] !bg-[#35978D] ${
            status === 'accepted' ? 'opacity-40 !text-white' : ''
          }`}
          variant="contained"
          onClick={(): void => setStatus('accepted')}
        >
          {t(TextKeys.groupRequestApprove)}
        </Button>
      </div>
    );
  };

  const renderGroupTitle = (): React.ReactNode => {
    return (
      <p className="mt-5 h3 text-on-primary !leading-6">{group?.info.title}</p>
    );
  };

  const renderCardBody = (): React.ReactNode => {
    return (
      <p className="mt-4 min-h-[100px] caption !text-base text-grey-dark !font-light !leading-[18px]">
        {group?.info.description}
      </p>
    );
  };

  const renderCommentSection = (): React.ReactNode => {
    return (
      <>
        <div className="w-full h-[1px] bg-secondary-background" />
        <div className="w-full pt-5 pb-2 pl-4 pr-4 h-[130px]">
          <InputLabel
            htmlFor="add-your-explanation"
            className="caption text-on-secondary"
          >
            {comment !== '' ? t(TextKeys.AddYourExplanation) : ''}
          </InputLabel>
          <Input
            disableUnderline
            autoFocus
            id="add-your-explanation"
            placeholder={t(TextKeys.AddYourExplanation)}
            fullWidth
            multiline
            maxRows={3}
            defaultValue={comment}
            onChange={(e): void => setComment(e.target.value)}
            classes={{
              input: "!text-sm !font-normal !leading-5 !font-['Roboto'] h-full",
              root: ' justify-start !items-start overflow-auto',
            }}
            className="flex justify-start flex-1 w-full !items-start"
          />
        </div>
      </>
    );
  };

  const renderHeader = (): React.ReactNode => {
    return (
      <div className="relative w-full pl-4 h-[80px]">
        <div className="absolute flex flex-col subtitle2 top-[20px] left-[95px]">
          {t(TextKeys.groupRequestedBy)}
          <p className="mb-0 text-brand-primary h6">
            {request && request.request.requester.nickname}
          </p>
        </div>
        <div className="absolute subtitle2 text-on-secondary top-[22px] right-5">
          {request && moment(request.request.requestAt).format('MMM DD, YYYY')}
        </div>
      </div>
    );
  };

  if (loading)
    return (
      <div className="relative w-full mt-4 bg-white border min-h-[330px] rounded-md border-secondary-background">
        <Skeleton variant="rectangular" width="100%" height={125} />
        <Skeleton variant="text" className="mt-4 ml-4" width="40%" />
        <Skeleton
          variant="rectangular"
          className="mt-4 ml-4 mr-4"
          width="90%"
          height="40%"
        />
      </div>
    );

  return (
    <div className="relative w-full mt-4 bg-white border min-h-[330px] rounded-md border-secondary-background">
      {renderHeader()}
      <img
        src={group?.info.coverImage}
        onLoad={(): void => setLoadingCoverImage(false)}
        // onLoadedData={(): void => setLoadingCoverImage(true)}
        alt={`${group?.info.title} cover image`}
        className={`object-cover w-full h-[125px] ${
          loadingCoverImage ? 'hidden' : 'block'
        }`}
      />
      <div className="pl-4 pr-4">
        {renderGroupTitle()}
        {renderCardBody()}
        {renderButtons()}
      </div>
      {status === 'rejected' && renderCommentSection()}
    </div>
  );
};

RequestGroupCard.defaultProps = {
  loading: false,
  request: undefined,
};

export default RequestGroupCard;
