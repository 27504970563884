import * as React from 'react';
import moment from 'moment-timezone';
import { Feed } from '@data/timeline/timeline.model';
import { SocialEvent } from '@data/social-events/socialEvents.model';

const useSocialEvent = (event?: Feed | SocialEvent) => {
  const startTime = moment(
    (event as Feed)?.event?.startTime || (event as SocialEvent).time?.startTime,
  );
  const endTime = moment(
    (event as Feed)?.event?.endTime || (event as SocialEvent).time?.endTime,
  );

  return {
    time: {
      day: moment(startTime).format('D'),
      month: moment(startTime).format('MMM'),
      start: moment
        .tz(startTime, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('hh:mm A'),
      end: moment
        .tz(endTime, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format('hh:mm A'),
      timezone: moment
        .tz(startTime, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .zoneAbbr(),
    },
  };
};

export default useSocialEvent;
