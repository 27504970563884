import React from 'react';

// MUI
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useDropDown from './hooks/useDropDown';

export interface DropDownItem {
  icon: React.ReactNode;
  label: string;
}

const DropDownMenu: React.FC<{
  options: DropDownItem[];
  onClick?: (action: string) => void;
}> = ({ options, onClick }) => {
  const { id, handleClose, handleOpen, open, anchorEl } = useDropDown();

  const handleClick = (label: string) => () => {
    if (onClick) onClick(label);
    handleClose()();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpen()}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((o, i) => (
          <MenuItem onClick={handleClick(o.label)} key={`${id}-${i}`}>
            {o.icon}
            <span className="ml-3">{o.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropDownMenu;
