import { Content } from '@data/contents/Contents.model';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  deleteContent,
  publishContent,
  unPublishContent,
} from '@data/contents/Contents.thunk';
import useAppDispatch from '@data/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import { createFileName, useScreenshot } from 'use-react-screenshot';
import UseConfirmModal from '@hooks/confirm-modal-hook/UseConfirmModal';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';

const useContentActions = (
  content?: Content,
  preview?,
  selectedStep?: number,
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [image, takeScreenShot] = useScreenshot();

  const [selectedAction, setSelectedAction] = React.useState<{
    onClick: () => void;
    label: string;
  } | null>(null);

  const [ConfirmationModal, showModal, hideModal] = UseConfirmModal({
    approveButton: {
      label: selectedAction?.label || '',
      action: () => {
        selectedAction?.onClick();
        hideModal();
      },
    },
    description: `Are you sure you want to ${selectedAction?.label.toLowerCase()} this content?`,
    hasLoading: true,
    rejectButton: {
      label: 'Cancel',
    },
    title: `${selectedAction?.label.toLowerCase()} content?`,
  });

  const unpublishContentAction = () =>
    dispatch(
      unPublishContent({
        id: content?.id || '',
      }),
    );

  const onUnPublishContent = React.useCallback(() => {
    setSelectedAction({
      onClick: () =>
        dispatch(
          unPublishContent({
            id: content?.id || '',
          }),
        ),
      label: 'Unpublish',
    });
    showModal();
  }, [content?.id, dispatch, showModal]);

  const onPublishContent = React.useCallback(() => {
    setSelectedAction({
      onClick: () =>
        dispatch(
          publishContent({
            id: content?.id || '',
          }),
        ),
      label: 'Publish',
    });
    showModal();
  }, [content?.id, dispatch, showModal]);

  const deleteContentAction = () =>
    dispatch(
      deleteContent({
        id: content?.id || '',
      }),
    );

  const onDeleteContent = React.useCallback(() => {
    setSelectedAction({
      onClick: () =>
        dispatch(
          deleteContent({
            id: content?.id || '',
          }),
        ),
      label: 'Delete',
    });
    showModal();
  }, [content?.id, dispatch, showModal]);

  const download = (image, { name = 'img', extension = 'png' } = {}) => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  React.useEffect(() => {
    if (image) {
      download(image, { name: content?.title, extension: 'png' });
    }
  }, [content?.title, image]);

  const actions = React.useMemo(() => {
    if (content?.status === 'draft')
      return [
        {
          label: 'EDIT PROJECT',
          icon: (
            <EditIcon sx={{ fontSize: 20 }} className="text-brand-primary" />
          ),
          onClick: () => {
            if (selectedStep && selectedStep > 0)
              navigate(
                endpointIDReplacer(Endpoints.DISCOVER_EDIT, selectedStep),
              );
            else
              navigate(
                endpointIDReplacer(Endpoints.DISCOVER_EDIT, content?.id),
              );
          },
        },
        {
          label: 'DELETE PROJECT',

          icon: <DeleteIcon sx={{ fontSize: 20 }} className="text-error" />,
          onClick: () => {
            setSelectedAction({
              onClick: () =>
                dispatch(
                  deleteContent({
                    id: content?.id || '',
                  }),
                ).then(() => {
                  navigate(Endpoints.DISCOVER);
                }),
              label: 'Delete',
            });
            showModal();
          },
        },
        // {
        //   label: 'DOWNLOAD SCREENSHOT',
        //   icon: (
        //     <DownloadIcon
        //       sx={{ fontSize: 20 }}
        //       className="text-brand-primary"
        //     />
        //   ),
        //   onClick: () => { g
        //     if (preview) takeScreenShot(preview.current);
        //   },
        // },
      ];
    return [
      {
        label: 'EDIT PROJECT',
        icon: <EditIcon sx={{ fontSize: 20 }} className="text-brand-primary" />,
        onClick: () => {
          if (selectedStep && selectedStep > 0)
            navigate(endpointIDReplacer(Endpoints.DISCOVER_EDIT, selectedStep));
          else
            navigate(endpointIDReplacer(Endpoints.DISCOVER_EDIT, content?.id));
        },
      },
    ];
  }, [
    content?.status,
    content?.id,
    selectedStep,
    navigate,
    showModal,
    dispatch,
  ]);

  const mainAction = React.useMemo(() => {
    if (content?.status === 'draft')
      return {
        label: 'PUBLISH',
        icon: null,
        onClick: onPublishContent,
      };
    return {
      label: 'UNPUBLISH',
      icon: null,
      onClick: onUnPublishContent,
    };
  }, [content?.status, onPublishContent, onUnPublishContent]);

  return {
    actions,
    mainAction,
    onUnPublishContent,
    onPublishContent,
    onDeleteContent,
    ConfirmationModal,
    deleteContentAction,
    unpublishContentAction,
  };
};

export default useContentActions;
