import React from 'react';
import { CircularProgress, Input } from '@mui/material';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';
import { Draft, DraftActions, DraftContext } from '../../hooks/useDraftActions';
import { FeedTypes } from '../../../../../../models/Feed';

const MakePostTitleEditor: React.FC<{
  selectedType: FeedTypes;
}> = ({ selectedType }) => {
  const { setTitle } = React.useContext(DraftContext) as DraftActions;
  const defaultValue = React.useContext(DefaultValueContext) as Draft;
  const [value, setValue] = React.useState<string | undefined>(
    defaultValue.title,
  );

  const placeHolder = React.useMemo(() => {
    if (selectedType === 'poll' || selectedType === 'question')
      return 'Ask a question...';
    return 'Add a title to what you’re sharing';
  }, [selectedType]);

  const MAXIMUM_CHAR = 80;

  const [numberOfChar, setNumberOfChar] = React.useState(0);

  const calculatePercent = () =>
    Math.min((numberOfChar / MAXIMUM_CHAR) * 100, 100);

  const getProgressColor = (): any =>
    numberOfChar >= MAXIMUM_CHAR ? 'on-error' : 'on-primary';

  const getNumberOfCharColor = () =>
    numberOfChar >= MAXIMUM_CHAR ? 'text-on-error' : 'text-on-secondary';

  const handleChangeTitle = () => (e) => {
    setValue(e.target.value);
    setNumberOfChar(e.target.value?.length);
  };

  React.useEffect(() => {
    setTitle(value || '');
  }, [setTitle, value]);

  return (
    <div className="flex items-center justify-between w-full   mt-5  mb-7  !pl-4 !pr-4">
      <Input
        disableUnderline
        autoFocus
        value={value}
        placeholder={placeHolder}
        fullWidth
        inputProps={{
          maxLength: 80,
        }}
        onChange={handleChangeTitle()}
        classes={{
          input:
            "!text-on-primary !text-lg !font-bold !leading-6 !font-['Roboto'] placeholder:opacity-60",
        }}
        className="flex-1 h-full flex-col-center"
      />
      <CircularProgress
        variant="determinate"
        className={`hidden sm:block !w-[20px] !h-[20px] ml-6 ${getProgressColor()}`}
        value={calculatePercent()}
      />
      <div className="hidden font-normal caption text-on-secondary ml-1.5 sm:block">
        <span className={`${getNumberOfCharColor()}`}>{numberOfChar}</span>/
        {MAXIMUM_CHAR}
      </div>
    </div>
  );
};

export default MakePostTitleEditor;
