// @flow
import * as React from 'react';
import Avatar from '@shared/components/avatar/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import useAppDispatch from '@data/useAppDispatch';
import { fetchFriendsBySearch } from '@data/my-circle/MyCircle.thunk';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Size } from '../../../../../models/constants';

type MessageListHeaderProps = {
  user?: {
    name: string;
    avatar: string;
    isOnline: boolean;
  };
  setSelectedConversation: (id: number | null) => void;
  showMobileChatView?: boolean;
};

const MessageListHeader: React.FC<MessageListHeaderProps> = (props) => {
  const { user, setSelectedConversation, showMobileChatView } = props;
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<
    {
      name: string;
      avatar: string;
      id: string;
    }[]
  >([]);

  const dispatch = useAppDispatch();

  const searchFriends = React.useCallback(
    (value: string) => {
      if (!value) return;
      setLoading(true);

      dispatch(
        fetchFriendsBySearch({
          search: value,
        }),
      )
        .then((res) => {
          setOptions(
            res.users.map((user) => {
              return {
                name: user.profile.nick_name,
                avatar: user.profile.img_url,
                id: user.id,
              };
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch],
  );

  if (user)
    return (
      <div className="flex items-center justify-start w-full pl-5 h-[40px]">
        {showMobileChatView && (
          <IconButton
            onClick={() => {
              setSelectedConversation(null);
            }}
            className="flex flex-col items-center justify-start pt-4 pl-4 pr-4 rounded-none"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <div className="flex items-center justify-start flex-1">
          <Avatar
            src={user.avatar}
            withOnlineSign
            online={user.isOnline}
            className="w-[30px] h-[30px]"
            size={Size.CUSTOM}
          />
          <span className="ml-4 text-on-primary h6">{user.name}</span>
        </div>
      </div>
    );

  return (
    <div className="flex w-full h-10 mt-3 ml-4 border-b subtitle2 text-on-secondary">
      {showMobileChatView && (
        <IconButton
          onClick={() => {
            setSelectedConversation(null);
          }}
          className="flex items-center justify-start w-full pt-4 pl-4 pr-4 rounded-none"
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      <p className="mt-2.5"> To:</p>{' '}
      <Autocomplete
        options={options}
        sx={{
          width: 300,
          height: '100%',
        }}
        onInputChange={(_, newInputValue) => {
          searchFriends(newInputValue);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => {
          return (
            <IconButton
              onClick={() => {
                if (option && option.id)
                  setSelectedConversation(Number(option.id));
              }}
              className="flex items-center justify-start w-full pt-4 pl-4 pr-4 rounded-none"
            >
              <Avatar
                src={option.avatar}
                className="w-9 h-9"
                size={Size.CUSTOM}
              />
              <p className="ml-2 subtitle2 text-on-primary">{option.name}</p>
            </IconButton>
          );
        }}
        loading={loading}
        renderInput={(params) => (
          <TextField
            id={params.id}
            InputLabelProps={params.InputLabelProps}
            size="small"
            fullWidth
            placeholder="Type in the name of the person"
            inputProps={params.inputProps}
            className="-ml-2.5"
            InputProps={{
              ref: params.InputProps.ref,
              // endAdornment: <React.Fragment>
              //     {loading ? <CircularProgress color="inherit" size={20}/> : null}
              //     {params.InputProps.endAdornment}
              // </React.Fragment>,
              // disableUnderline: true,
              autoFocus: true,
              fullWidth: true,
              classes: {
                input:
                  "!text-sm !font-normal !leading-5 !font-['Roboto'] h-full",
                root: ' justify-start !items-start ',
                notchedOutline: 'hidden',
              },
              className: 'flex justify-start flex-1 w-full !items-start',
            }}
          />
        )}
      />
    </div>
  );
};
export default MessageListHeader;
