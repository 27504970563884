import React from 'react';
import Background from '@assets/images/login_bg.svg';

const WithBanner: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <div className="flex flex-row w-full h-screen m-0">
      <div className="h-full mx-auto sm:container">{children}</div>
      <img src={Background} className="hidden h-full xl:block" alt="" />
    </div>
  );
};

export default WithBanner;
