import * as React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useAppDispatch from '@data/useAppDispatch';
import { sendMessage } from '@data/chat/Chat.thunk';
import { Message } from '@data/chat/Chat.model';
import { AppSelector } from '@data/app/app.selector';

const useSendMessage = (peer: number) => {
  const dispatch = useAppDispatch();
  const [pendingMessages, setPendingMessages] = React.useState<{
    [id: number]: Message[];
  }>([]);
  const userId = useSelector(AppSelector.selectUserId);

  const onSendMessage = (message: string) => {
    const id = Math.random();

    if (message.length > 0) {
      setPendingMessages({
        ...pendingMessages,
        [peer]: [
          {
            id,
            message,
            userId,
            time: moment().toISOString(),
            pending: true,
          },
          ...(pendingMessages[peer] || []),
        ],
      });

      dispatch(sendMessage({ message, peer })).then(() => {
        setPendingMessages({
          ...pendingMessages,
          [peer]: [],
        });
      });
    }
  };

  return { onSendMessage, pendingMessages: pendingMessages[peer] || [] };
};

export default useSendMessage;
