import { useSelector } from 'react-redux';
import { registerNotificationToken } from '@data/app/app.thunk';
import useAppDispatch from '@data/useAppDispatch';
import { AppSelector } from '@data/app/app.selector';
import { isSupported as isFirebaseMessagingSupported } from 'firebase/messaging';
import React from 'react';

const useRegisterNotificationService = () => {
  const dispatch = useAppDispatch();
  const notificationToken = useSelector(AppSelector.selectNotificationToken);
  const [isSupported, setIsSupported] = React.useState(false);

  React.useEffect(() => {
    isFirebaseMessagingSupported().then((isSupported) => {
      setIsSupported(isSupported);
    });
  });

  const getNotification = (notification: Notification, location: Location) => {
    console.log('notification', notification, location);
  };

  const registerNotificationService = (notificationToken: string) => {
    dispatch(registerNotificationToken({ notificationToken }));
  };

  return {
    registerNotificationService,
    isRegistered: Boolean(notificationToken),
    getNotification,
    isSupported,
  };
};

export default useRegisterNotificationService;
