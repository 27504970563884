import * as React from 'react';
import ContentItem from '@view/content-management-system/components/ContentItem';
import { ReactComponent as EmptyList } from '@assets/images/empty_list.svg';
import { Content } from '@data/contents/Contents.model';
import { LoadingState } from '../../../models/Loading';

const ContentListView: React.FC<{
  list: Content[];
  loading: LoadingState;
}> = ({ list, loading }) => {
  const renderContentList = () => {
    if (loading === LoadingState.IDLE || loading === LoadingState.LOADING)
      return (
        <>
          {[...Array(10)].map((_, index) => (
            <ContentItem key={`loading-content-${index}`} loading />
          ))}
        </>
      );
    if (list.length === 0)
      return (
        <div className="flex flex-col items-center w-full h-full pt-20 text-on-secondary h2">
          <EmptyList />
          <p className="mt-4">No content found</p>
        </div>
      );
    return (
      <>
        {list.map((item) => {
          return <ContentItem key={item.id} content={item} />;
        })}
      </>
    );
  };

  return (
    <div className="flex-1 w-full overflow-auto">{renderContentList()}</div>
  );
};

export default ContentListView;
