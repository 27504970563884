import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@lib/configureStore';
import { socialEventAdapter } from './socialEvents.reducer';

export const SocialEventsSelector = {
  selectById: createSelector(
    [(state: RootState) => state.socialEvents, (_: void, id: string) => id],
    (state: RootState, id) => {
      return (
        socialEventAdapter.recommendedEvents
          .getSelectors()
          .selectById(state.recommendedEvents, id) ||
        socialEventAdapter.myEvents
          .getSelectors()
          .selectById(state.myEvents, id)
      );
    },
  ),
  myEvents: socialEventAdapter.myEvents.getSelectors(
    (state: any) => state.socialEvents.myEvents,
  ),
  recommendedEvents: socialEventAdapter.recommendedEvents.getSelectors(
    (state: any) => state.socialEvents.recommendedEvents,
  ),
};
