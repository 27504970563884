import React from 'react';
import { Drawer } from '@mui/material';
import useBreakpoint from '@hooks/index';
import MenuDrawerHeader from './header/MenuDrawerHeader';
import MenuDrawerUserProfile from './user-profile/MenuDrawerUserProfile';
import MobileMenuLinks from '../menu-links/MobileMenuLinks';
import MobileMenuFooter from './footer/MobileMenuFooter';

type props = {
  isOpen: boolean;
  onClose: () => void;
};

const MenuDrawer: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<props>>
> = ({ isOpen, onClose }) => {
  useBreakpoint();

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose} className="z-1400">
      <div className="flex flex-col items-start justify-start h-full w-72">
        <MenuDrawerHeader />
        <div className="w-full overflow-auto h-mobile-drawer">
          <MenuDrawerUserProfile />
          <MobileMenuLinks />
        </div>
        <MobileMenuFooter />
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
