import React from 'react';

const useOutsideAlerter: (
  ref: React.MutableRefObject<any>,
  onClick: () => void,
) => void = (ref, onClick) => {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && event.target === ref.current) {
        onClick();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClick, ref]);
};

export default useOutsideAlerter;
