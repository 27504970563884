import React from 'react';

// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import Endpoints from '@view/routes/endpoints';
import Avatar from '../../../../../components/avatar';
import HDivider from '../../../../../components/divider/HDivider';

const MenuDrawerUserProfile: React.FC<unknown> = () => {
  const navigate = useNavigate();
  const profile = useSelector(AppSelector.selectUserInfo);

  return (
    <div
      className="flex flex-col justify-center m-6 w-60"
      role="button"
      tabIndex={0}
      onClick={(): void => navigate(Endpoints.PROFILE)}
      onKeyDown={(): void => navigate(Endpoints.PROFILE)}
    >
      <div className="flex flex-row items-center">
        <Avatar src={profile?.avatar} />
        <div>
          <p className="ml-3.5 h3 text-primary">{profile?.nickName}</p>
          <p className="ml-3.5 subtitle2 text-primary">See my profile</p>
        </div>
      </div>
      <HDivider className="mt-3.5" />
    </div>
  );
};

export default MenuDrawerUserProfile;
