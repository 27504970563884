import { Checkbox } from '@mui/material';
import { TextKeys } from '@lib/i18n/configureI18n';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  EventDraftAction,
  EventDraftContext,
  EventDraftValidationAction,
} from '@view/create-event/hooks/useDraftEvent';

const CreateEventTermsAndAgreement: React.FC<{
  validate: (value: boolean) => void;
}> = ({ validate }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = React.useState(false);

  const draftContext = React.useContext(EventDraftContext) as EventDraftAction &
    EventDraftValidationAction;

  React.useEffect(() => {
    draftContext.setIsCommunityGuidelinesChecked(isChecked);
    validate(
      draftContext.validateAgreement({
        isCommunityGuidelinesChecked: isChecked,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftContext, isChecked]);

  return (
    <div className="w-full pl-5 mt-4 body1">
      <Checkbox value={isChecked} onChange={() => setIsChecked(!isChecked)} />
      {t(TextKeys.IAgreeToThe)}{' '}
      <a
        className="underline text-[#1861D3] underline-offset-8"
        href={t(TextKeys.CommunityGuidelinesLink)}
      >
        {' '}
        {t(TextKeys.CommunityGuidelines)}
      </a>
    </div>
  );
};

export default CreateEventTermsAndAgreement;
