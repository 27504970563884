import React from 'react';
import PublicIcon from '@mui/icons-material/Public';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { ReactComponent as ShareCircleSvg } from '@assets/icons/share_circle.svg';
import { FeedSharingOption, FeedTypes } from '../../../../../models/Feed';

export enum DraftStatus {
  START = 'start',
  INITIATED = 'initiated',
  SUBMITTING = 'submitting',
  DRAFTING = 'drafting',
  SETTING_PUBLISH_TIME = 'setting publish time',
  SCHEDULING = 'scheduling',
  DONE = 'done',
}

export type MakePostDefaultValues = {
  title: string;
  description: string;
  category: FeedTypes;
};

export type DraftProviderValueType = {
  setTitle: (_?: string) => void;
  setSelectedSharingOption: (_: string) => void;
  setType: (_: string) => void;
  setBody: (_: string) => void;
  setAttachments: (_: { file: File; content: string }[]) => void;
  setScheduledTime: (_: Date) => void;
  setOptions: (_: string[]) => void;
  setEndTime: (_: { day: number; hour: number; minute: number }) => void;
  reset: () => void;
};

export type MakePostDraft = {
  providerValue: DraftProviderValueType;
  // header: MakePostHeaderProps;
  // editor: MakePostEditorType;
  // actions: MakePostAction;
  status?: DraftStatus;
  // reset: () => void;
  // defaultValues: MakePostDefaultValues;
  // cancelScheduling: () => () => () => void;
};

export const makePostSharingOptions = {
  unassigned: {
    [FeedSharingOption.PUBLIC]: {
      label: 'Community',
      key: FeedSharingOption.PUBLIC,
      Icon: <PublicIcon className="flex-1" />,
      description: 'Anyone on this community',
    },
    [FeedSharingOption.CIRCLE]: {
      label: 'My circle',
      key: FeedSharingOption.CIRCLE,
      Icon: <ShareCircleSvg className="flex-1 h-5 fill-on-secondary" />,
      description: 'Your friends',
    },
    [FeedSharingOption.PRIVATE]: {
      label: 'Select friends',
      key: FeedSharingOption.PRIVATE,
      Icon: <PeopleAltRoundedIcon className="flex-1 h-5 fill-on-secondary" />,
      description: 'Select specific friends ',
    },
    [FeedSharingOption.SELF]: {
      label: 'Only me',
      key: FeedSharingOption.SELF,
      Icon: <PersonIcon className="flex-1  fill-on-secondary" />,
    },
  },
};
