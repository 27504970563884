export enum NotificationType {
  'sgrReviewReminder' = 'sgr-review-reminder', // 'Review Group Request Reminder'
  'sgrReview' = 'sgr-review', // 'Review Group Request'
  'share' = 'share', // 'Share'
  'newSeriesAvailable' = 'new-series-available', // 'New Series Available'
  'eventAttended' = 'event-attended', // 'Event Attended'
  'eventReminder2' = 'event-reminder-2', // 'Event Reminder in 30 minutes'
  'eventReminder1' = 'event-reminder-1', // 'Event Reminder now'
  'pollEnded' = 'poll-ended', // 'Poll Ended'
  'mentionedInPost' = 'mentioned-in-post', // 'Mentioned in Post'
  'like' = 'like', // 'Liked your Post'
  'resharedItemSeries' = 'reshared_item_series', // 'Reshared Item Series'
  'makeFriend' = 'make-friend', // 'Make Friend'
  'reshare' = 'reshare', // 'Reshare'
  'newArticleAvailable' = 'new-article-available', // 'New Article Available'
  'comment' = 'comment', // 'Commented on your Post'
  'suspendGroup' = 'suspend-group', // 'Suspend Group'
  'deleteGroup' = 'delete-group', // 'Delete Group'
  'pvReview' = 'pv-review', // 'Review Adverse Event
  'pvReviewReminder' = 'pv-review-reminder', // 'Review Adverse Event Reminder'
}

export interface Notification {
  id: number;
  createdAt: number;
  message: string;
  type: NotificationType;
  user: {
    id: number;
    name: string;
    avatar: string;
  };
  isUnread: boolean;
  referenceIds?: string[];
  asset?: { id: string }[];
}
