// @flow
import * as React from 'react';
import Button from '@mui/material/Button';
import { Content } from '@data/contents/Contents.model';
import Skeleton from '@mui/material/Skeleton';
import UseConfirmModal from '@hooks/confirm-modal-hook/UseConfirmModal';
import { IconButton } from '@mui/material';
import useIsMobile from '@hooks/use-breakpoint/useIsMobile';
import useContent from '../hooks/useContent';

type ContentItemProps = {
  content?: Content;
  loading?: boolean;
};

const ContentItem: React.FC<ContentItemProps> = ({ content, loading }) => {
  const { title, createdAt, contentType, onContentClick, action } =
    useContent(content);

  const isMobile = useIsMobile();

  const [ConfirmationModal, showModal, hideModal] = UseConfirmModal({
    approveButton: {
      label: action.label,
      action: () => {
        action.onClick().then(() => hideModal());
      },
    },
    description: `Are you sure you want to ${action.label.toLowerCase()} this content?`,
    hasLoading: true,
    rejectButton: {
      label: 'Cancel',
    },
    title: `${action.label.toLowerCase()} content?`,
  });

  const renderItemType = React.useCallback(() => {
    if (!contentType) return null;
    return (
      <div className="flex items-center justify-center w-[8%]">
        {contentType.icon('w-[14px] h-[14px]')}
        <span className="ml-2 uppercase caption text-on-primary">
          {contentType.label}
        </span>
      </div>
    );
  }, [contentType]);

  if (loading)
    return (
      <Skeleton
        variant="rectangular"
        className="mb-[6px]"
        width="100%"
        height={60}
      />
    );

  return (
    <div className="relative">
      <ConfirmationModal />
      <IconButton
        className={`flex justify-center w-full pt-4 pb-4 pl-5 pr-6 bg-white rounded-none h-[60px] mb-[6px]
        ${isMobile ? 'flex-col items-stretch' : 'flex-row  items-center'}
        `}
        onClick={onContentClick()}
      >
        {!isMobile && renderItemType()}
        <p
          className={`text-left text-black caption-medium ${
            isMobile ? 'w-[90%] text-left text-ellipsis' : 'w-[57%]'
          } pl-[2%]`}
        >
          {isMobile ? title?.substring(0, 40) : title}
        </p>
        <p
          className={`
        italic w-[10%] caption ml-[2%]
        ${isMobile ? 'w-full text-left' : 'w-[10%]'}
        `}
        >
          {createdAt}
        </p>
        <div className="h-6 text-white  w-[15%] max-w-[136px] ml-[3%]" />
      </IconButton>
      <Button
        className="absolute h-6 text-white top-[33%] z-[1020] bg-error w-fit sm:w-[15%] max-w-[200px] sm:max-w-[136px] right-10"
        color="error"
        variant={!isMobile ? 'contained' : 'outlined'}
        onClick={(e) => {
          showModal();
        }}
      >
        {action.label}
      </Button>
    </div>
  );
};

ContentItem.defaultProps = {
  content: undefined,
  loading: false,
};
export default ContentItem;
