// @flow
import * as React from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { ReactComponent as GroupBadgeSvg } from '@assets/icons/group_requestor_icon.svg';
import { ReactComponent as VerifiedSvg } from '@assets/icons/verified.svg';
import { ReactComponent as RejectedSvg } from '@assets/icons/rejected.svg';
import { ReactComponent as StarSvg } from '@assets/icons/star.svg';
import { RequestedSupportGroup } from '@data/support-groups/supportGroups.model';
import Members from './components/Members';

type ReviewedGroupCardProps = {
  request: RequestedSupportGroup;
};

const ReviewedGroupCard: React.FC<ReviewedGroupCardProps> = (props) => {
  const { request } = props;
  const { group } = request;

  const { t } = useTranslation();

  const renderInfo = () => {
    const iconClass = 'w-5 h-5 mr-2 ml-2 mt-0.5 mb-0.5';
    const containerClass = 'min-h-4 flex items-center ';
    const textContainerClass = 'min-h-4 flex items-center flex-wrap';
    const textClass = 'text-brand-primary ml-1';
    const numberOfAdmins = group.admins?.length ?? 0;

    return (
      <div className="flex pl-5 pr-4 ">
        <img
          className="object-cover w-[112px] h-[112px] rounded-[6px]"
          src={group.info.coverImage}
          alt=""
        />
        <div className="flex flex-col justify-center w-full h-[112px] body2 text-on-secondary">
          <div className={containerClass}>
            <GroupBadgeSvg className={iconClass} />
            <div className={textContainerClass}>
              Requested on{' '}
              {moment(request.request.requestAt).format('DD/MM/YYYY')} by{' '}
              <span className={textClass}>
                {' '}
                {request.request.requester.nickname}
              </span>
            </div>
          </div>
          <div className={containerClass}>
            {request.isApproved ? (
              <VerifiedSvg className={iconClass} />
            ) : (
              <RejectedSvg className={iconClass} />
            )}
            <div className={textContainerClass}>
              {request.isApproved ? 'Approved' : 'Rejected'} on{' '}
              {moment(request.review.reviewedAt).format('DD/MM/YYYY')} by{' '}
              <span className={textClass}>
                {' '}
                {request.review.reviewer.nickname}
              </span>
            </div>
          </div>
          {request.isApproved && (
            <div className={containerClass}>
              <StarSvg className={iconClass} />
              <div className={textContainerClass}>
                Administrator{numberOfAdmins > 1 ? 's' : ''}:{' '}
                {group.admins?.map((admin, i) => (
                  <>
                    <span className={textClass}>{admin.nickname}</span>
                    {i === numberOfAdmins - 1 ? '' : ','}
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderReason = () => {
    if (request.isApproved) return null;
    return (
      <>
        <div className="w-full h-[1px] bg-secondary-background" />
        <div className="w-full pt-5 pl-5 pr-6">
          <span className="caption text-on-secondary ">
            Why was this group rejected?
          </span>
          <div className="mt-5 body1 text-on-primary">
            {request.review.message}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="relative w-full pt-5 pb-5 mt-4 bg-white border min-h-[330px] rounded-md border-secondary-background">
      {renderInfo()}
      <div className="relative w-full pl-5 pr-4">
        <div className="mt-[22px] h3 w-[16rem]">{group.info.title}</div>
        {request.isApproved && (
          <Members
            numberOfMembers={group?.members.numberOfMembers || 0}
            className="border top-0.5 right-5 border-secondary-background"
          />
        )}
      </div>
      <div className="pl-5 pr-4 mt-4 body1 min-h-[100px]">
        {group.info.description}
      </div>
      {renderReason()}
    </div>
  );
};

export default ReviewedGroupCard;
