import { SocialEvent } from './socialEvents.model';

export const convertSocialEventResponse = (socialEvent: any): SocialEvent => ({
  id: socialEvent.id,
  info: {
    title: socialEvent.title,
    description: socialEvent.description,
    isActive: socialEvent.isActive,
    cover: socialEvent.img_url,
    location: socialEvent.location_url,
    numberOfParticipants: socialEvent.subscriber_count,
  },
  admin: socialEvent.admin,
  time: {
    startTime: socialEvent.start_time,
    endTime: socialEvent.end_time,
  },
  userIsJoined: socialEvent.is_member,
  adminId: socialEvent.user_id,
});
export const convertSocialEventsResponse = (
  response: any[] = [],
): SocialEvent[] =>
  response.map((socialEvent) => convertSocialEventResponse(socialEvent));

export const convertSocialEventParticipantsResponse = (
  response: any[] = [],
) => {
  return response.map((participant) => ({
    id: participant.user_id,
    nickname: participant.nick_name,
    avatar: participant.img_url,
    isAdmin: participant.role === 'admin',
    isFriend: participant.relation === 'friend',
    isOnline: participant.online_status === 'Active',
  }));
};
