/**
 * Configure Application
 *
 * @param configInitiator
 */
const configureApp = (...configInitiator) =>
  configInitiator.reduce((acc, method) => {
    return {
      ...acc,
      ...(method(acc) || {}),
    };
  }, {});

export default configureApp;
