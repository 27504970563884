// @flow
import * as React from 'react';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import Avatar from '@shared/components/avatar';
import { Card } from '@mui/material';
import { ReactComponent as KarmaSvg } from '@assets/icons/karma.svg';
import HDivider from '@shared/components/divider/HDivider';
import { useParams } from 'react-router-dom';
import Timeline from '@shared/components/timeline/Timeline';
import { fetchUserPosts } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import { TimeLines } from '@data/timeline/timeline.model';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import { AsyncThunk } from '@reduxjs/toolkit';
import useProfile from './hooks/useProfile';
import { Size } from '../../models/constants';
import KarmaDialog from './components/KarmaDialog';

const ProfileView: React.FC = () => {
  const { userId } = useParams();
  const currentUser = useSelector(AppSelector.selectUserId);
  const { nickname, country, ageRange, avatar, karmaPoint, info } = useProfile(
    userId || currentUser,
  );
  const [karmaDialogOpen, setKarmaDialogOpen] = React.useState(false);

  const renderHeader = () => {
    return (
      <div className="w-full min-h-[180px] bg-gradient-to-b from-[#007A8B] to-[#70D0C6] flex-col-center">
        <div className="w-full max-w-[820px]">
          <div className="relative w-[300px] h-[140px] bg-white/70  rounded-md  ml-[70px]">
            <Avatar
              src={avatar}
              size={Size.CUSTOM}
              className="absolute !w-[140px] !h-[140px] -left-[70px]"
            />
            <div className="absolute flex flex-col justify-around w-full h-full left-[80px]">
              <h3 className="h3 text-on-primary">{nickname}</h3>
              {country && (
                <p className="subtitle1 text-on-primary">{country}</p>
              )}
              {ageRange && (
                <p className="subtitle1 text-on-primary">{ageRange}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      info &&
      Object.keys(info).map((key, index) => {
        return (
          <div key={`info-${key}`} className="m-5">
            <p className="mb-5 caption text-on-secondary">{key}</p>
            <p className="body1 text-on-primary">{info[key]}</p>
          </div>
        );
      })
    );
  };

  const renderAbout = () => {
    return (
      <div className="w-[365px]">
        <Card className="relative flex items-center w-full h-12 pl-5 mt-6 mb-5">
          <KarmaSvg className="mr-3 w-[30px] h-[30px]" />
          <h5 className="h5 text-on-secondary">{karmaPoint} Karma Score</h5>
          <p
            className="absolute cursor-pointer subtitle2 text-on-secondary top-[30%] right-3"
            onClick={() => setKarmaDialogOpen(true)}
          >
            Learn more
          </p>
        </Card>
        <Card className="w-full h-auto pb-4 max-h-[700px]">
          <h6 className="mt-3 mb-3 ml-5 h6 text-on-primary">About me</h6>
          <HDivider />
          {renderInfo()}
        </Card>
      </div>
    );
  };

  const renderActivity = () => {
    return (
      <div className="w-full h-full mt-5 ml-4 overflow-auto w-[455px] ">
        <Timeline
          timelineFetcher={
            fetchUserPosts as unknown as AsyncThunk<
              never,
              { page: number },
              never
            >
          }
          selector={TimelineSelectors.users.selectIds}
          hasMoreSelector={TimelineSelectors.users.hasMoreSelector}
          pageSelector={TimelineSelectors.users.pageSelector}
          timeline={TimeLines.GROUP_TIMELINE}
          fetcherProps={{ userId: userId || currentUser }}
        />
      </div>
    );
  };

  return (
    <WithSideBar>
      <KarmaDialog
        open={karmaDialogOpen}
        onClose={() => setKarmaDialogOpen(false)}
      />
      <div className="flex flex-col w-full h-full">
        {renderHeader()}
        <div className=" w-full h-full flex-col-center">
          <div className="flex w-full h-full max-w-[820px]">
            {renderAbout()}
            {renderActivity()}
          </div>
        </div>
      </div>
    </WithSideBar>
  );
};
export default ProfileView;
