import { Dimension } from '@hooks/use-breakpoint/resize';
import breakpoints, { BreakpointName } from '../models/constants/breakpoints';

export const isExtraSmallBreakpoint = (breakpoint: BreakpointName): boolean =>
  breakpoint === breakpoints.name.XSMALL;

export const isMobileBreakpoint = (breakpoint: BreakpointName): boolean =>
  [breakpoints.name.XSMALL, breakpoints.name.SMALL].includes(breakpoint);

export const isTabletOrBelow = (breakpoint: BreakpointName): boolean =>
  [
    breakpoints.name.XSMALL,
    breakpoints.name.SMALL,
    breakpoints.name.MEDIUM,
    breakpoints.name.LARGE,
  ].includes(breakpoint);

export const isTabletBreakpoint = (breakpoint: BreakpointName): boolean =>
  [breakpoints.name.MEDIUM].includes(breakpoint);

export const isLargeBreakpoint = (breakpoint: BreakpointName): boolean =>
  [breakpoints.name.LARGE].includes(breakpoint);

export const isExtraLargeBreakpoint = (breakpoint: BreakpointName): boolean =>
  [
    breakpoints.name.XLARGE,
    breakpoints.name.XXLARGE,
    breakpoints.name.MAX,
  ].includes(breakpoint);

export const isDesktopBreakpoint = (breakpoint: BreakpointName): boolean =>
  [
    breakpoints.name.LARGE,
    breakpoints.name.XLARGE,
    breakpoints.name.XXLARGE,
    breakpoints.name.MAX,
  ].includes(breakpoint);

export const isTabletOrLarge = (breakpoint: BreakpointName): boolean =>
  [breakpoints.name.MEDIUM, breakpoints.name.LARGE].includes(breakpoint);

export const isSmallOrGreater = (breakpoint: BreakpointName): boolean =>
  [
    breakpoints.name.XLARGE,
    breakpoints.name.XXLARGE,
    breakpoints.name.MAX,
  ].includes(breakpoint);

export const isExtraLargeOrGreater = (breakpoint: BreakpointName): boolean =>
  [
    breakpoints.name.XLARGE,
    breakpoints.name.XXLARGE,
    breakpoints.name.MAX,
  ].includes(breakpoint);

export const isDoubleExtraLargeOrGreater = (
  breakpoint: BreakpointName,
): boolean =>
  [breakpoints.name.XXLARGE, breakpoints.name.MAX].includes(breakpoint);

export const isMaxBreakpoint = (breakpoint: BreakpointName): boolean =>
  [breakpoints.name.MAX].includes(breakpoint);

export const isKeyboardOpen = (dimensions: Dimension): boolean =>
  dimensions.height < 400;
