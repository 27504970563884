import { TextKeys } from '@lib/i18n/configureI18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SupportGroupGuidelines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div
      key="groups-guidelines"
      className="hidden bg-white sm:block w-[300px] h-fit rounded-[6px] ml-[34px] mt-[60px]"
    >
      <span className="flex items-center justify-start w-full ml-4 h-[40px] h6">
        {t(TextKeys.groupsGuidelines)}
      </span>
      <div className="w-full h-[1px] bg-secondary-background" />
      <div className="p-4 text-on-secondary body1">
        <p className="mb-4">{t(TextKeys.groupsGuidelinesText)}</p>
        <p>{t(TextKeys.groupsGuidelinesNote)}</p>
      </div>
    </div>
  );
};

export default SupportGroupGuidelines;
