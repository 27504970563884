import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import { Moment } from 'moment-timezone';
import SetTimeDialog from '@view/create-event/components/SetTimeDialog';
import {
  EventDraftAction,
  EventDraftContext,
  EventDraftValidationAction,
} from '@view/create-event/hooks/useDraftEvent';
import moment from 'moment';
import { EventDefaultValueContext } from '@view/create-event/hooks/useDefaultValues';

const EventDateAndTimePicker: React.FC<{
  validate: (value: boolean) => void;
}> = ({ validate }) => {
  const defaultValues = React.useContext(EventDefaultValueContext);

  const [isDatePickerOpen, setIsDatePickerOpen] =
    React.useState<boolean>(false);
  const [isStartTimeDialogOpen, setIsStartTimeDialogOpen] =
    React.useState<boolean>(false);
  const [isEndTimeDialogOpen, setIsEndTimeDialogOpen] =
    React.useState<boolean>(false);

  const [date, setDate] = React.useState<Date | undefined | null>(
    moment(defaultValues?.startTime).toDate(),
  );
  const [startTime, setStartTime] = React.useState<Moment>(
    defaultValues?.startTime,
  );
  const [endTime, setEndTime] = React.useState<Moment>(defaultValues?.endTime);

  const formatTime = (time: Moment, timeZone) =>
    `${time.format('HH:mm ')} ${timeZone.format('z')}`;

  const draftContext = React.useContext(EventDraftContext) as EventDraftAction &
    EventDraftValidationAction;

  React.useEffect(() => {
    if (date && startTime) {
      draftContext.setStartTime(
        moment(
          moment(date).format('YYYY-MM-DD') +
            ' ' +
            formatTime(startTime, startTime),
        ),
      );
      if (endTime) {
        draftContext.setEndTime(
          moment(
            moment(date).format('YYYY-MM-DD') +
              ' ' +
              formatTime(endTime, startTime),
          ),
        );
      }
    }
    validate(
      draftContext.validateTime({
        startTime,
        endTime,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, draftContext, endTime, startTime]);

  return (
    <div className="w-full pt-4 pb-4 pl-5 pr-5 flex-col-center bg-lighter-background">
      <SetTimeDialog
        open={isStartTimeDialogOpen}
        label="Set start time"
        setTime={setStartTime}
        onConfirm={() => setIsStartTimeDialogOpen(false)}
      />
      <SetTimeDialog
        open={isEndTimeDialogOpen}
        label="Set end time"
        setTime={setEndTime}
        onConfirm={() => setIsEndTimeDialogOpen(false)}
      />
      <p className="w-full mb-1 h5 text-[#4D565F]">Event Details</p>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          open={isDatePickerOpen}
          onAccept={() => setIsDatePickerOpen(false)}
          onChange={(date) => setDate(date)}
          value={date}
          className="w-full"
          inputFormat="MMMM D[,] YYYY"
          disablePast
          disableOpenPicker
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="filled"
                classes={{
                  root: ' w-full bg-white body1 mb-4 h-[38px]',
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    root: 'h-full bg-white !body1 !text-[#4D565F] ',
                    input: '!pl-4 !pr-4 !pt-0 !pb-0',
                  },
                }}
                onClick={() => setIsDatePickerOpen(true)}
                placeholder="Post publish date"
              />
            );
          }}
        />
      </LocalizationProvider>

      <div
        onClick={() => setIsStartTimeDialogOpen(true)}
        className="flex items-center w-full pl-4 pr-4 mb-4 bg-white cursor-pointer h-[38px] pt-[10px] pb-[10px] text-[#4D565F] body1"
      >
        {startTime ? formatTime(startTime, startTime) : 'Select Start Time'}
      </div>
      {!startTime ? null : (
        <div
          onClick={() => setIsEndTimeDialogOpen(true)}
          className="flex items-center w-full pl-4 pr-4 bg-white cursor-pointer h-[38px] pt-[10px] pb-[10px] text-[#4D565F] body1"
        >
          {endTime ? formatTime(endTime, startTime) : 'Select End Time'}
        </div>
      )}
    </div>
  );
};

export default EventDateAndTimePicker;
