import useBreakpoint from '@hooks/use-breakpoint/useBreakpoints';
import * as React from 'react';
import { isMobileBreakpoint } from '@utils/breakpoints.utils';

const useIsMobile = () => {
  const { breakpoint } = useBreakpoint();
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    setIsMobile(isMobileBreakpoint(breakpoint));
  }, [breakpoint]);

  return isMobile;
};

export default useIsMobile;
