import { createSelector } from '@reduxjs/toolkit';
import { timelineAdapter } from './timeline.reducer';

const TimelineSelectors = {
  published: {
    ...timelineAdapter?.published?.getSelectors(
      (state: any) => state.timeline.published,
    ),
    // selectPostWithoutCommentsById: createSelector([(state: any) => state.timeline-view.published, (_, id) => id], (state, id) => {
    //     const post = state.entities[id]
    //
    //     if (post)
    //         return {
    //             ...post,
    //             comments: undefined
    //         }
    //     return post
    // }),
    pageSelector: createSelector(
      (state) => state.timeline.published,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.published,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  drafted: {
    ...timelineAdapter?.drafted.getSelectors(
      (state: any) => state.timeline.drafted,
    ),
    pageSelector: createSelector(
      (state) => state.timeline.drafted,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.drafted,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  scheduled: {
    ...timelineAdapter?.scheduled.getSelectors(
      (state: any) => state.timeline.scheduled,
    ),
    pageSelector: createSelector(
      (state) => state.timeline.scheduled,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.scheduled,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  bookmarked: {
    ...timelineAdapter?.bookmarked.getSelectors(
      (state: any) => state.timeline.bookmarked,
    ),
    pageSelector: createSelector(
      (state) => state.timeline.bookmarked,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.bookmarked,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  adverseEvent: {
    ...timelineAdapter?.adverseEvent.getSelectors(
      (state: any) => state.timeline.adverseEvent,
    ),
    pageSelector: createSelector(
      (state) => state.timeline.adverseEvent,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.adverseEvent,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  flagged: {
    ...timelineAdapter?.flagged.getSelectors(
      (state: any) => state.timeline.flagged,
    ),
    pageSelector: createSelector(
      (state) => state.timeline.flagged,
      (state) => state.page,
    ),
    selectReviewedPostsIds: createSelector(
      (state) =>
        timelineAdapter.flagged
          .getSelectors()
          .selectAll(state.timeline.flagged),
      (posts) => {
        return posts
          .filter((post) => post.status.flagReviewedAt !== null)
          .map((post) => {
            return `f-${post.id}`;
          });
      },
    ),
    selectNotReviewedPostsIds: createSelector(
      (state) => {
        return timelineAdapter.flagged
          .getSelectors()
          .selectAll(state.timeline.flagged);
      },
      (posts) => {
        return posts
          .filter((post) => post.status.flagReviewedAt === null)
          .map((post) => {
            return `f-${post.id}`;
          });
      },
    ),
    totalFlaggedPosts: createSelector(
      (state) => state.timeline.flagged,
      (state) => state.total,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.flagged,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  AECases: {
    ...timelineAdapter?.AECases.getSelectors(
      (state: any) => state.timeline.AECases,
    ),
    selectIdsByDate: createSelector(
      [(state) => state.timeline.AECases, (_, date) => date],
      (state, date) => {
        return state.ids.filter((id) => {
          return id.includes(date);
        });
      },
    ),
    pageSelector: createSelector(
      (state) => state.timeline.AECases,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.AECases,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  groups: {
    ...timelineAdapter?.groups.getSelectors(
      (state: any) => state.timeline.groups,
    ),
    pageSelector: createSelector(
      (state) => state.timeline.groups,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.groups,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  users: {
    ...timelineAdapter?.users.getSelectors(
      (state: any) => state.timeline.users,
    ),
    pageSelector: createSelector(
      (state) => state.timeline.users,
      (state) => state.page,
    ),
    hasMoreSelector: createSelector(
      (state) => state.timeline.users,
      (state) => state?.ids?.length < state.total || state.total === -1,
    ),
  },
  selectPost: createSelector(
    [(state) => state.timeline, (_, postKey) => postKey],
    (state, postKey) => {
      return (
        timelineAdapter?.published
          .getSelectors()
          ?.selectById(state.published, postKey) ||
        timelineAdapter?.drafted
          .getSelectors()
          ?.selectById(state.drafted, postKey) ||
        timelineAdapter?.scheduled
          .getSelectors()
          ?.selectById(state.scheduled, postKey) ||
        timelineAdapter?.bookmarked
          .getSelectors()
          ?.selectById(state.bookmarked, postKey) ||
        timelineAdapter?.flagged
          .getSelectors()
          ?.selectById(state.flagged, postKey) ||
        timelineAdapter?.adverseEvent
          .getSelectors()
          ?.selectById(state.adverseEvent, postKey) ||
        timelineAdapter?.AECases.getSelectors()?.selectById(
          state.AECases,
          postKey,
        ) ||
        timelineAdapter?.groups
          .getSelectors()
          ?.selectById(state.groups, postKey) ||
        timelineAdapter?.users.getSelectors()?.selectById(state.users, postKey)
      );
    },
  ),
  selectAdapters: (prefix: string) => {
    return (
      {
        p: 'published',
        d: 'drafted',
        s: 'scheduled',
        b: 'bookmarked',
      }[prefix] || 'groups'
    );
  },
};

export default TimelineSelectors;
