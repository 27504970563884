import { AuthenticatedUser } from './app.modal';

export const convertUserResponse = (
  user,
  appInfo,
  state,
): AuthenticatedUser => ({
  ...state,
  email: user.email,
  auth: {
    ...state.auth,
    token: user.token,
    refreshToken: user.refresh_token,
    tokenExpiry: user.expires_at,
    authToken: user.authentication_token,
    deviceId: user.device_id,
    uuid: user.uuid,
  },
  user: {
    id: user.profile.id,
    age: {
      from: user.profile.age_from,
      to: user.profile.age_to,
    },
    country: user.profile.country,
    nickName: user.profile.nick_name,
    karma: user.profile.karma,
    avatar: user.profile.img_url,
    relation: user.profile.relation,
  },
  conditions: user.badges.map((badge) => ({
    id: badge.condition_id,
    title: badge.condition.display_name,
    badgeImage: badge.img_url,
    key: badge.condition.name,
  })),
  appInfo,
});
