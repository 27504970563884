// @flow
import * as React from 'react';
import { Card } from '@mui/material';
import { SocialEvent } from '@data/social-events/socialEvents.model';
import { Feed } from '@data/timeline/timeline.model';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import useSocialEvent from '../post/hooks/useSocialEvent';

type SocialEventDateAndTitleProps = {
  event: SocialEvent | Feed;
  loading?: boolean;
};

const SocialEventDateAndTitle: React.FC<SocialEventDateAndTitleProps> = ({
  event,
  loading,
}) => {
  const { time } = useSocialEvent(event);
  const navigate = useNavigate();

  const navigateToEvent = React.useCallback(
    () => () => {
      navigate(
        endpointIDReplacer(
          Endpoints.SOCIAL_EVENT,
          (event as Feed).event?.eventId || event.id,
        ),
      );
    },
    [navigate, event],
  );

  const renderDate = () => {
    if (loading)
      return <Skeleton variant="rectangular" width="100%" height="100%" />;
    return (
      <>
        <p className="caption text-brand-primary">{time.month}</p>
        <p className="text-black h3">{time.day}</p>
      </>
    );
  };

  const renderTime = () => {
    if (loading)
      return (
        <>
          <Skeleton variant="text" width={70} />
          <Skeleton variant="text" width={150} />
        </>
      );
    return (
      <>
        <p className="text-black h3">{event.info.title}</p>
        <p className="mt-1 text-black body1">
          {time.start} - {time.end} {time.timezone}
        </p>
      </>
    );
  };

  return (
    <div
      className="flex w-full pt-4 pl-5 cursor-pointer"
      onClick={navigateToEvent()}
    >
      <Card className="w-[60px] h-[60px] bg-[#D8EBF0] flex-col-center ">
        {renderDate()}
      </Card>
      <div className="flex flex-col ml-3">{renderTime()}</div>
    </div>
  );
};

export default SocialEventDateAndTitle;
