import { TextKeys } from '@lib/i18n/configureI18n';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import useSharingOptions from '@shared/components/make-post/hooks/useSharingOptions';
import useGroups from '@shared/components/make-post/hooks/useGroups';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';
import Menu from '@mui/material/Menu';
import useDropDown from '@shared/components/dropDown-menu/hooks/useDropDown';
import SelectFriends from '@shared/components/make-post/components/select-friends/SelectFriends';
import { FeedSharingOption } from '../../../../../../models/Feed';
import GroupedDropDownMenu from '../../../dropDown-menu/GroupedDropDownMenu';
import { GroupedDropDownItems } from '../../../dropDown-menu/models/DropDownItems';
import { Draft, DraftActions, DraftContext } from '../../hooks/useDraftActions';
import Avatar from '../../../avatar';

const MakePostHeader: React.FC<{
  channelId?: number;
}> = ({ channelId = 0 }) => {
  const user = useSelector(AppSelector.selectUserInfo);
  const userName = user?.nickName;
  const avatar = user?.avatar;

  const myGroups = useGroups();

  const { sharingOptions, getSharingOptionKey } = useSharingOptions(
    myGroups,
    channelId,
  );

  const { setSelectedSharingOption, setRecipients } = React.useContext(
    DraftContext,
  ) as DraftActions;

  const defaultValue = React.useContext(DefaultValueContext) as Draft;

  const [value, setValue] = React.useState(
    defaultValue.selectedSharingOption === ''
      ? 'unassigned-' + FeedSharingOption.PUBLIC
      : defaultValue.selectedSharingOption,
  );

  React.useEffect(() => {
    if (value) setSelectedSharingOption(value);
  }, [setSelectedSharingOption, value]);

  const { t } = useTranslation();

  // const [renderSelectFriends, setRenderSelectFriends] = React.useState(false);

  const { id, handleClose, handleOpen, open, anchorEl } = useDropDown();

  const [selectedFriends, setSelectedFriends] = React.useState<any[]>([]);

  React.useEffect(() => {
    setRecipients?.(selectedFriends.map((friend) => friend.id));
  }, [selectedFriends, setRecipients]);

  const handleSelectSharingOption = (option: string, anchorEl?: any) => {
    if (option === 'unassigned-' + FeedSharingOption.PRIVATE) {
      setValue(option);
      handleOpen()(anchorEl);
    } else {
      setValue(option);
      setSelectedFriends([]);
    }
  };

  const handleSelectFriends = (friends: any[]) => {
    setValue('unassigned-' + FeedSharingOption.PRIVATE);
    setSelectedFriends(friends);
    handleClose()();
  };

  return (
    <div className="flex pt-5 pb-4 pl-6  sm:mt-3">
      <Avatar src={avatar} />
      <div className="flex flex-col justify-center ml-5 align-startd">
        <span className="font-bold h6 mb-1.5">{userName}</span>
        {defaultValue.selectedSharingOption === '' ? (
          <>
            <div>
              <GroupedDropDownMenu
                disabled={channelId !== 0}
                title={t(TextKeys.WhoCanSeeThePost)}
                items={
                  sharingOptions as GroupedDropDownItems<FeedSharingOption>
                }
                selected={value}
                onSelect={handleSelectSharingOption}
              />
            </div>
            {selectedFriends.length > 0 && (
              <p className="mt-2 caption text-on-secondary max-w-[400px]">
                Shared with:{' '}
                {selectedFriends.map((friend) => friend.name).join(', ')}
              </p>
            )}
          </>
        ) : (
          <p className="caption text-error">
            This post is visible to {defaultValue.selectedSharingOption}
          </p>
        )}
      </div>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        className="mt-2"
        MenuListProps={{
          className: 'w-[333px] pl-[16px] max-h-[430px] mt-2',
          'aria-labelledby': 'basic-button',
        }}
      >
        <SelectFriends onSubmit={handleSelectFriends} />
      </Menu>
    </div>
  );
};

export default MakePostHeader;
