import CircleIcon from '@mui/icons-material/Circle';
import * as React from 'react';
import { SocialEventDetails } from '@view/home-page/components/event-view/hooks/useSocialEvent';

type EventInfoProps = {
  details: SocialEventDetails;
};

const EventInfo: React.FC<EventInfoProps> = ({ details }) => {
  const { title, start, end, cover, month, day, year } = details;

  return (
    <>
      <img src={cover} className="object-cover w-full h-[86px]" alt={title} />
      <div className="w-full pl-3 pr-2 mt-4 tags text-brand-primary">
        {month} {day}, {year}{' '}
        <CircleIcon className="h-[4px] w-[4px] mr-[1px] ml-[1px]" /> {start}-
        {end}
      </div>
      <div className="pl-3 pr-3 mt-1 h3 ellipsis2">{title}</div>
    </>
  );
};

export default EventInfo;
