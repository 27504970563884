import React from 'react';

const useDropDown = () => {
  const id = React.useId();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  const handleOpen = () => (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget) setAnchorEl(event.currentTarget);
    else setAnchorEl(event);
  };

  const handleClose = () => () => {
    setAnchorEl(null);
  };

  return { id, handleClose, handleOpen, open, anchorEl };
};

export default useDropDown;
