// @flow
import * as React from 'react';
import { IconButton } from '@mui/material';
import Avatar from '@shared/components/avatar';
import HDivider from '@shared/components/divider/HDivider';
import Size from '../../../../../models/constants/size';
import useConversation from '../hooks/useConversation';

type ConversationItemProps = {
  conversationId: string;
  selected?: boolean;
  onClick: () => void;
};

const ConversationItem: React.FC<ConversationItemProps> = ({
  conversationId,
  selected,
  onClick,
}) => {
  const { user, message, time, unreadCount } = useConversation(conversationId);

  const renderUnreadCount = () => {
    if (unreadCount === 0 || !unreadCount) return null;
    return (
      <div className="w-6 h-6 text-white rounded-full bg-brand-primary caption flex-col-center">
        {unreadCount}
      </div>
    );
  };

  return (
    <>
      <IconButton className="w-full rounded-none !p-0" onClick={onClick}>
        <div
          className={`flex w-full h-[100px] pt-5 pr-4 pl-4 ${
            selected && 'bg-lighter-background'
          }`}
        >
          <div className="mr-7">
            <Avatar
              className=" !w-[47px] !h-[47px]"
              size={Size.XSMALL}
              src={user.avatar}
              withOnlineSign
              online={user.isOnline}
            />
          </div>
          <div className="flex flex-col justify-start w-full h-full">
            <div className="flex justify-between w-full">
              <h6 className="h6 text-on-primary mb-[10px] ">{user.name}</h6>
              <p className="mb-3 caption text-on-secondary">{time}</p>
            </div>
            <div className="flex justify-between w-full">
              <p className="flex-1 text-left body2 text-on-secondary ellipsis2">
                {message}
              </p>
              {renderUnreadCount()}
            </div>
          </div>
        </div>
      </IconButton>
      <HDivider />
    </>
  );
};
export default ConversationItem;
