// @flow
import * as React from 'react';
import { Box } from '@mui/material';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';

type TabsProps = {
  tabs: string[];
  onTabChanged?: (index: number) => void;
};

const Tabs: React.FC<TabsProps> = (props) => {
  const { tabs, onTabChanged } = props;
  const id = React.useId();
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleOnTabClicked =
    () => (event: React.SyntheticEvent, newValue: number) => {
      setSelectedTab(newValue);
    };

  React.useEffect(() => {
    if (onTabChanged) {
      onTabChanged(selectedTab);
    }
  }, [onTabChanged, selectedTab]);

  return (
    <Box
      className="flex w-full bg-white h-[50px] rounded-tr-[20px] rounded-tl-[20px]"
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <MuiTabs
        value={selectedTab}
        className="w-full h-full"
        onChange={handleOnTabClicked()}
        centered
        classes={{
          indicator:
            '!bg-brand-primary !h-1 !rounded-tr-[20px] !rounded-tl-[20px]',
          flexContainer: '!justify-evenly',
        }}
      >
        {tabs.map((tab, index) => (
          <MuiTab
            label={tab}
            value={index}
            key={`${id}-${index}`}
            classes={{
              root: `w-[33%] sm:w-[160px] h-full flex-col-center !text-on-primary !mt-2 !capitalize
                                 ${
                                   selectedTab === index
                                     ? "!text-base !font-medium !leading-[22px] !font-['Roboto']"
                                     : "!text-[16px] !font-normal !leading-[22px] !font-normal !font-['Roboto']"
                                 }`,
            }}
          />
        ))}
      </MuiTabs>
    </Box>
  );
};
export default Tabs;

// < IconButton
// onClick = {handleOnTabClicked(index)}
// className = 'relative h-full w-[160px] flex-col-center !rounded-none' >
//     < span
// >
// {
//     tab
// }
// </span>
// {
//     selectedTab === index ?
//         <div
//             className='absolute bottom-0 w-full h-1 bg-brand-primary rounded-tr-[20px] rounded-tl-[20px]'/> : ''
// }
// </IconButton>
