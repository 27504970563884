import { createSelector } from '@reduxjs/toolkit';
import { MyCircleAdapter } from './MyCircle.reducer';

export const MyCircleSelector = {
  ...MyCircleAdapter.getSelectors((state: any) => state.myCircle),
  pageSelector: createSelector(
    (state) => state.myCircle,
    (state) => state.page,
  ),
  selectTotalFriends: createSelector(
    (state) => state.myCircle,
    (state) => state.total,
  ),
  hasMoreSelector: createSelector(
    (state) => state.myCircle,
    (state) => state?.ids?.length < state.total || state.total === -1,
  ),
  onlineFriendsSelector: createSelector(
    (state) => state.myCircle,
    (state) => state?.ids?.filter((id) => state?.entities[id].isOnline),
  ),
};
