import useSocialEventDetails, {
  useSocialEventDetailsReturnType,
} from '@view/social-event-details/hooks/useSocialEventDetails';
import moment from 'moment';
import React from 'react';

export type EventDefaultValues = {
  title: string;
  description: string;
  startTime?: moment.Moment;
  endTime?: moment.Moment;
  location: string;
  cover: string;
  // sharingWith: string[]; @TODO: add this
};

const initialValue: EventDefaultValues = {
  title: '',
  description: '',
  startTime: undefined,
  endTime: undefined,
  location: '',
  cover: '',
};

export const EventDefaultValueContext =
  React.createContext<EventDefaultValues>(initialValue);

const useDefaultValues = (eventId?: string) => {
  const draftDetails: useSocialEventDetailsReturnType =
    useSocialEventDetails(eventId);

  const getDefaultValues = React.useCallback((draftDetails) => {
    if (draftDetails) {
      const { title, description, cover, location, time } = draftDetails;
      return {
        title,
        description,
        cover,
        location,
        startTime: moment(time.startTime),
        endTime: moment(time.endTime),
      };
    }
    return initialValue;
  }, []);

  return React.useMemo(
    () => getDefaultValues(draftDetails),
    [draftDetails, getDefaultValues],
  );
};

export default useDefaultValues;
