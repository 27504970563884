// @flow
import * as React from 'react';

import { TextKeys } from '@lib/i18n/configureI18n';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { EntityId } from '@reduxjs/toolkit';
import useNotification from './hooks/useNotification';
import Avatar from '../avatar';

const Notification: React.FC<{ notificationId: EntityId }> = ({
  notificationId,
}) => {
  const { t } = useTranslation();

  const { avatar, message, time, isUnread, onClick } =
    useNotification(notificationId);

  const containerClass = `flex w-full pt-5 pb-4 pr-[70px] pl-5 rounded-none ${
    isUnread && 'bg-accent'
  } relative justify-start items-start`;

  return (
    <IconButton onClick={onClick} className={containerClass}>
      {isUnread && (
        <div className="absolute left-0 w-1 h-7 bg-brand-primary rounded-tr-3xl rounded-br-3xl top-[38px]" />
      )}
      <Avatar src={avatar} />
      <div className="flex flex-col flex-1 text-left ml-[30px]">
        <p className="subtitle2 text-on-primary">{message}</p>
        <p className="mt-1 bottom-nav text-on-secondary">{time}</p>
      </div>
      {isUnread && (
        <p className="absolute h6 text-alternative1 right-5 top-4">
          {t(TextKeys.New)}
        </p>
      )}
    </IconButton>
  );
};

export default Notification;
