import IconButton from '@mui/material/IconButton';
import LikedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import DislikedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  markContentAsHelpful,
  markContentAsNotHelpful,
} from '@data/contents/Contents.thunk';

const ContentFeedback: React.FC<{
  contentId: string;
  contentMark?: 'helpful' | 'unhelpful';
}> = ({ contentId, contentMark }) => {
  const dispatch = useDispatch();

  const buttonList = React.useMemo(
    () => [
      {
        Icon: <LikedIcon />,
        title: 'Helpful',
        action: () => {
          dispatch(markContentAsHelpful(contentId));
        },
        marked: contentMark === 'helpful',
      },
      {
        Icon: <DislikedIcon />,
        title: 'Not Helpful',
        action: () => {
          dispatch(markContentAsNotHelpful(contentId));
        },
        marked: contentMark === 'unhelpful',
      },
    ],
    [contentId, contentMark, dispatch],
  );

  const renderFeedbackButton = React.useCallback(
    ({ Icon, title, action, marked }) => {
      return (
        <IconButton
          key={`feedback-${contentId}-${title}`}
          className={`flex items-center justify-center flex-1 h-full rounded-none ${
            marked ? 'text-brand-primary' : 'text-[#4D565F]'
          }`}
          onClick={!marked && action}
        >
          <span className="w-5 h-full  flex-col-center">{Icon}</span>
          <h6 className="h-full ml-2 h6 flex-col-center">{title}</h6>
        </IconButton>
      );
    },
    [contentId],
  );

  return (
    <div className="flex items-center justify-center w-full h-12 sticky bg-white bottom-0">
      {buttonList.map((button, _) => {
        return renderFeedbackButton(button);
      })}
    </div>
  );
};

export default ContentFeedback;
