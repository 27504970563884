import React from 'react';

import { ReactComponent as ExternalLinkSvg } from '@assets/icons/external_link.svg';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '@data/useAppDispatch';
import { logoutUser } from '@data/app/app.thunk';
import MobileNavItem from '../../nav-item/MobileNavItem';
import HDivider from '../../../../../components/divider/HDivider';

const MobileMenuFooter: React.FC<{ className?: string }> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = React.useCallback(
    () => () => {
      dispatch(logoutUser()).then(() => {
        navigate('/');
      });
    },
    [dispatch, navigate],
  );

  return (
    <div className="w-full list-none lg:w-auto lg:fixed lg:bottom-0 flex-col-center pb-3.5">
      <HDivider className="ml-6 mr-6 mt-3.5" />
      <MobileNavItem
        key="Log out"
        label="Log out"
        pathname="out"
        icon={ExternalLinkSvg}
        onClick={onLogout()}
      />
    </div>
  );
};

export default MobileMenuFooter;
