import EventCard from '@view/home-page/components/event-view/EventCard';
import { TextKeys } from '@lib/i18n/configureI18n';
import * as React from 'react';
import { ReactComponent as EmptyList } from '@assets/images/empty-pages/empty_events_list.svg';
import { useTranslation } from 'react-i18next';
import useSocialEventsList from '@view/home-page/components/event-view/hooks/useSocialEventsList';
import { SocialEvent } from '@data/social-events/socialEvents.model';

type EventsListProps = {
  activeTab: number;
};

const EventsList: React.FC<EventsListProps> = ({ activeTab }) => {
  const { t } = useTranslation();

  const { showLoading, eventsList } = useSocialEventsList(activeTab);

  if (showLoading)
    return (
      <div className="flex flex-wrap justify-center w-full pl-4 pr-4 mt-2 h-fit sm:pr-0 sm:pl-0">
        {[...Array(4)].map((_, index) => (
          <EventCard key={index} loading />
        ))}
      </div>
    );

  if (eventsList.length === 0)
    return (
      <div className="w-full flex-col-center">
        <EmptyList className="h-[100px] mt-[50px]" />
        <span className="mt-6 text-center text-black w-[330px] body1 !text-[13px] !leading-[19.5px]">
          {
            [
              t(TextKeys.YouHaventJoinedAnyEvents),
              t(TextKeys.ThereAreNoUpcomingEvents),
            ][activeTab]
          }
        </span>
      </div>
    );

  return (
    <div className="flex flex-wrap justify-center w-full pb-4 pl-4 pr-4 mt-2 h-fit sm:pr-0 sm:pl-0">
      {eventsList
        .sort((a: SocialEvent, b: SocialEvent) =>
          a.time.startTime > b.time.startTime ? -1 : 1,
        )
        .map((event) => {
          return <EventCard key={`event-${event.id}`} event={event} />;
        })}
    </div>
  );
};

export default EventsList;
