import React from 'react';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { MenuItem } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { GroupedDropDownItems } from './models/DropDownItems';
import useDropDown from './hooks/useDropDown';

type GroupedDropDownMenuProps<T> = {
  title?: string;
  selected?: string;
  onSelect?: (key: string, anchorEl?: any) => void;
  items?: GroupedDropDownItems<T>;
  disabled?: boolean;
};

const GroupedDropDownMenu: React.FC<GroupedDropDownMenuProps<any>> = ({
  title,
  selected = '',
  items = {},
  disabled,
  onSelect,
}) => {
  const { id, handleClose, handleOpen, open, anchorEl } = useDropDown();

  const getSelectedItem = () => {
    try {
      const parent = selected.split('-')[0];
      const child = selected.split('-')[1];
      return (
        items[parent][child] || {
          label: '',
          Icon: '',
        }
      );
    } catch (e) {
      return {
        label: '',
        Icon: '',
      };
    }
  };

  const renderMenuItem = (key: string) => {
    const handleSelect = (key: string) => () => {
      if (onSelect) onSelect(key, anchorEl);
      handleClose()();
    };

    return Object.keys(items[key]).map((i, index) => {
      const item = items[key][i];
      const isSelected = `${key}-${item.key}` === selected;
      return (
        <div key={`c-${id}-${key}-${index}`}>
          {index === 0 && key !== 'unassigned' ? (
            <div className="pl-4 font-normal text-on-secondary caption mb-[24px] mt-[12px]">
              {key}
            </div>
          ) : (
            ''
          )}
          <MenuItem
            key={`${id}-${key}-${index}`}
            className="!text-on-secondary"
            onClick={handleSelect(`${key}-${i}`)}
          >
            <div className="w-6">{item.Icon}</div>
            <span className="mr-2  pl-[15px] w-[15rem]">
              <p className="w-full overflow-hidden truncate body1 text-on-primary">
                {item.label}
              </p>
              {item.description && (
                <p className="break-normal whitespace-normal caption text-on-secondary">
                  {item.description}
                </p>
              )}
            </span>
            {!isSelected ? (
              <RadioButtonUncheckedIcon />
            ) : (
              <RadioButtonCheckedIcon />
            )}
          </MenuItem>
        </div>
      );
    });
  };

  const selectedItem = getSelectedItem();

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className="bg-white h-[36px] !p-0 !hover:rounded-md"
        onClick={handleOpen()}
        disabled={disabled}
      >
        <div className="flex items-center justify-center w-full h-full pl-2 cursor-pointer rounded-md narrow-border text-on-secondary">
          {selectedItem.Icon}{' '}
          <span className="ml-2 mr-3 font-normal caption">
            {selectedItem.label}
          </span>
          {!disabled && (
            <ArrowDropDownIcon className="mr-2 !w-[20px] !h-[20px]" />
          )}
        </div>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose()}
        className="mt-2"
        MenuListProps={{
          className: 'w-[333px] pl-[16px] max-h-[430px] mt-2',
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className="text-on-primary h3 mb-[24px] pl-[16px] pt-[16px]">
          {title}
        </div>
        {Object.keys(items).map((k) => renderMenuItem(k))}
      </Menu>
    </>
  );
};

export default GroupedDropDownMenu;
