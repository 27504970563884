import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';

import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import SupportGroupSelector from '@data/support-groups/supportGroup.selector';
import { SupportGroup } from '@data/support-groups/supportGroups.model';
import useAppDispatch from '@data/useAppDispatch';
import {
  deleteSupportGroup,
  hideSupportGroup,
  unhideSupportGroup,
} from '@data/support-groups/supportGroup.thunk';
import { RootState } from '@lib/configureStore';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';

interface SupportGroupSettingType {
  label: string;
  icon: () => JSX.Element;
  onClick: () => () => void;
  show: boolean;
}

const useSupportGroupSetting = (
  groupId: string,
  onEdit: () => void,
): {
  settingOptions: SupportGroupSettingType[];
} => {
  const supportGroup: SupportGroup | undefined = useSelector(
    (state: RootState) =>
      SupportGroupSelector.selectById(
        state.supportGroups.all,
        `all-${groupId}`,
      ),
  );

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const settingOptions = React.useMemo(() => {
    const iconClass = (color) => `text-${color} mr-3`;

    return [
      {
        label: t(TextKeys.ViewGroupInfo),
        icon: () => <InfoIcon className={iconClass('brand-primary')} />,
        onClick: () => () =>
          navigate(endpointIDReplacer(Endpoints.GROUP_DETAILS, groupId)),
        show: true,
      },
      {
        label: t(TextKeys.EditGroupSetting),
        icon: () => <SettingsIcon className={iconClass('brand-primary')} />,
        onClick: () => () => onEdit(),
        show: Boolean(supportGroup?.status.userIsAdmin),
      },
      {
        label: t(TextKeys.HideGroup),
        icon: () => (
          <VisibilityOffIcon className={iconClass('secondary-background')} />
        ),
        onClick: () => () => dispatch(hideSupportGroup({ groupId })),
        show:
          Boolean(supportGroup?.status.userIsAdmin) &&
          Boolean(!supportGroup?.status.isHidden),
      },
      {
        label: t(TextKeys.UnhideGroup),
        icon: () => (
          <VisibilityIcon className={iconClass('secondary-background')} />
        ),
        onClick: () => () => dispatch(unhideSupportGroup({ groupId })),
        show:
          Boolean(supportGroup?.status.userIsAdmin) &&
          Boolean(supportGroup?.status.isHidden),
      },
      {
        label: t(TextKeys.DeleteGroup),
        icon: () => <DeleteIcon className={iconClass('error')} />,
        onClick: () => () =>
          dispatch(deleteSupportGroup({ groupId })).then(() =>
            navigate(Endpoints.GROUPS),
          ),
        show: Boolean(supportGroup?.status.userIsAdmin),
      },
    ];
  }, [
    t,
    supportGroup?.status.userIsAdmin,
    supportGroup?.status.isHidden,
    navigate,
    groupId,
    onEdit,
    dispatch,
  ]);

  return { settingOptions };
};

export default useSupportGroupSetting;
