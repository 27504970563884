// @flow
import * as React from 'react';
import { Dialog, IconButton } from '@mui/material';
import { ReactComponent as KarmaSvg } from '@assets/icons/karma.svg';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CloseIcon from '@mui/icons-material/Close';

type KarmaDialogProps = {
  open: boolean;
  onClose: () => void;
};

const KarmaDialog: React.FC<KarmaDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const points: { icon: React.ReactNode; label: string; point: number }[] = [
    {
      icon: <FavoriteIcon className="w-6 h-6 text-tertiary" />,
      label: t(TextKeys.KarmaDialogPointsFavorite),
      point: 1,
    },
    {
      icon: <CommentIcon className="w-6 h-6 text-tertiary" />,
      label: t(TextKeys.KarmaDialogPointsComment),
      point: 5,
    },
    {
      icon: <EditIcon className="w-6 h-6 text-tertiary" />,
      label: t(TextKeys.KarmaDialogPointsPost),
      point: 10,
    },
    {
      icon: <EmojiEventsIcon className="w-6 h-6 text-tertiary" />,
      label: t(TextKeys.KarmaDialogPointsProgram),
      point: 100,
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} className="w-full h-full">
      <div className="relative flex flex-col items-center w-[365px] pt-9 pb-9">
        <IconButton className="absolute top-2 right-2" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <KarmaSvg className="w-10 h-10 " />
        <h3 className="w-full mt-1 text-center h3 text-on-primary">
          Karma Score
        </h3>
        <p className="w-full mt-5 mb-10 text-center text-on-primary body1 text-on=primary">
          {t(TextKeys.KarmaDialogDescription)}
        </p>
        {points.map((point, index) => (
          <div
            key={index}
            className="flex flex-row items-start justify-start mb-3 w-[200px]"
          >
            {point.icon}
            <div className="flex flex-col ml-7">
              <h5 className="h5">{point.label}</h5>
              <p className="mt-1 body2 text-on-secondary">
                Gain {point.point} point{point.point > 1 && 's'}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Dialog>
  );
};
export default KarmaDialog;
