// @flow
import * as React from 'react';
import { useParams } from 'react-router-dom';
import GroupEventActivitiesView from '@shared/templates/group-event-view/GroupEventActivitiesView';
import { fetchGroupTimeline } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import useSupportGroupSetting from '@shared/templates/group-event-view/hooks/useSupportGroupSetting';
import useCreateGroup from '@shared/components/create-group-dialog/hooks/useCreateGroup';
import CreateGroupDialog from '@shared/components/create-group-dialog/CreateGroupDialog';
import useSupportGroupDetails from '@shared/templates/group-event-view/hooks/useSupportGroupDetails';

const SupportGroupActivities: React.FC<unknown> = () => {
  const { groupId } = useParams();
  const supportGroupDetails = useSupportGroupDetails(groupId as string);
  const editDialog = useCreateGroup('edit', Number(groupId));
  const { settingOptions } = useSupportGroupSetting(
    groupId || '',
    editDialog.openDialog,
  );

  if (!groupId) return null;

  return (
    <>
      <CreateGroupDialog
        open={editDialog.open}
        mode={editDialog.mode}
        setCover={editDialog.setCover}
        onCancel={editDialog.onCancel}
        onSubmit={editDialog.onSubmit}
        setTitle={editDialog.setTitle}
        setDescription={editDialog.setDescription}
        loading={editDialog.loading}
        isValid={editDialog.isValid}
        cover={editDialog.cover}
        title={editDialog.title}
        description={editDialog.description}
      />
      <GroupEventActivitiesView
        fetcher={fetchGroupTimeline}
        channelId={supportGroupDetails.channelId || 0}
        selectors={{
          selector: TimelineSelectors.groups.selectIds,
          hasMoreSelector: TimelineSelectors.groups.hasMoreSelector,
          pageSelector: TimelineSelectors.groups.pageSelector,
        }}
        cover={supportGroupDetails.cover}
        title={supportGroupDetails.title}
        settingOptions={settingOptions}
      />
    </>
  );
};
export default SupportGroupActivities;
