import * as React from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { useSelector } from 'react-redux';
import { SocialEventsSelector } from '@data/social-events/socialEvents.selector';
import {
  fetchMySocialEvents,
  fetchRecommendedSocialEvents,
} from '@data/social-events/socialEvents.thunk';
import { SocialEvent } from '@data/social-events/socialEvents.model';
import { LoadingState } from '../../../../../models/Loading';

const useSocialEventsList = (
  activeTab: number,
): {
  eventsList: SocialEvent[];
  showLoading: boolean;
} => {
  const [previousTab, setPreviousTab] = React.useState(-1);
  const [loadingEvents, setLoadingEvents] = React.useState<LoadingState[]>([
    LoadingState.IDLE,
    LoadingState.IDLE,
  ]);
  const dispatch = useAppDispatch();

  const myEvents = useSelector(SocialEventsSelector.myEvents.selectAll);
  const recommendedEvents = useSelector(
    SocialEventsSelector.recommendedEvents.selectAll,
  );

  React.useEffect(() => {
    if (
      activeTab !== previousTab &&
      loadingEvents[activeTab] === LoadingState.IDLE
    ) {
      setLoadingEvents(
        loadingEvents.map((_, index) =>
          index === activeTab ? LoadingState.LOADING : loadingEvents[index],
        ),
      );
      dispatch(
        [fetchMySocialEvents, fetchRecommendedSocialEvents][activeTab]({
          page: 1,
        }),
      )
        .then(() => {
          setLoadingEvents(
            loadingEvents.map((_, index) =>
              index === activeTab
                ? LoadingState.SUCCEEDED
                : loadingEvents[index],
            ),
          );
        })
        .catch(() => {
          setLoadingEvents(
            loadingEvents.map((_, index) =>
              index === activeTab ? LoadingState.FAILED : loadingEvents[index],
            ),
          );
        });
      setPreviousTab(activeTab);
    }
  }, [activeTab, dispatch, loadingEvents, previousTab]);

  return {
    showLoading: loadingEvents[activeTab] === LoadingState.LOADING,
    eventsList: [myEvents, recommendedEvents][activeTab],
  };
};

export default useSocialEventsList;
