import React from 'react';
import { ReactComponent as LogoSvg } from '@assets/logo.svg';
import { Link } from 'react-router-dom';

export interface LogoProps {
  isLink?: boolean;
  className?: string;
}

/**
 * Curatio Branding Logo
 */
const Logo = (props: LogoProps) => {
  const { isLink, className } = props;
  return (
    <div className={`${className} h-10 inline-block`}>
      {isLink ? (
        <Link to="/">
          <LogoSvg />
        </Link>
      ) : (
        <LogoSvg />
      )}
    </div>
  );
};

export default Logo;
