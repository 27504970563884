import { Button } from '@mui/material';
import React from 'react';
import useIsMobile from '@hooks/use-breakpoint/useIsMobile';
import { FeedTypes } from '../../../../../../models/Feed';
import { DraftActions, DraftContext } from '../../hooks/useDraftActions';

const types: { key: FeedTypes; label: string }[] = [
  { label: 'Create a post', key: FeedTypes.SHARE },
  {
    label: 'Ask a question',
    key: FeedTypes.QUESTION,
  },
  {
    label: 'Challenge',
    key: FeedTypes.MISSION,
  },
  {
    label: 'Create A Poll',
    key: FeedTypes.POLL,
  },
  // {label: 'Share my health', key: FeedTypes.HEALTH},
];

const MakePostEditorTabs: React.FC<{
  defaultValue?: FeedTypes;
  setSelectedType: (type: FeedTypes) => void;
}> = ({ setSelectedType, defaultValue }) => {
  const [value, setValue] = React.useState(defaultValue || FeedTypes.SHARE);

  const { setType } = React.useContext(DraftContext) as DraftActions;

  const disabled = defaultValue !== undefined;

  React.useEffect(() => {
    if (value === undefined) return;
    setType(value);
    setSelectedType(value);
  }, [setType, value, setSelectedType]);

  const handleSelect = (key: string) => () => {
    // TODO prevent changing on edit mode
    setValue(key as FeedTypes);
  };

  const isMobile = useIsMobile();

  const roundStyle = (i) => {
    if (i === 0) return 'rounded-tl-lg';
    if (i === 1 && isMobile) return 'rounded-tr-lg sm:rounded-none';
    if (i === types.length - 2 && isMobile)
      return 'rounded-bl-lg sm:rounded-bl-none';
    if (i === types.length - 1)
      return isMobile ? 'rounded-br-lg' : 'rounded-tr-lg';
    return 'rounded-none';
  };

  const fontAndColorStyle = (key) => {
    if (value === key)
      return '!bg-lighter-background !h6 !text-brand-primary !border-b-2 !border-b-brand-primary';
    if (disabled) return '';
    return ` !caption !font-normal !text-on-secondary !cursor-pointer`;
  };

  return (
    <div className="flex flex-wrap w-full pl-3 pr-3 h-fit sm:h-[30px] sm:flex-nowrap">
      {types.map((t, i) => (
        <div
          className={`narrow-border w-1/2 sm:flex-1 flex-col-center ${roundStyle(
            i,
          )}`}
          key={t.label}
        >
          <Button
            disabled={disabled}
            className={`!p-0 !w-full !h-full ${fontAndColorStyle(t.key)} `}
            onClick={handleSelect(t.key)}
          >
            {t.label}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default MakePostEditorTabs;
