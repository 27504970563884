// @flow
import * as React from 'react';
import { ReactComponent as DefaultCover } from '@assets/images/default-images/group_cover.svg';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

type HeaderProps = {
  cover?: string;
  loading?: boolean;
};

const Header: React.FC<HeaderProps> = ({ cover, loading }) => {
  if (loading)
    return (
      <Stack spacing={1} className="w-full">
        <Skeleton variant="rectangular" width="100%" height={120} />
      </Stack>
    );

  return (
    <img
      className="object-cover w-full h-[120px]"
      src={cover || (DefaultCover as unknown as string)}
      alt="Group Cover"
    />
  );
};

Header.defaultProps = {
  cover: undefined,
  loading: false,
};

export default Header;
