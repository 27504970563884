// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import { Card } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';

import Timeline from '@shared/components/timeline/Timeline';
import { fetchReviewedAEPosts } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import { TimeLines } from '@data/timeline/timeline.model';
import { AsyncThunk } from '@reduxjs/toolkit';

const ReviewedAdverseEventsPage: React.FC = () => {
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const renderRightAndLeftButtons = (Icon, step: number) => {
    return (
      <IconButton
        classes="ml-6 mr-6 text-on-secondary"
        onClick={() => {
          const newDate = moment(selectedDate).add(step, 'month').toDate();
          if (newDate.getTime() > new Date().getTime()) return;
          setSelectedDate(newDate);
        }}
      >
        <Icon />
      </IconButton>
    );
  };

  const renderDate = React.useCallback(() => {
    const date = moment(selectedDate).format('MMMM YYYY');
    return (
      <span className="text-black h4 w-[140px] flex-col-center">{date}</span>
    );
  }, [selectedDate]);

  const renderTimeSlider = () => {
    return (
      <div className="flex items-center justify-center w-full max-w-[540px]">
        {renderRightAndLeftButtons(KeyboardArrowLeftIcon, -1)}
        {renderDate()}
        {renderRightAndLeftButtons(KeyboardArrowRightIcon, +1)}
      </div>
    );
  };

  const fetchCases = React.useCallback(
    ({ page }) =>
      fetchReviewedAEPosts({
        page,
        month: Number(moment(selectedDate).format('M')),
        year: Number(moment(selectedDate).format('YYYY')),
      }),
    [selectedDate],
  );

  const renderCases = React.useCallback(() => {
    return (
      <Timeline
        timelineFetcher={
          fetchCases as unknown as AsyncThunk<never, { page: number }, never>
        }
        selector={(state) =>
          TimelineSelectors.AECases.selectIdsByDate(
            state,
            moment(selectedDate).format('YYYY-M'),
          )
        }
        hasMoreSelector={TimelineSelectors.AECases.hasMoreSelector}
        pageSelector={TimelineSelectors.AECases.pageSelector}
        timeline={TimeLines.REPORTED_ADVERSE_EVENT}
        emptyListMessage="No cases found"
      />
    );
  }, [fetchCases, selectedDate]);

  return (
    <WithSideBar>
      <div className="flex justify-center w-full h-full pb-4">
        <div className="relative w-full h-auto mt-5 flex-col-center max-w-[540px]">
          <span className="w-full mb-4 flex-col-center h3 text-on-primary">
            {t(TextKeys.AdverseEvents)}
          </span>
          {renderTimeSlider()}
          <Card className="w-full h-full mt-1 max-w-[540px]">
            {renderCases()}
          </Card>
        </div>
      </div>
    </WithSideBar>
  );
};

export default ReviewedAdverseEventsPage;
