/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

export interface AppCardProps {
  isActive: boolean;
  id: string;
  img: string;
  displayName: string;
  onClick: (key: string) => void;
}

/**
 *  Selection Card
 */
const SelectionCard = ({
  id,
  img,
  displayName,
  isActive,
  onClick,
}: AppCardProps) => {
  const classes =
    'text-sm font-bold w-full bg-white min-w-180 max-w-180 h-44 flex flex-col ' +
    'items-center text-center relative flex-1 select-none cursor-pointer ' +
    'border-r border-t border-b border-gray-secondary mb-4 rounded-md shadow-card ' +
    `${
      isActive &&
      'h-[190px] -mt-2 min-w-[190px] z-[3] -mr-[5px] -ml-[5px]' +
        'before:content-none before:block before:absolute before:h-2 before:w-full before:top-0 before:left-0 before:bg-white' +
        'after:content-none after:block after:absolute after:h-2 after:w-full after:bottom-0 after:left-0 after:bg-brand-primary after:rounded-b-[6px]'
    }`;

  return (
    <div
      className={classes}
      onClick={() => onClick(id)}
      role="button"
      tabIndex={0}
    >
      <img
        src={img}
        alt={displayName}
        className="object-contain max-w-[60%] h-14 mt-[20%]"
      />
      <span className="block pl-2 pr-2 break-words mt-1.5">{displayName}</span>
    </div>
  );
};

export default React.memo(SelectionCard, (prev, next) => {
  return next.isActive === prev.isActive;
});
