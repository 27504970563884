import { useSelector } from 'react-redux';

import { ReactComponent as BellSvg } from '@assets/icons/bell.svg';
import useBreakpoint from '@hooks/index';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, SvgIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { NotificationSelector } from '@data/notifications/Notification.selector';
import NotificationsDrawer from '@shared/templates/with-side-bar/components/notification-drawer/NotificationsDrawer';
import endpoints from '@view/routes/endpoints';
import { isExtraSmallBreakpoint } from '../../../../../../utils';

const NotificationButton: React.FC<unknown> = () => {
  const navigate = useNavigate();
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = React.useState(false);
  const { breakpoint } = useBreakpoint();

  const newNotifications = useSelector(NotificationSelector.getUnreadCount);

  const openNotification = (): void => {
    if (isExtraSmallBreakpoint(breakpoint)) navigate(endpoints.NOTIFICATIONS);
    else setIsMenuDrawerOpen(true);
  };

  const renderBell = (): React.ReactNode => (
    <IconButton onClick={openNotification}>
      <Badge badgeContent={newNotifications} color="error">
        <SvgIcon className="!fill-grey-light" component={BellSvg} />
      </Badge>
    </IconButton>
  );

  const renderDesktopView = (): React.ReactNode => (
    <div className="items-center hidden pr-6 ml-auto lg:flex">
      {renderBell()}
      <NotificationsDrawer
        open={isMenuDrawerOpen}
        onClose={(): void => setIsMenuDrawerOpen(false)}
      />
    </div>
  );

  const renderMobileView = (): React.ReactNode => (
    <div className="absolute right-0 flex w-16 h-full flex-col-center bg-lighter lg:hidden">
      {renderBell()}
    </div>
  );

  return (
    <>
      {renderDesktopView()}
      {renderMobileView()}
    </>
  );
};

export default NotificationButton;
