// @flow
import { Backdrop } from '@mui/material';
import * as React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export type LightBoxContextType = {
  openLightBox: (
    sources: { url: string; type: 'video' | 'image' }[],
    startIndex?: number,
  ) => void;
};

export const LightBoxContext = React.createContext<LightBoxContextType | null>(
  null,
);

const WithLightbox: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [sources, setSources] = React.useState<
    { url: string; type: 'video' | 'image' }[]
  >([]);
  const [index, setIndex] = React.useState<number>(0);

  React.useEffect(() => {
    if (!isOpen) {
      setSources([]);
      setIndex(0);
    }
  }, [isOpen]);

  const renderImage = (url: string) => (
    <img className="object-contain max-w-3/4 h-3/4" src={url} alt="" />
  );
  const renderVideo = (url: string) => (
    <video className="max-w-3/4 h-3/4" controls autoPlay>
      <source src={url} />
    </video>
  );

  const renderMedia = () => {
    if (!sources[index]) return null;
    if (sources[index].type === 'video') return renderVideo(sources[index].url);
    if (sources[index].type === 'image') return renderImage(sources[index].url);
    return null;
  };

  const lightBoxContextValue = React.useMemo(
    () => ({
      openLightBox: (
        s: { url: string; type: 'video' | 'image' }[],
        startIndex?: number,
      ) => {
        setIsOpen(true);
        setIndex(startIndex ?? 0);
        setSources(s);
      },
    }),
    [],
  );

  return (
    <LightBoxContext.Provider value={lightBoxContextValue}>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isOpen}
      >
        <div className="relative flex items-center justify-center w-full h-full p-[20px]">
          <IconButton
            onClick={() => setIsOpen(false)}
            className="absolute right-5 top-5"
          >
            <CloseIcon className="!text-white text-[40px]" />
          </IconButton>
          <IconButton
            onClick={() => setIndex(index - 1)}
            className={`m-4 ${index > 0 ? '' : 'invisible'}`}
          >
            <KeyboardArrowLeftIcon className="!text-white text-[40px]" />
          </IconButton>
          {renderMedia()}
          <IconButton
            onClick={() => setIndex(index + 1)}
            className={`m-4 ${index < sources.length - 1 ? '' : 'invisible'}`}
          >
            <KeyboardArrowRightIcon className="!text-white text-[40px]" />
          </IconButton>
        </div>
      </Backdrop>
      {children}
    </LightBoxContext.Provider>
  );
};
export default WithLightbox;
