import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import ContentCardSkeleton from '@view/discover-page/components/ContentCardSkeleton';

const ContentGroupSkeleton: React.FC<{
  numOfCards?: number;
}> = ({ numOfCards = 3 }) => {
  const id = React.useId();

  return (
    <div className="relative">
      <Skeleton variant="text" className="w-1/3 h-9" />
      <div className="flex flex-wrap items-center justify-start w-full mt-4">
        {[...new Array(numOfCards)].map((_, index) => {
          return <ContentCardSkeleton index={index} key={`${id}-${index}`} />;
        })}
      </div>
    </div>
  );
};

export default ContentGroupSkeleton;
