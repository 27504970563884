import { FileUtils, ParamsUtils } from '@utils/index';
import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';
import { SocialEventDraft } from '@data/social-events/socialEvents.model';

export const fetchMySocialEvents = createAsyncThunkWithAuth<{
  page: number;
}>('socialEvent/fetchMySocialEvents', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GET_EVENTS, {
          user: args.userId,
          page: args.page,
          eventType: 'joined',
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const fetchRecommendedSocialEvents = createAsyncThunkWithAuth<{
  page: number;
}>('socialEvent/fetchRecommendedSocialEvents', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        urlGenerator(ApiEndpoint.GET_EVENTS, {
          user: args.userId,
          page: args.page,
          eventType: 'recommendation',
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const fetchSocialEvent = createAsyncThunkWithAuth<{ eventId: string }>(
  'socialEvent/fetchSocialEvent',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.GET_SELECTED_EVENT, {
            user: args.userId,
            eventId: args.eventId,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const createSocialEvent = createAsyncThunkWithAuth<SocialEventDraft>(
  'socialEvent/createSocialEvent',
  async (args, urlGenerator, dispatch) => {
    return new Promise((resolve, reject) => {
      FileUtils.localURLtoFile(args.cover, args.title)
        .then((cover) => {
          const body = {
            title: args.title,
            description: args.description,
            start_time: args.startDate,
            location_url: args.location,
            end_time: args.endDate,
            user: args.userId,
            relation: args.relation,
            channel_id: args.channelId,
          };
          const form = ParamsUtils.jsonToForm(body);
          form.append('img', cover);

          axios
            .post(
              urlGenerator(ApiEndpoint.CREATE_EVENT, {
                user: args.userId,
              }),
              form,
            )
            .then(async (response) => {
              if (response.data?.id) {
                (
                  dispatch(
                    fetchSocialEvent({ eventId: response.data.id }),
                  ) as unknown as PromiseLike<any>
                ).then((res) => {
                  resolve(res);
                });
              } else reject(response);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  },
);

export const editSocialEvent = createAsyncThunkWithAuth<
  SocialEventDraft & { eventId: string }
>('socialEvent/editSocialEvent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    FileUtils.localURLtoFile(args.cover, args.title).then((cover) => {
      const body = {
        title: args.title,
        description: args.description,
        start_time: args.startDate,
        location_url: args.location,
        end_time: args.endDate,
        user: args.userId,
      };
      const form = ParamsUtils.jsonToForm(body);
      form.append('img', cover);
      axios
        .put(
          urlGenerator(ApiEndpoint.EDIT_EVENT, {
            user: args.userId,
            eventId: args.eventId,
          }),
          form,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  });
});

export const fetchSocialEventParticipants = createAsyncThunkWithAuth<{
  eventId: number;
  totalNumberOfParticipants: number;
}>('socialEvent/fetchSocialEventParticipants', async (args, urlGenerator) => {
  const responses = await Promise.all(
    [...Array(Math.ceil(args.totalNumberOfParticipants / 50))].map(
      async (_, index) => {
        const response = await axios.get(
          urlGenerator(ApiEndpoint.GET_EVENT_MEMBERS, {
            user: args.userId,
            eventId: args.eventId,
            page: index + 1,
          }),
        );
        return response.data.members;
      },
    ),
  );
  return responses.flat();
});

export const joinSocialEvent = createAsyncThunkWithAuth<{
  eventId: number | string;
  feedId?: number;
}>('socialEvent/joinSocialEvent', async (args, urlGenerator, dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        urlGenerator(ApiEndpoint.JOIN_EVENT, {
          user: args.userId,
          eventId: args.eventId,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const leaveSocialEvent = createAsyncThunkWithAuth<{
  eventId: number | string;
}>('socialEvent/leaveSocialEvent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.LEAVE_EVENT, {
          user: args.userId,
          eventId: args.eventId,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});

export const deleteSocialEvent = createAsyncThunkWithAuth<{
  eventId: number | string;
}>('socialEvent/deleteSocialEvent', async (args, urlGenerator) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        urlGenerator(ApiEndpoint.DELETE_EVENT, {
          user: args.userId,
          eventId: args.eventId,
        }),
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
});
