import React from 'react';
import { debounce } from '@mui/material';
import { ReactComponent as LogoIconSvg } from '@assets/logo_icon.svg';
import CircleIcon from '@mui/icons-material/Circle';

interface RescrollerType {
  className?: string;
  components?: {
    Header?: React.ReactNode;
  };
  itemsCount: number;
  renderer: (index: number) => React.ReactNode;
  scroller: React.MutableRefObject<HTMLElement | null>;
  loadMore?: () => void;
  hasMore?: boolean;
  reverse?: boolean;
}

const Rescroller = (props: RescrollerType) => {
  const {
    components,
    className = '',
    renderer,
    itemsCount,
    scroller,
    loadMore,
    hasMore = true,
    reverse = false,
  } = props;
  const [loadScroller, setLoadScroller] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const selfScroller = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setLoadScroller(false);
    if (itemsCount === 0 && hasMore) setLoading(true);
    if (itemsCount > 0) setLoading(false);
  }, [itemsCount, hasMore]);

  const handleLoadMore = React.useCallback(() => {
    if (loadMore && hasMore) {
      loadMore();
      setLoading(true);
    }
  }, [loadMore, hasMore]);

  React.useEffect(() => {
    if (
      ((!reverse && scroller !== null) || (reverse && selfScroller !== null)) &&
      !loadScroller
    ) {
      const element = (reverse ? selfScroller : scroller)
        .current as HTMLElement;
      element.onscroll = debounce(() => {
        if (
          ((reverse &&
            element.scrollHeight - element.clientHeight + element.scrollTop <
              100) ||
            (!reverse &&
              element.scrollHeight - element.scrollTop - 100 <
                element.clientHeight)) &&
          !loading
        )
          handleLoadMore();
      });
      setLoadScroller(true);
    }
  }, [scroller, loadScroller, loading, reverse, handleLoadMore]);

  const renderItems = () => {
    return [...new Array(itemsCount)].map((_, i) => renderer(i));
  };

  const renderLoading = () => {
    return (
      <div className="h-12 pt-5 pb-5 flex-col-center">
        <div className="h-10 opacity-50 flex-col-center">
          <LogoIconSvg className="animate-spin" />
        </div>
      </div>
    );
  };
  const renderEnd = () => {
    return (
      <div className="h-12 flex-col-center text-on-secondary">
        <CircleIcon className="!h-2" />
      </div>
    );
  };

  const containerClassName = `${className} ${
    reverse ? 'h-full flex flex-col-reverse ' : ''
  } ${itemsCount > 0 && reverse && 'overflow-auto'}`;

  return (
    <div className={containerClassName} key="rescroller" ref={selfScroller}>
      {components?.Header}
      {renderItems()}
      {loading ? renderLoading() : null}
      {!hasMore && !reverse ? renderEnd() : null}
    </div>
  );
};

export default Rescroller;
