// @flow
import * as React from 'react';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import IconButton from '@mui/material/IconButton';
import { TimeLines } from '@data/timeline/timeline.model';
import MakePostDialogProvider from '@shared/components/make-post/MakePostDialogProvider';
import { MakePostDialogOpeningModeAction } from '@shared/components/make-post/models/MakePostAction';
import GroupEventView from './GroupEventView';
import MakePostContainer from '../../components/make-post/components/make-post-container/MakePostContainer';
import Timeline from '../../components/timeline/Timeline';

type GroupEventViewProps = {
  cover?: string;
  fetcher: AsyncThunk<any, any, any>;
  title;
  selectors: {
    selector: (state) => EntityId[];
    pageSelector: (state) => number;
    hasMoreSelector: (state) => boolean;
  };
  channelId: number;
  settingOptions?: {
    label: string;
    icon: () => React.ReactElement;
    onClick: () => () => void;
    show: boolean;
  }[];
};

const GroupEventActivitiesView: React.FC<GroupEventViewProps> = (props) => {
  const {
    cover,
    fetcher,
    selectors,
    settingOptions = [],
    title,
    channelId,
  } = props;

  const [openDialog, setOpenDialog] =
    React.useState<MakePostDialogOpeningModeAction>({
      mode: 'close',
    });

  const { t } = useTranslation();

  const handleEditPost = (id: string) => () => {
    setOpenDialog({ mode: 'open', id });
  };

  const handleOpenMakePostDialog = React.useCallback(
    (mode?: 'photo' | 'video') => {
      setOpenDialog({ mode: mode || 'open' });
    },
    [],
  );

  const handleCloseMakePostDialog = React.useCallback(
    () => () => {
      setOpenDialog({ mode: 'close' });
    },
    [],
  );

  const renderTitle = () => {
    return (
      <h1 className="absolute flex items-center w-full pl-6 h-[50px] max-w-[540px] bg-white/90 rounded-md h3 top-[100px]">
        {title}
      </h1>
    );
  };

  const renderSettings = () => {
    return (
      <Card className="flex flex-col items-start justify-start w-full pb-4 pl-4 mt-5 ml-2 mr-2 sm:mr-0 sm:ml-6 sm:w-[200px] lg:w-[300px] pt-[10px] h-fit">
        <h1 className="mb-4 h6 sm:mb-8">{t(TextKeys.Settings)}</h1>
        <div className="flex flex-row flex-wrap w-full sm:flex-col">
          {settingOptions.map((option, index) => {
            if (!option.show) return null;
            return (
              <IconButton
                className="flex justify-start -ml-2 text-left rounded-none subtitle2 w-[50%] sm:w-full"
                key={`setting-${index}`}
                onClick={option.onClick()}
              >
                {option.icon()}
                <span className="ml-2">{option.label}</span>
              </IconButton>
            );
          })}
        </div>
      </Card>
    );
  };

  const renderTimeline = () => {
    const { channelId } = props;

    return (
      <div className="w-full h-full sm:max-w-[400px] lg:max-w-[540px]">
        {renderTitle()}
        <Timeline
          timelineFetcher={
            fetcher as unknown as AsyncThunk<never, { page: number }, never>
          }
          handleEditPost={handleEditPost}
          selector={selectors.selector}
          hasMoreSelector={selectors.hasMoreSelector}
          pageSelector={selectors.pageSelector}
          timeline={TimeLines.GROUP_TIMELINE}
          fetcherProps={{ channelId }}
          className="pr-2"
        >
          <MakePostContainer
            onClick={(type?: 'photo' | 'video') => {
              handleOpenMakePostDialog(type);
            }}
          />
          <MakePostDialogProvider
            open={openDialog}
            closeDialog={handleCloseMakePostDialog()}
          />
        </Timeline>
      </div>
    );
  };

  return (
    <GroupEventView cover={cover}>
      <div className="flex flex-col-reverse justify-center w-full sm:flex-row">
        {renderTimeline()}
        {renderSettings()}
      </div>
    </GroupEventView>
  );
};
export default GroupEventActivitiesView;
