import React from 'react';
import MenuFooter from '@shared/templates/with-side-bar/components/menu-drawer/footer/MenuFooter';
import MenuLinks from '@shared/templates/with-side-bar/components/menu-links/MenuLinks';

/**
 * Main Dashboard Sidebar
 */

const Sidebar: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <aside className="fixed hidden h-full bg-white lg:block lg:pt-1 lg:pb-1 shrink-[3] max-w-[210px]">
      <div className="overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-custom-secondary h-[calc(100%_-_140px)]">
        <MenuLinks />
      </div>
      <MenuFooter
        // @ts-ignore
        style={{
          padding: '8px 12px',
          ':hover': {
            // background: transparent,
          },
          span: {
            padding: '8px',
            // border: `1px solid ${lighter}`,
            borderRadius: '50%',
            ':hover': {
              // background: secondaryBackground,
            },
          },
          svg: {
            marginRight: '0',
            width: '34px',
            height: '34px',
            path: {
              fill: '#008AAC',
            },
          },
        }}
      />
    </aside>
  );
};

export default Sidebar;
