enum Endpoints {
  HOME = '/',
  SOCIAL_EVENT = '/social-events/:eventId',
  CREATE_EVENT = '/create-event',
  EDIT_EVENT = '/edit-event/:eventId',
  CIRCLE = '/circle',
  CHAT = '/chat',
  PROFILE = '/profile',
  PROFILE_ID = '/profile/:userId',
  AUTH = '/auth',
  PORTAL = '/portal',
  DISCOVER = '/discover',
  BOOKMARKS = '/bookmarks',
  APP_SELECTION = '/app-selection',
  POST = '/post/:feedId',
  SCHEDULE_POST = '/scheduled-posts',
  DRAFTED_POSTS = '/drafted-posts',
  FLAGGED_POSTS = '/flagged-posts',
  NOTIFICATIONS = '/notifications',
  GROUPS = '/groups',
  GROUP_DETAILS = '/groups/:groupId/details',
  GROUP_ACTIVITY = '/groups/:groupId/activities',
  GROUP_REVIEWS = '/group-review',
  ADVERSE_EVENTS = '/adverse-events',
  ADVERSE_EVENTS_REVIEWED = '/adverse-events/reviewed',
  SETTINGS = '/settings',
  DISCOVER_MY_PROJECTS = '/discover/my-projects',
  DISCOVER_CREATE_SELECTION = '/discover/create',
  DISCOVER_CREATE = '/discover/create/:contentType',
  DISCOVER_PREVIEW = '/discover/preview/:contentId',
  DISCOVER_EDIT = '/discover/edit/:contentId',
  DISCOVER_VIEW = '/discover/view/:contentId',
  NOT_FOUND = '/not-found',
}

export const endpointIDReplacer = (
  endpoint: string,
  id?: string | number,
): string => {
  if (!id) return Endpoints.NOT_FOUND;
  return endpoint.replace(/:\w+/, String(id));
};

export default Endpoints;
