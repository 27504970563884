import React from 'react';
import { NotFoundErrorPage } from '@view/error-page/components';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import RunTimeError from '@view/error-page/components/RunTimeError';
import { ErrorType } from '../../models/constants/error';

type ErrorViewProps = {
  type?: ErrorType;
};

const ErrorView: React.FC<ErrorViewProps> = ({ type }) => {
  const renderErrorPage = (): React.ReactNode => {
    switch (type) {
      case ErrorType.NOT_FOUND:
        return <NotFoundErrorPage />;
      default:
        return <RunTimeError />;
    }
  };

  return <WithSideBar>{renderErrorPage()}</WithSideBar>;
};

ErrorView.defaultProps = {
  type: ErrorType.CLIENT_ERROR,
};

export default ErrorView;
