import breakpoints, {
  BreakpointName,
} from '../../models/constants/breakpoints';

export interface Dimension {
  height: number;
  width: number;
}

export interface SizeProps {
  breakpoint: BreakpointName;
  dimensions: Dimension;
}

interface ResizeEngine {
  breakpoint: BreakpointName;
  currentKey: string;
  didInit: boolean;
  running: boolean;
  subscribers: {
    [key: string]: {
      callbackFunction?: (args?: any) => void;
      onlyPublishByEvent?: boolean;
    };
  };
  dimensions: Dimension;
  getProps: () => SizeProps;
  subscribe: (callbackFunction: any) => string;
  unsubscribe: (key: string) => void;
}

class ResizeEngine {
  constructor() {
    this.currentKey = '0';
    this.didInit = false;
    this.subscribers = {};
    this.running = false;
    this.dimensions = {
      height: 0,
      width: 0,
    };
    this.breakpoint = BreakpointName.XSMALL;
  }

  public getProps = (): SizeProps =>
    this.didInit
      ? {
          breakpoint: this.breakpoint,
          dimensions: this.dimensions,
        }
      : {
          breakpoint: this.breakpoint,
          dimensions: this.dimensions,
        };

  public subscribe = (callbackFunction: any): string => {
    if (!this.didInit) {
      // eslint-disable-next-line no-underscore-dangle
      this._init();
    }

    this.currentKey += 1;
    this.subscribers[this.currentKey] = { callbackFunction };

    return this.currentKey;
  };

  public unsubscribe = (key: string) => {
    delete this.subscribers[key];
  };

  // eslint-disable-next-line no-underscore-dangle
  private _init() {
    this.didInit = true;
    // eslint-disable-next-line no-underscore-dangle
    window.addEventListener('resize', this._onResize);
    // eslint-disable-next-line no-underscore-dangle
    this._updateSizes(true);
  }

  private _onResize = () => {
    if (!this.running) {
      this.running = true;
      // eslint-disable-next-line no-underscore-dangle
      window.requestAnimationFrame(() => this._updateSizes());
    }
  };

  private _updateSizes = (isInit?) => {
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(() => {
        this.dimensions = {
          height: window.innerHeight,
          width: window.innerWidth,
        };
        // eslint-disable-next-line no-underscore-dangle
        this.breakpoint = this._getBreakpoint();
        // eslint-disable-next-line no-underscore-dangle
        this._publish(isInit);
        this.running = false;

        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
  };

  private _getBreakpoint = () => {
    let breakpoint;

    Object.keys(breakpoints.value).some((bp) => {
      if (this.dimensions.width >= breakpoints.value[bp]) {
        breakpoint = bp;
        return true;
      }

      return false;
    });

    return breakpoint;
  };

  private _publish = (isInit = false) => {
    Object.keys(this.subscribers).forEach((key) => {
      const subscription = this.subscribers[key];
      if (!subscription || !subscription.callbackFunction) {
        this.unsubscribe(key);
      } else if (!isInit || (isInit && !subscription.onlyPublishByEvent)) {
        subscription.callbackFunction(this.getProps());
      }
    });
  };
}

export default new ResizeEngine();
