import { Attachment, AttachmentType } from '../models/Feed';

// const getAttachmentType = (fileName: string): AttachmentType => {
//   if (
//     fileName.match(
//       /.(mp4|mkv|wmv|m4v|mov|avi|flv|webm|flac|mka|m4a|aac|ogg)$/i,
//     ) !== null
//   )
//     return AttachmentType.VIDEO;
//   if (fileName.match(/\.(gif|jpe?g|tiff?|png|webp|bmp|svg)$/i) !== null)
//     return AttachmentType.IMAGE;
//
//   return AttachmentType.NONE;
// };

const getImageDimensions = (
  url: string,
): Promise<{ height: number; width: number }> => {
  return new Promise<{ height: number; width: number }>((resolve, reject) => {
    const img = new Image();
    img.onload = (): void => resolve({ height: img.height, width: img.width });
    img.onerror = reject;
    img.src = url;
  });
};

const getVideoDimensions = (
  videoFile: File,
  name: string,
): Promise<{
  height: number;
  width: number;
  duration: number;
  preview: File;
}> => {
  return new Promise<{
    height: number;
    width: number;
    duration: number;
    preview: File;
  }>((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');
    const source = document.createElement('source');
    const context = canvas.getContext('2d');
    const urlRef = URL.createObjectURL(videoFile);

    video.style.display = 'none';
    canvas.style.display = 'none';

    source.setAttribute('src', urlRef);
    video.setAttribute('crossorigin', 'anonymous');
    video.currentTime = 1;

    video.onloadedmetadata = (): void => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    };

    video.appendChild(source);
    if (!context) {
      reject();
      return;
    }

    video.addEventListener('loadeddata', () => {
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }

        resolve({
          height: canvas.height,
          width: canvas.width,
          duration: video.duration,
          preview: new File([blob], name, {
            type: 'image/png',
          }),
        });

        URL.revokeObjectURL(urlRef);

        video.remove();
        canvas.remove();
      }, 'image/png');
    });

    video.load();
  });
};

const filesToForm = async (
  files?: Attachment[],
  form?: FormData,
  urlHash?: string,
  comment?: boolean,
  key = 'file',
): Promise<void> => {
  if (files && form) {
    for (let i = 0; i < files.length; i += 1) {
      const f = files[i];
      const index = files.indexOf(f);
      const prefix = comment
        ? 'commentAttachments[]'
        : `postTypeMeta[${f.type}${index}`;

      form.append(`${key}[]`, f.file);
      // form.append(`file[]`, `thumbnail-${f.file.name}`);
      if (!comment) {
        form.append('postOrder[]', `${f.type}${index}`);

        form.append(
          `postTypeMeta[${f.type}${index}][urlRef]`,
          `${urlHash}/${f.name}`,
        );
      } else {
        form.append('commentAttachments[][commentType]', 'photoComment');
      }

      if (f.type === AttachmentType.IMAGE) {
        /* eslint-disable no-await-in-loop */
        const { height, width } = await getImageDimensions(f.url);
        form.append(`${prefix}[height]`, `${height}`);
        form.append(`${prefix}[width]`, `${width}`);
      } else if (f.type === AttachmentType.VIDEO) {
        /* eslint-disable no-await-in-loop */
        const { height, width, duration, preview } = await getVideoDimensions(
          f.file,
          `thumbnail-${f.name.split('.').slice(0, -1).join('.')}.png`,
        );
        form.append(`${prefix}[height]`, `${height}`);
        form.append(`${prefix}[width]`, `${width}`);
        form.append(`${prefix}[duration]`, `${duration}`);
        form.append(
          `${prefix}][thumbURLRef]`,
          `${urlHash}/thumbnail-${f.name
            .split('.')
            .slice(0, -1)
            .join('.')}.png`,
        );
        form.append(`${key}[]`, preview);
      }
    }
  }
};

const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(',');
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

const localURLtoFile = (localUrl: string, filename: string): Promise<File> => {
  return new Promise((resolve, reject) => {
    const isAbsoluteUrl = /^(?:[a-z]+:)?\/\//.test(localUrl);
    console.log('isAbsoluteUrl', isAbsoluteUrl, localUrl);
    if (isAbsoluteUrl) {
      resolve(null);
      return;
    }
    fetch(localUrl)
      .then((res) => {
        res
          .blob()
          .then((blob) => {
            const metadata = {
              type: 'image/jpeg',
            };
            resolve(new File([blob], filename, metadata));
          })
          .catch(reject);
      })
      .catch(reject);
  });
};

const fileToAttachment = (file: File, url: string): Attachment => {
  let type = AttachmentType.NONE;

  if (file.type.includes('image')) type = AttachmentType.IMAGE;
  else if (file.type.includes('video')) type = AttachmentType.VIDEO;

  return {
    file,
    url,
    name: file.name,
    type,
  };
};

// const getBase64Image = (url: string, ref, takeScreenshot, callback) => {
//   const xhr = new XMLHttpRequest();
//   xhr.onload = () => {
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       callback(reader.result, ref, takeScreenshot);
//     };
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url);
//   xhr.responseType = 'blob';
//   xhr.send();
// };

export default {
  getImageDimensions,
  getVideoDimensions,
  filesToForm,
  dataURLtoFile,
  localURLtoFile,
  fileToAttachment,
  // getBase64Image,
};
