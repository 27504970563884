// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Card, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import { AppSelector } from '@data/app/app.selector';
import { ContentTypes } from '@data/contents/Contents.model';
import endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import { getContentTypeView } from '../../logic/CMS/content.logic';

const ContentTypeSelection: React.FC<unknown> = () => {
  const { nickName } = useSelector(AppSelector.selectUserInfo);
  const navigate = useNavigate();

  const renderHeader = () => {
    return (
      <div className="flex w-full pl-8 pr-8 bg-white flex-col-center h-fit pt-9 pb-9">
        <h1 className="w-full mb-3 text-left h1 text-on-primary max-w-[760px]">
          Hello {nickName}
        </h1>
        <p className="w-full text-left h2 text-on-primary max-w-[760px]">
          What are you looking to create today? Let’s begin with a template.
        </p>
      </div>
    );
  };

  const renderContentTypes = () => {
    return (
      <div className="flex flex-row flex-wrap justify-between w-ful max-w-[760px]">
        {Object.keys(ContentTypes).map((key) => {
          if (key === 'UNKNOWN') return null;
          const contentType = getContentTypeView(ContentTypes[key]);
          return (
            <IconButton
              key={`content-type-${key}`}
              className="mt-6 rounded-none w-[49%] disabled:opacity-50"
              onClick={() =>
                navigate(
                  endpointIDReplacer(
                    endpoints.DISCOVER_CREATE,
                    contentType.label.toLowerCase(),
                  ),
                )
              }
              disabled={!contentType.active}
            >
              <Card className="flex items-center w-full h-full pl-5 pr-5 pt-[30px] pb-[30px]">
                <div className="mr-4 w-[54px] h-[54px]">
                  {contentType.icon()}
                </div>
                <div className="flex-col items-start justify-center text-on-primary">
                  <h1 className="w-full text-left h1">{contentType.label}</h1>
                  <p className="w-full mt-1 text-left body1">
                    {contentType.description}
                  </p>
                </div>
              </Card>
            </IconButton>
          );
        })}
      </div>
    );
  };

  return (
    <WithSideBar>
      <div className="flex flex-col items-center w-full h-full">
        {renderHeader()}
        {renderContentTypes()}
      </div>
    </WithSideBar>
  );
};
export default ContentTypeSelection;
