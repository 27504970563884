import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Conversation, Message } from './Chat.model';
import {
  fetchConversation,
  fetchMessages,
  markAsRead,
  sendMessage,
} from './Chat.thunk';
import {
  convertMessagesResponse,
  convertSentMessageResponse,
  covertConversationsResponse,
} from './Chat.factory';

export const chatAdapter = {
  conversations: createEntityAdapter<Conversation>({
    selectId: (conversation) => conversation.userId,
    sortComparer: (a, b) =>
      b.latestMessageTime.localeCompare(a.latestMessageTime),
  }),
  message: createEntityAdapter<Message>({
    selectId: (message) => message.id,
    sortComparer: (a, b) => b.time.localeCompare(a.time),
  }),
};

const getInitialState = () => ({
  conversations: chatAdapter.conversations.getInitialState(),
  messages: {},
});

const chatSlice = createSlice({
  name: 'chat',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchConversation.fulfilled, (state, action) => {
        chatAdapter.conversations.setAll(
          state.conversations,
          covertConversationsResponse(action.payload.users),
        );
      })
      .addCase(fetchMessages.pending, (state, action) => {
        const { peer } = action.meta.arg;
        if (!state.messages[peer]) {
          return {
            ...state,
            messages: {
              ...state.messages,
              [peer]: {
                ...chatAdapter.message.getInitialState(),
              },
            },
          };
        }
        return state;
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        const { peer, page } = action.meta.arg;
        if (page === 1) {
          chatAdapter.message.setAll(
            state.messages[peer],
            convertMessagesResponse(action.payload),
          );
        } else {
          chatAdapter.message.setMany(
            state.messages[peer],
            convertMessagesResponse(action.payload),
          );
        }
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        const { peer } = action.meta.arg;
        chatAdapter.message.addOne(
          state.messages[peer],
          convertSentMessageResponse(
            action.payload.message,
            action.payload.userId,
          ),
        );
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        const { peer } = action.meta.arg;
        chatAdapter.conversations.updateOne(state.conversations, {
          id: peer,
          changes: {
            unreadMessageCount: 0,
          },
        });
      }),
});

export default {
  reducers: chatSlice.reducer,
  getInitialState,
};
