import * as React from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { fetchProfilePicture } from '@data/profiles/Profile.thunk';

const useProfilePicture = (targetId?: number) => {
  const [profilePicture, setProfilePicture] = React.useState<string | null>(
    null,
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (targetId && !profilePicture) {
      dispatch(
        fetchProfilePicture({
          targetId,
        }),
      ).then((url) => {
        setProfilePicture(url);
      });
    }
  }, [targetId, profilePicture, dispatch]);

  return profilePicture;
};

export default useProfilePicture;
