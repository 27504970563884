// @flow
import * as React from 'react';
import { Card, Dialog } from '@mui/material';

type SubmittingDialogProps = {
  message: string;
  show?: boolean;
};

const SubmittingDialog: React.FC<SubmittingDialogProps> = ({
  message,
  show,
}) => {
  return (
    <Dialog
      open={Boolean(show)}
      classes={{
        container:
          'lg:ml-[60px] flex items-center sm:items-start pt-6 pl-[70px]',
      }}
    >
      <Card className="h-20 bg-white w-[300px] sm:w-[540px] flex-col-center h3 text-support-success">
        {message}
      </Card>
    </Dialog>
  );
};
export default React.memo(SubmittingDialog);
