import React from 'react';
import { Checkbox, Divider, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import useFriends from '@shared/components/make-post/components/select-friends/hooks/useFriends';
import Avatar from '@shared/components/avatar';
import { Size } from '../../../../../../models/constants';

const SelectFriends: React.FC<{
  onSubmit: (selectedFriends: any[]) => void;
}> = ({ onSubmit }) => {
  const [searchKey, setSearchKey] = React.useState('');

  const { friends, loading } = useFriends(searchKey);

  const [selectedFriends, setSelectedFriends] = React.useState([] as any);

  const renderFriends = React.useCallback(
    (friends, loading, selectedFriends) => {
      if (loading) return <div>Loading...</div>;
      if (!friends.length && !selectedFriends.length)
        return <div>No friends found</div>;

      return (!friends.length ? selectedFriends : friends).map((friend) => {
        const isSelected = selectedFriends.some(
          (selectedFriend) => selectedFriend.id === friend.id,
        );

        return (
          <IconButton
            key={friend.id}
            className="flex items-center w-full mb-3 rounded-none"
            onClick={() => {
              if (isSelected) {
                setSelectedFriends(
                  selectedFriends.filter(
                    (selectedFriend) => selectedFriend.id !== friend.id,
                  ),
                );
              } else {
                setSelectedFriends([...selectedFriends, friend]);
              }
            }}
          >
            <div className="mr-3 w-[40px] h-[40px]">
              <Avatar src={friend.avatar} size={Size.XSMALL} />
            </div>
            <h6 className="flex-1 text-left h6 text-on-primary">
              {friend.name}
            </h6>
            <Checkbox checked={isSelected} />
          </IconButton>
        );
      });
    },
    [],
  );

  return (
    <div className="flex flex-col w-full h-[420px]">
      <div className="text-on-primary h3 mb-[9px] pt-[16px]">
        Select friends
      </div>
      <TextField
        id="outlined-basic"
        // label="Outlined"
        placeholder="Search friends"
        variant="outlined"
        onChange={(e) => setSearchKey(e.target.value)}
        className="mb-3 !h-[34px] w-[292px]"
        InputProps={{
          className: 'h-[34px] !text-on-primary border-[#D6D9DC]',
          startAdornment: (
            <SearchIcon className="mr-3 -ml-1 text-on-secondary" />
          ),
        }}
      />
      <Divider className="-ml-[22px] w-[333px]" />
      <div className="flex-1 w-full pt-2 pb-2 overflow-auto max-h-[260px]">
        {renderFriends(friends, loading, selectedFriends)}
      </div>
      <Divider className="-ml-[22px] w-[333px]" />
      <div className="w-full h-[70px] flex-col-center">
        <Button
          disabled={!selectedFriends.length}
          className={`h-10 -ml-4 text-white w-[154px]
          ${
            selectedFriends.length &&
            'bg-brand-primary hover:bg-brand-secondary'
          }`}
          onClick={() => onSubmit(selectedFriends)}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SelectFriends;
