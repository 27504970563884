// @flow
import * as React from 'react';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import useAppDispatch from '@data/useAppDispatch';
import { fetchSupportGroups } from '@data/support-groups/supportGroup.thunk';
import GroupCard from '@shared/components/group-card/GroupCard';
import CreateGroupDialog from '@shared/components/create-group-dialog/CreateGroupDialog';
import useCreateGroup from '@shared/components/create-group-dialog/hooks/useCreateGroup';
import HighlightedTabs from '@shared/components/tabs/HighlightedTabs';
import SupportGroupSelector from '@data/support-groups/supportGroup.selector';
import { LoadingState } from '../../models/Loading';

const Hashes = {
  SUGGESTED: '#suggested',
  MyGroups: '#myGroups',
  HiddenGroups: '#hiddenGroups',
};

const SupportGroups: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState<LoadingState[]>([
    LoadingState.IDLE,
    LoadingState.IDLE,
    LoadingState.IDLE,
  ]);
  const suggestedGroups = useSelector(
    SupportGroupSelector.selectSuggestedGroups,
  );
  const myGroups = useSelector(SupportGroupSelector.selectMyGroups);
  const hiddenGroups = useSelector(SupportGroupSelector.selectHiddenGroups);
  const { hash } = useLocation();
  const createGroupProps = useCreateGroup('create');
  const navigate = useNavigate();

  React.useEffect(() => {
    if (loading[selectedTab] === LoadingState.IDLE) {
      setLoading((prevState) => {
        const newState = [...prevState];
        newState[selectedTab] = LoadingState.LOADING;
        return newState;
      });
      dispatch(fetchSupportGroups({ page: 1 }))
        .then(() => {
          setLoading((prevState) => {
            const newState = [...prevState];
            newState[selectedTab] = LoadingState.SUCCEEDED;
            return newState;
          });
        })
        .catch(() => {
          setLoading((prevState) => {
            const newState = [...prevState];
            newState[selectedTab] = LoadingState.FAILED;
            return newState;
          });
        });
    }
  }, [dispatch, loading, selectedTab]);

  React.useEffect(() => {
    if (hash === Hashes.MyGroups) setSelectedTab(1);
    else if (hash === Hashes.HiddenGroups) setSelectedTab(2);
    else setSelectedTab(0);
  }, [hash]);

  const changeTabs = React.useCallback(
    (tab: number) => {
      if (tab === 0) navigate(Hashes.SUGGESTED);
      else if (tab === 1) navigate(Hashes.MyGroups);
      else navigate(Hashes.HiddenGroups);
    },
    [navigate],
  );

  const tabs = ['SUGGESTED', 'MY GROUPS', 'HIDDEN GROUPS'];

  const renderGroups = React.useCallback(() => {
    return (
      <div className="flex flex-wrap items-start justify-between w-full h-auto pb-4">
        {
          // eslint-disable-next-line no-nested-ternary

          loading[selectedTab] !== LoadingState.IDLE &&
          loading[selectedTab] !== LoadingState.LOADING ? (
            // eslint-disable-next-line no-nested-ternary
            (selectedTab === 0
              ? [undefined, ...suggestedGroups]
              : selectedTab === 1
              ? myGroups
              : hiddenGroups
            ).map((group) => {
              return (
                <GroupCard
                  key={group ? group.id : 'empty-card'}
                  supportGroup={group}
                  isEmptyCard={!group}
                  onCreateGroup={createGroupProps.openDialog}
                />
              );
            })
          ) : (
            <>
              <GroupCard key="loading-1" loading />
              <GroupCard key="loading-2" loading />
              <GroupCard key="loading-3" loading />
            </>
          )
        }
      </div>
    );
  }, [
    loading,
    selectedTab,
    suggestedGroups,
    myGroups,
    hiddenGroups,
    createGroupProps.openDialog,
  ]);

  return (
    <WithSideBar>
      <CreateGroupDialog
        open={createGroupProps.open}
        mode={createGroupProps.mode}
        setCover={createGroupProps.setCover}
        onCancel={createGroupProps.onCancel}
        onSubmit={createGroupProps.onSubmit}
        setTitle={createGroupProps.setTitle}
        setDescription={createGroupProps.setDescription}
        loading={createGroupProps.loading}
        isValid={createGroupProps.isValid}
        cover={createGroupProps.cover}
        title={createGroupProps.title}
        description={createGroupProps.description}
      />
      <div className="flex justify-center w-full h-full pl-4 pr-4">
        <div className="w-full h-full pt-5 max-w-[870px]">
          <p className="mb-3 h3 text-on-primary">{t(TextKeys.SupportGroups)}</p>
          <p className="mb-4 text-on-secondary body1 max-w-[664px] leading-4">
            {t(TextKeys.SupportGroupsDescription)}
          </p>
          <HighlightedTabs
            tabs={tabs}
            onTabChanged={changeTabs}
            selectedTab={selectedTab}
          />
          {renderGroups()}
        </div>
      </div>
    </WithSideBar>
  );
};

export default SupportGroups;
