import React, { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useLoading } from '../use-loading/useLoading/useLoading';
import { LoadingType } from '../use-loading/useLoading/components/Loading';

type buttonProps = {
  label: string;
  action?: (...t) => void;
};

const UseConfirmModal = ({
  title,
  description,
  approveButton,
  rejectButton,
  hasLoading = false,
}: {
  title: string;
  description: string;
  approveButton: buttonProps;
  rejectButton: buttonProps;
  hasLoading?: boolean;
}) => {
  const [show, setShow] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { LoadingComponent, isLoading, loadingId } = useLoading();
  const [props, setProps] = useState<unknown>({});

  const showModal = useCallback(
    (...t) => {
      setShow(true);
      setProps(t);
    },
    [setProps, setShow],
  );

  const hideModal = useCallback(() => {
    setShow(hasLoading && isLoading);
  }, [hasLoading, isLoading]);

  const onRejectClicked = useCallback(() => {
    if (rejectButton.action) rejectButton.action();
    hideModal();
  }, [hideModal, rejectButton]);

  const onApproveClicked = useCallback(() => {
    if (approveButton.action) approveButton.action(props);
  }, [approveButton, props]);

  const confirmationModal = useMemo(() => {
    const Modal: React.FC = () => {
      return (
        <Dialog
          fullScreen={fullScreen}
          open={show}
          onClose={hideModal}
          aria-labelledby="responsive-dialog-title"
          className="pl-[70px]"
        >
          <DialogTitle
            className="h4 text-brand-primary"
            id="responsive-dialog-title"
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="w-full h-full body1 text-primary">
                {description}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="w-full">
            <div className="flex items-center justify-center w-full h-full">
              <Button
                disabled={hasLoading && isLoading}
                color="primary"
                variant="outlined"
                className="mr-2 w-36"
                onClick={onRejectClicked}
              >
                {rejectButton.label}
              </Button>
              <Button
                disabled={hasLoading && isLoading}
                className="ml-2 w-36"
                onClick={onApproveClicked}
                variant="contained"
                color="primary"
              >
                {hasLoading && isLoading ? (
                  <LoadingComponent type={LoadingType.SPINNER} />
                ) : (
                  `${approveButton.label}`
                )}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      );
    };
    return Modal;
  }, [
    fullScreen,
    show,
    hideModal,
    title,
    description,
    hasLoading,
    isLoading,
    onRejectClicked,
    rejectButton.label,
    onApproveClicked,
    LoadingComponent,
    approveButton.label,
  ]);

  return [confirmationModal, showModal, hideModal, loadingId] as [
    () => JSX.Element,
    (...t) => void,
    () => void,
    number,
  ];
};

export default UseConfirmModal;
