import createAsyncThunkWithAuth from '@data/createAsyncThunkWithAuth';
import axios from 'axios';
import { ApiEndpoint } from '@utils/types';

export const fetchMyCircle = createAsyncThunkWithAuth<{ page: number }>(
  'my-circle/fetch',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.GET_FRIENDS, {
            user: args.userId,
            page: args.page,
          }),
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch(reject);
    });
  },
);

export const fetchFriendsBySearch = createAsyncThunkWithAuth<{ search }>(
  'my-circle/fetch-by-search',
  async (args, urlGenerator) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          urlGenerator(ApiEndpoint.SEARCH_FRIEND, {
            user: args.userId,
          }),
          {
            params: {
              query: args.search,
            },
          },
        )
        .then((result) => resolve(result.data))
        .catch(reject);
    });
  },
);
