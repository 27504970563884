import { useCallback, useEffect, useState } from 'react';
import ResizeEngine, { SizeProps } from '@hooks/use-breakpoint/resize';
import breakpoints from '../../models/constants/breakpoints';

/**
 * Use Breakpoint Hook
 *
 * @returns {SizeProps} object with breakpoint and viewport dimensions value
 */
const useBreakpoint = (): SizeProps => {
  const [breakpoint, setBreakpoint] = useState(breakpoints.name.XSMALL);
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  });

  const onResize = useCallback(
    ({ breakpoint: bp, dimensions: d }: any) => {
      setBreakpoint(bp);
      setDimensions(d);
    },
    [setBreakpoint, setDimensions],
  );

  useEffect(() => {
    const { breakpoint: initialBreakpoint, dimensions: initialDimensions } =
      ResizeEngine.getProps();

    setBreakpoint(initialBreakpoint);
    setDimensions(initialDimensions);

    const key = ResizeEngine.subscribe(onResize);

    return () => {
      if (key) {
        ResizeEngine.unsubscribe(key);
      }
    };
  }, [onResize]);

  return { breakpoint, dimensions };
};

export default useBreakpoint;
