// @flow
import * as React from 'react';
import { ReactComponent as AddImageSVG } from '@assets/icons/add_image.svg';
import { useFilePicker } from 'use-file-picker';
import { Button, Dialog } from '@mui/material';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../../utils/crop';

type ImageCropUploadProps = {
  setImage: (image?: string) => void;
  height?: number;
  width?: number;
  defaultImage?: string;
  placeholder?: (onClick) => React.ReactNode;
};

const ImageCropUpload: React.FC<ImageCropUploadProps> = ({
  setImage,
  height = 180,
  width = 540,
  defaultImage,
  placeholder,
}) => {
  const [openFileSelector, { plainFiles, filesContent }] = useFilePicker({
    accept: 'image/jpg, image/jpeg, image/png',
    readAs: 'DataURL',
  });

  const [image, _setImage] = React.useState<string | undefined>(defaultImage);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [cropping, setCropping] = React.useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState({
    x: 0,
    y: 0,
  });
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });

  React.useEffect(() => {
    if (plainFiles?.length > 0) {
      plainFiles?.map((file, i) => _setImage(filesContent[i].content));
      setOpenDialog(true);
    }
  }, [plainFiles, filesContent]);

  React.useEffect(() => {
    setImage(image);
    if (!image) {
      setOpenDialog(false);
    }
  }, [image, setImage]);

  const renderImage = () => {
    return <img className="object-cover w-full h-full" src={image} alt="" />;
  };

  const renderPlaceHolder = () => {
    return (
      <div
        className="w-full h-full cursor-pointer flex-col-center"
        onClick={openFileSelector}
      >
        {image ? renderImage() : <AddImageSVG className="h-[106px]" />}
      </div>
    );
  };

  const setCroppedImage = React.useCallback(async () => {
    try {
      setCropping(true);
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
      _setImage(croppedImage);
      setCropping(false);
      setOpenDialog(false);
    } catch (e) {
      setCropping(false);
    }
  }, [image, croppedAreaPixels, setOpenDialog]);

  const renderCropDialog = () => {
    return (
      <Dialog
        open={openDialog}
        classes={{
          paper: 'w-full m-0',
        }}
      >
        <div className="w-full pt-8 pb-5 h-[415px]">
          <div className="flex justify-between w-full pb-4 pl-8 pr-6 h2">
            Drag to Adjust
            <Button
              className="tags text-on-secondary"
              variant="text"
              color="error"
              onClick={() => setImage(undefined)}
              disabled={cropping}
            >
              REMOVE IMAGE
            </Button>
          </div>
          <div className="relative w-full pl-8 pr-8 m-0 sm:w-[590px] h-[255px]">
            <Cropper
              image={image}
              crop={crop}
              zoom={1}
              aspect={width / height}
              onCropChange={setCrop}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                setCroppedAreaPixels(croppedAreaPixels)
              }
              style={{
                mediaStyle: {
                  objectFit: 'cover',
                },
              }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center w-full pb-[24px]">
          <Button
            disabled={cropping}
            className="!ml-2 !mr-2 w-[130px] h-[40px]"
            onClick={() => _setImage(undefined)}
            variant="outlined"
            color="primary"
          >
            CANCEL
          </Button>
          <Button
            onClick={setCroppedImage}
            disabled={cropping}
            className="!ml-2 !mr-2 w-[130px] h-[40px]"
            variant="contained"
            color="primary"
          >
            SAVE
          </Button>
        </div>
      </Dialog>
    );
  };

  if (placeholder)
    return (
      <>
        {renderCropDialog()}
        {placeholder(openFileSelector)}
      </>
    );

  return (
    <div className={`w-full h-[${height}px] banner-gradiant`}>
      {renderCropDialog()}
      {renderPlaceHolder()}
    </div>
  );
};

export default ImageCropUpload;
