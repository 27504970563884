/**
 * Drafted Posts View
 */
import React from 'react';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import { fetchDrafts } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import Timeline from '@shared/components/timeline/Timeline';
import { TimeLines } from '@data/timeline/timeline.model';
import { AsyncThunk } from '@reduxjs/toolkit';
import { MakePostDialogOpeningModeAction } from '@shared/components/make-post/models/MakePostAction';
import MakePostDialogProvider from '@shared/components/make-post/MakePostDialogProvider';

const ScheduledPosts: React.FC<
  React.PropsWithChildren<React.PropsWithChildren>
> = () => {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] =
    React.useState<MakePostDialogOpeningModeAction>({
      mode: 'close',
    });

  const handleEditPost = (feedId) => () => {
    setOpenDialog({ mode: 'open', id: feedId });
  };

  const handleCloseMakePostDialog = React.useCallback(
    () => () => {
      setOpenDialog({ mode: 'close' });
    },
    [],
  );

  return (
    <WithSideBar>
      <span className="w-full mt-5 mb-4 h3 flex-col-center">
        {t(TextKeys.MyDrafts)}
      </span>
      <div className="flex justify-center w-full pb-4">
        <Card className="w-full h-full max-w-[540px]">
          <Timeline
            timelineFetcher={
              fetchDrafts as unknown as AsyncThunk<
                never,
                { page: number },
                never
              >
            }
            handleEditPost={handleEditPost}
            selector={TimelineSelectors.drafted.selectIds}
            hasMoreSelector={TimelineSelectors.drafted.hasMoreSelector}
            pageSelector={TimelineSelectors.drafted.pageSelector}
            timeline={TimeLines.DRAFTED}
            emptyListMessage={t(TextKeys.NoDrafts)}
          >
            <MakePostDialogProvider
              open={openDialog}
              closeDialog={handleCloseMakePostDialog()}
            />
          </Timeline>
        </Card>
      </div>
    </WithSideBar>
  );
};

export default ScheduledPosts;
