/* eslint-disable no-empty */
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { Dispatch } from 'redux';
import { path } from 'ramda';
// TODO change once dependencies are resolved
import moment from 'moment';
import { logoutUser } from '@data/app/app.thunk';
import * as Sentry from '@sentry/react';

/**
 * Initialize Axios Interceptors
 *
 * @param dispatch
 * @param getState
 */
function initAxiosInterceptors(
  dispatch: Dispatch,
  getState: () => Record<string, unknown>,
): void {
  // set interceptor to use auth information available in the store
  axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const newConfig: InternalAxiosRequestConfig = config;

    const expiry: string | undefined = path(
      ['app', 'auth', 'tokenExpiry'],
      getState(),
    );
    // authentication_token
    if (
      (!expiry || moment(expiry).isBefore(Date.now())) &&
      !(
        config.url?.match('init') ||
        config.url?.match('encrypt') ||
        config.url?.match('apps') ||
        config.url?.match('login')
      )
    ) {
      // dispatch(authActions.fetchAuth());
      // TODO: add auth action
    }

    try {
      const token = path(['app', 'auth', 'appToken'], getState());
      if (token && config.url?.match('api.embed.rocks') === null) {
        // @ts-ignore
        newConfig.headers.x_curatio_api_key = `Bearer ${token}`;
      }
    } catch (error) {
      /** @todo add sentry error logging here */
    }

    try {
      const authToken = path(['app', 'auth', 'authToken'], getState());
      if (
        newConfig &&
        newConfig.url &&
        (newConfig.url.includes('curatio') ||
          newConfig.url.includes('profile-pic'))
      ) {
        // @ts-ignore
        newConfig.headers.x_curatio_authentication_token = authToken;
      }
    } catch (e) {}

    return newConfig;
  });

  axios.interceptors.response.use(
    async (response: AxiosResponse) => {
      const originalRequest: InternalAxiosRequestConfig & {
        _retry?: boolean;
      } = response.config;
      // const body = {};
      // if (originalRequest.data instanceof FormData)
      //   body = Object.fromEntries(originalRequest.data);
      // else body = originalRequest.data;

      // if (response.data.error && response.data.error.code !== 4002) {
      //     Sentry.setContext('params', originalRequest.params);
      //     Sentry.setContext('data', body);
      //     Sentry.setContext('description', {
      //         description: JSON.stringify(response.data.error.description).substr(
      //             1,
      //             700,
      //         ),
      //     });
      //     Sentry.captureException(new Error(response.data.error.message), {});
      // }

      if (response.data.error) {
        Sentry.captureException(response.data.error);
      }

      if (
        response.data.error &&
        // eslint-disable-next-line no-underscore-dangle
        !originalRequest._retry &&
        response.data.error.code === 4002 &&
        !response.config.url?.match('login')
      ) {
        // @ts-ignore
        dispatch(logoutUser());
        // originalRequest._retry = true;
        // if (originalRequest.params) {
        //     const refresh_token = path(
        //         ['app', 'auth', 'refreshToken'],
        //         getState(),
        //     );
        //     const device_id = path(
        //         ['app', 'auth', 'deviceId'],
        //         getState(),
        //     );
        //     const user_id: string = path(
        //         ['app', 'user', 'id'],
        //         getState(),
        //     ) as string;
        //     const br = browserName.replace(/ /g, '');
        //     const client_id = `${config.platform}-${config.version}-${br}-${fullBrowserVersion}`;
        //     if (refresh_token && user_id) {
        //         try {
        //             const ref = await axios.post(
        //                 generateApiRoute(ApiEndpoint.USER_REFRESH_TOKEN, {user_id}),
        //                 {
        //                     device_id,
        //                     client_id,
        //                     refresh_token,
        //                 },
        //             );
        //
        //             if (ref.data.user) {
        //                 // dispatch(userActions.setUserData(ref.data.user));
        //
        //                 originalRequest.params.authentication_token =
        //                     ref.data.user.authentication_token;
        //
        //                 originalRequest.params.refresh_token =
        //                     ref.data.user.refresh_token;
        //
        //                 return await axios.request(originalRequest);
        //             }
        //             // @ts-ignore
        //             // dispatch(logoutUser());
        //         } catch (error) {
        //             // @ts-ignore
        //             // dispatch(logoutUser());
        //         }
        //     } else {
        //         // @ts-ignore
        //         // dispatch(logoutUser());
        //     }
        // } else {
        //     // @ts-ignore
        //     dispatch(logoutUser());
        // }
      }
      // Handle errors within Redux sagas instead
      if (response.data && response.data.error) {
        const { error } = response.data;
        // error code 4000 gives custom response from API handle it here
        return Promise.reject(new Error(JSON.stringify(error)));
      }

      return response;
    },
    (error: AxiosError) => {
      // check for ability to redo calls in axios documentation
      Sentry.captureException(error);
      if (
        (error.response && error.response.status === 401) ||
        (error.response && error.response.status === 403)
      ) {
        // check auth token expire date
        // check if user is still logged In
      }
      // call the original request again
    },
  );
}

export const configureAxios = ({ store }) => {
  return initAxiosInterceptors(store.dispatch, store.getState);
};
