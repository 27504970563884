import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import { AppSelector } from '@data/app/app.selector';
import Avatar from '@shared/components/avatar';
import ChangePassword from './ChangePassword';

const Setting: React.FC<unknown> = () => {
  const [activeTab, setActiveTab] = useState(0);
  const avatarUrl = useSelector(AppSelector.selectUserInfo)?.avatar;

  const tabs = [
    {
      label: 'Change Password',
    },
  ];

  const renderChangePassword = () => {
    return (
      <div className="flex items-start justify-center w-full h-full mt-8">
        <div className="flex items-start justify-center w-11/12 w-full overflow-auto bg-white max-w-screen-sm">
          <ChangePassword />
        </div>
      </div>
    );
  };

  return (
    <WithSideBar>
      <div className="w-full h-full">
        <div className="w-full h-48 bg-brand-primary bg-setting-header flex-col-center">
          <Avatar src={avatarUrl} className="w-24 h-24" />
          <div className="relative w-11/12 h-12 bg-white max-w-screen-sm -bottom-6 rounded-tl-3xl rounded-tr-3xl flex-col-center">
            {tabs.map((t, i) => (
              <div key={`tab-${t}`}>
                <div
                  className={`h-full flex-col-center cursor-pointer
                                     ${
                                       i === activeTab
                                         ? 'font-bold h5'
                                         : 'subtitle1'
                                     }
                                        `}
                  onClick={() => setActiveTab(i)}
                  role="button"
                  tabIndex={0}
                >
                  {t.label}
                </div>
                {i === activeTab && (
                  <div className="relative w-full h-1 bg-brand-primary -bottom-2 rounded-tr-3xl rounded-tl-3xl flex-col-center" />
                )}
              </div>
            ))}
          </div>
        </div>
        {activeTab === 0 ? renderChangePassword() : null}
      </div>
    </WithSideBar>
  );
};

export default Setting;
