import { createSelector } from '@reduxjs/toolkit';
import { Dictionary } from '@reduxjs/toolkit/src/entities/models';
import { contentAdapter, tagAdapter } from './Contents.reducer';
import { Content, ContentTypes } from './Contents.model';

export const ContentsSelectors = {
  contents: {
    ...contentAdapter.getSelectors((state: any) => state.contents),
    selectArticles: createSelector(
      (state: any) => state.contents.contents.entities,
      (contents) => {
        return Object.fromEntries(
          Object.entries(contents as Dictionary<Content>).filter(
            ([_, v]) =>
              v?.type?.toUpperCase() === ContentTypes.ARTICLE.toUpperCase(),
          ),
        );
      },
    ),
    selectPublishedContentsByCategory: createSelector(
      [
        (state: any) => state.contents.contents.entities,
        (_, sortBy) => sortBy,
        (_, _2, bookmarked) => bookmarked,
      ],
      (
        contents,
        sortBy: 'latest' | 'most-viewed' | 'most-liked',
        bookmarked,
      ) => {
        return Object.entries(contents as Dictionary<Content>)
          .filter(
            ([_, v]) =>
              v?.status?.toUpperCase() === 'PUBLISHED' &&
              (!bookmarked || v?.bookmarked === bookmarked),
          )
          .map(([_, v]) => v)
          .sort((a, b) => {
            if (sortBy === 'latest')
              return (
                new Date(b?.createdAt as string).getTime() -
                new Date(a?.createdAt as string).getTime()
              );
            if (sortBy === 'most-viewed')
              return (
                b?.interaction?.numberOfViews - a?.interaction?.numberOfViews
              );
            if (sortBy === 'most-liked')
              return (
                b?.interaction?.numberOfLikes - a?.interaction?.numberOfLikes
              );
            return 0;
          })
          .reduce((acc: Dictionary<Content[]>, cur: Content) => {
            const { category } = cur;
            acc[category] = acc[category] ?? [];
            acc[category]?.push(cur);
            return acc;
          }, {});
      },
    ),
  },
  categories: {
    ...tagAdapter.getSelectors((state: any) => state.categories),
  },
  tags: {
    ...tagAdapter.getSelectors((state: any) => state.tags),
  },
};
