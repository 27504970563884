import { useDispatch } from 'react-redux';
import React from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import HDivider from '@shared/components/divider/HDivider';
import Button from '@mui/material/Button';

type changePasswordCredential = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  currentPasswordError: boolean;
  newPasswordError: boolean;
  confirmPasswordError: boolean;
};

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();

  const [values, setValues] = React.useState<changePasswordCredential>({
    confirmPassword: '',
    newPassword: '',
    currentPassword: '',
    showCurrentPassword: false,
    showNewPassword: false,
    currentPasswordError: false,
    newPasswordError: false,
    confirmPasswordError: false,
  });

  const handleChange =
    (prop: keyof changePasswordCredential) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword =
    (prop: 'showCurrentPassword' | 'showNewPassword') => () => {
      setValues({
        ...values,
        [prop]: !values[prop],
      });
    };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleForgotPasswordClick = () => {
    window.open(`/forgot-password`);
  };

  const onsubmit = () => {
    const currentPasswordError = values.currentPassword === '';
    const newPasswordError =
      values.newPassword.length < 8 ||
      values.newPassword.search(/[a-z]/) < 0 ||
      values.newPassword.search(/[A-Z]/) < 0 ||
      values.newPassword.search(/\d/) < 0;
    const confirmPasswordError =
      values.confirmPassword === '' ||
      values.confirmPassword !== values.newPassword;

    setValues({
      ...values,
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
    });

    if (!currentPasswordError && !newPasswordError && !confirmPasswordError) {
      // dispatch(
      //     userActions.changePassword(values.currentPassword, values.newPassword),
      // );
      // TODO: dispatch change password action
    }
  };

  return (
    <div className="w-full flex-col-center">
      <div className="w-full mt-5 h5 flex-col-center">
        Update Password Information
      </div>
      <div className="w-full mt-10 flex-col-center">
        <FormControl
          className="w-9/12"
          variant="outlined"
          error={values.currentPasswordError}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Current password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showCurrentPassword ? 'text' : 'password'}
            value={values.currentPassword}
            onChange={handleChange('currentPassword')}
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword('showCurrentPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showCurrentPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
      </div>

      <HDivider className="w-11/12 mt-5" />

      <div className="w-full mt-5 flex-col-center">
        <FormControl
          className="w-9/12"
          variant="outlined"
          error={values.newPasswordError}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            New password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showNewPassword ? 'text' : 'password'}
            value={values.newPassword}
            onChange={handleChange('newPassword')}
            aria-describedby="outlined-weight-helper-text"
            required
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword('showNewPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="New password"
          />
          <FormHelperText id="outlined-weight-helper-text">
            Min. 8 characters, 1 uppercase letter, 1 lowercase letter.
          </FormHelperText>
        </FormControl>
      </div>

      <div className="w-full mt-5 flex-col-center">
        <FormControl
          className="w-9/12"
          variant="outlined"
          error={values.confirmPasswordError}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Confrim password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type="password"
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            label="Confirm password"
            required
          />
        </FormControl>
      </div>

      <button
        onClick={handleForgotPasswordClick}
        className="font-bold cursor-pointer text-link mt-9 h5"
        type="button"
      >
        Forgot your password?
      </button>
      <div className="flex justify-center w-full  mt-9 align-center">
        <Button
          variant="contained"
          color="primary"
          className="w-40 h-10 mb-5"
          onClick={onsubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ChangePassword;
