// @flow
import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { submitSupportGroupReviews } from '@data/support-groups/supportGroup.thunk';
import { ReviewSupportGroupsContext } from '../context/ReviewSupportGroupsContext';
import { useSnackbar } from 'notistack';

type useReviewSupportGroupsType = {
  SupportGroupReviewProvider: React.FC<PropsWithChildren>;
  reviews: { status: 'approved' | 'rejected'; id: number; comment?: string }[];
  onSubmit: () => () => void;
  onReset: () => void;
  submitting: boolean;
  isDone: boolean;
  isValid: boolean;
};

const useReviewSupportGroups = (): useReviewSupportGroupsType => {
  const [reviews, setReviews] = React.useState<
    { status: 'approved' | 'rejected'; id: number; comment?: string }[]
  >([]);
  const [review, setReview] = React.useState<
    | {
        status: 'approved' | 'rejected' | 'remove';
        id: number;
        comment?: string;
      }
    | undefined
  >(undefined);
  const [status, setStatus] = React.useState<
    'submitting' | 'pending' | 'done' | 'reviewing'
  >('submitting');
  const [isLoading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const onApprove = React.useCallback(
    (id: number) => {
      setReview({ status: 'approved', id });
    },
    [setReview],
  );

  const isValid = useMemo(() => {
    if (reviews.length === 0) {
      return false;
    }
    console.log(reviews);
    return reviews.every((r) => r.status !== 'rejected' || r.comment !== '');
  }, [reviews]);

  const onReject = React.useCallback(
    (id: number, comment: string) => {
      setReview({ status: 'rejected', id, comment });
    },
    [setReview],
  );

  React.useEffect(() => {
    if (review) {
      if (review.status === 'remove') {
        setReviews(reviews.filter((r) => r.id !== review.id));
      } else {
        setReviews([
          ...reviews.filter((r) => r.id !== review.id),
          review as {
            status: 'approved' | 'rejected';
            id: number;
            comment?: string;
          },
        ]);
      }
      setReview(undefined);
    }
  }, [review, reviews]);

  React.useEffect(() => {
    if (status === 'submitting') {
      setLoading(true);
      setStatus('pending');
      dispatch(submitSupportGroupReviews({ reviews })).then((results) => {
        const errors = results
          .filter((r) => r.status === 'rejected')
          .map((r) => {
            return JSON.parse(r.reason.message).message;
          })
          .join(', ');
        if (errors) {
          enqueueSnackbar(errors, { variant: 'error' });
        }

        setLoading(false);
        setStatus('done');
      });
    }
    if (status === 'done') {
      setReviews([]);
      setStatus('reviewing');
    }
  }, [dispatch, reviews, status, setStatus, setLoading, enqueueSnackbar]);

  const supportGroupReviewValue = React.useMemo(
    () => ({
      onApprove,
      onReject,
    }),
    [onApprove, onReject],
  );

  const SupportGroupReviewProvider = React.useMemo(() => {
    const Component: React.FC<PropsWithChildren> = ({ children }) => (
      <ReviewSupportGroupsContext.Provider value={supportGroupReviewValue}>
        {children}
      </ReviewSupportGroupsContext.Provider>
    );

    return Component;
  }, [supportGroupReviewValue]);

  return {
    SupportGroupReviewProvider,
    reviews,
    onSubmit: () => () => setStatus('submitting'),
    onReset: () => {
      console.log('reset');
      setStatus('reviewing');
      setReviews([]);
    },
    submitting: isLoading,
    isDone: status === 'done',
    isValid,
  };
};

export default useReviewSupportGroups;
