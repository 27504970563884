import GroupedDropDownMenu from '@shared/components/dropDown-menu/GroupedDropDownMenu';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useTranslation } from 'react-i18next';
import { GroupedDropDownItems } from '@shared/components/dropDown-menu/models/DropDownItems';
import PublicIcon from '@mui/icons-material/Public';
// import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import useGroups from '@shared/components/make-post/hooks/useGroups';
import React from 'react';
import { ReactComponent as ShareCircleSvg } from '@assets/icons/share_circle.svg';
import { ReactComponent as GroupSvg } from '@assets/icons/group.svg';
import {
  EventDraftAction,
  EventDraftContext,
} from '@view/create-event/hooks/useDraftEvent';
import { FeedSharingOption } from '../../../models/Feed';

const CreateEventSharingOptions: React.FC<unknown> = () => {
  const { t } = useTranslation();

  const defaultSharingOptions: GroupedDropDownItems<FeedSharingOption> = {
    unassigned: {
      [FeedSharingOption.PUBLIC]: {
        label: 'Community',
        key: FeedSharingOption.PUBLIC,
        Icon: <PublicIcon className="flex-1" />,
      },
      [FeedSharingOption.CIRCLE]: {
        label: 'My circle',
        key: FeedSharingOption.CIRCLE,
        Icon: <ShareCircleSvg className="flex-1 h-5 fill-on-secondary" />,
      },
      // [FeedSharingOption.PRIVATE]: {
      //   label: 'Select friends',
      //   key: FeedSharingOption.PRIVATE,
      //   Icon: <PeopleAltRoundedIcon className="flex-1 h-5 fill-on-secondary" />,
      // },
    },
  };

  const [sharingOptions, setSharingOptions] = React.useState<
    GroupedDropDownItems<FeedSharingOption | number>
  >(defaultSharingOptions);

  const myGroups = useGroups();

  React.useEffect(() => {
    if (myGroups && myGroups.length > 0) {
      setSharingOptions((prev) => ({
        ...prev,
        'My Groups': myGroups.reduce((groups, group) => {
          return {
            ...groups,
            [group.channelId]: {
              label: group.info.title,
              key: group.channelId,
              Icon: <GroupSvg className="flex-1 h-5 fill-on-secondary" />,
            },
          };
        }, {}),
      }));
    }
  }, [myGroups]);

  const [shareWith, setShareWith] = React.useState<string>(
    `unassigned-${FeedSharingOption.PUBLIC}`,
  );

  const getSharingOptionKey = React.useCallback(
    (selectedSharingOption) => {
      try {
        const parent = selectedSharingOption?.split('-')[0];
        const child = selectedSharingOption?.split('-')[1];
        return sharingOptions[parent][child]?.key;
      } catch (e) {
        return '';
      }
    },
    [sharingOptions],
  );

  const draftContext = React.useContext(EventDraftContext) as EventDraftAction;

  React.useEffect(() => {
    const shareWithKey = getSharingOptionKey(shareWith);
    if (
      [FeedSharingOption.PUBLIC, FeedSharingOption.CIRCLE].includes(
        shareWithKey as FeedSharingOption,
      )
    ) {
      draftContext.setShareWith(shareWithKey as FeedSharingOption);
    } else {
      draftContext.setShareWith(FeedSharingOption.CHANNEL);
      draftContext.setChannelId(shareWithKey as string);
    }
  }, [draftContext, getSharingOptionKey, shareWith]);

  return (
    <GroupedDropDownMenu
      title={t(TextKeys.WhoCanSeeThePost)}
      items={sharingOptions}
      selected={shareWith}
      onSelect={setShareWith}
      // disabled={editMode}
    />
  );
};

export default CreateEventSharingOptions;
