// @flow
import * as React from 'react';
import { Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useSelector } from 'react-redux';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import Rescroller from '@shared/components/rescroller/Rescroller';
import { MyCircleSelector } from '@data/my-circle/MyCircle.selector';
import { fetchMyCircle } from '@data/my-circle/MyCircle.thunk';
import useAppDispatch from '@data/useAppDispatch';
import MyFriendItem from './components/MyFriendItem';

const MyCircle: React.FC<any> = () => {
  const { t } = useTranslation();

  const [page, setPage] = React.useState(-1);

  const numberOfFriends = useSelector(MyCircleSelector.selectTotalFriends);
  const numberOfOnlineFriends = useSelector(
    MyCircleSelector.onlineFriendsSelector,
  );
  const friends = useSelector(MyCircleSelector.selectIds);
  const scroller = React.useRef<HTMLDivElement>(null);
  const hasMore = useSelector(MyCircleSelector.hasMoreSelector);
  const lastLoadedPage = useSelector(MyCircleSelector.pageSelector);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setPage(Math.max(lastLoadedPage, 1));
  }, [lastLoadedPage]);

  React.useEffect(() => {
    if (page > 0) dispatch(fetchMyCircle({ page }));
  }, [dispatch, page]);

  const renderHeader = () => {
    return (
      <>
        <div className="flex items-center justify-between w-full pl-4 pr-5 h-[40px] h6 text-on-primary">
          <h1>
            {t(TextKeys.MyCircleOfFriends)}{' '}
            {numberOfFriends > 0 && `(${numberOfFriends})`}
          </h1>
          <span className="subtitle2 text-success">
            {numberOfOnlineFriends.length} online
          </span>
        </div>
        <div className="w-full h-[1px] bg-secondary-background" />
      </>
    );
  };

  const renderRow = React.useCallback(
    (i: number) => {
      return (
        <MyFriendItem key={`friend-${friends[i]}`} friendId={friends[i]} />
      );
    },
    [friends],
  );

  const fetchNextPage = React.useCallback(() => {
    setPage(lastLoadedPage + 1);
  }, [lastLoadedPage]);

  return (
    <WithSideBar>
      <div className="w-full h-full pt-[40px] pb-[75px] flex-col-center">
        <Card className="flex flex-col w-full h-full mt-5 max-w-[540px]">
          {renderHeader()}
          <div className="w-full h-full overflow-auto" ref={scroller}>
            <Rescroller
              renderer={renderRow}
              scroller={scroller}
              itemsCount={friends.length}
              hasMore={hasMore}
              loadMore={fetchNextPage}
            />
          </div>
        </Card>
      </div>
    </WithSideBar>
  );
};
export default MyCircle;
