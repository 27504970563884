// @flow
import * as React from 'react';
import { useParams } from 'react-router-dom';
import GroupEventDetailsView from '@shared/templates/group-event-view/GroupEventDetailsView';
import useAppDispatch from '@data/useAppDispatch';
import {
  fetchSupportGroup,
  fetchSupportGroupMembers,
} from '@data/support-groups/supportGroup.thunk';
import useSupportGroupDetails from '@shared/templates/group-event-view/hooks/useSupportGroupDetails';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import { LoadingState } from '../../models/Loading';

const SupportGroupDetails: React.FC<unknown> = () => {
  const { groupId } = useParams();
  const supportGroupDetails = useSupportGroupDetails(groupId || '');

  const [membersLoaded, setMembersLoaded] = React.useState(false);
  const userId = useSelector(AppSelector.selectUserId);
  const dispatch = useAppDispatch();

  const [loadGroup, setLoadGroup] = React.useState<LoadingState>(
    LoadingState.IDLE,
  );

  React.useEffect(() => {
    if (loadGroup === LoadingState.IDLE && groupId) {
      dispatch(fetchSupportGroup({ groupId, userId }))
        .then(() => {
          setLoadGroup(LoadingState.SUCCEEDED);
        })
        .catch(() => {
          setLoadGroup(LoadingState.FAILED);
        });
    }
  }, [loadGroup, groupId, dispatch, userId]);

  React.useEffect(() => {
    if (
      !membersLoaded &&
      supportGroupDetails.numberOfMembers &&
      groupId &&
      supportGroupDetails.userIsJoined
    ) {
      dispatch(
        fetchSupportGroupMembers({
          groupId,
          numberOfMembers: supportGroupDetails.numberOfMembers,
        }),
      ).finally(() => {
        setMembersLoaded(true);
      });
    }
  }, [
    membersLoaded,
    supportGroupDetails.numberOfMembers,
    groupId,
    dispatch,
    supportGroupDetails.userIsJoined,
  ]);

  const loading = React.useMemo(
    () => loadGroup === LoadingState.LOADING || loadGroup === LoadingState.IDLE,
    [loadGroup],
  );

  return (
    <GroupEventDetailsView
      type="group"
      title={supportGroupDetails.title}
      cover={supportGroupDetails.cover}
      description={supportGroupDetails.description}
      onJoin={supportGroupDetails.onJoin}
      onLeave={supportGroupDetails.onLeave}
      admins={supportGroupDetails.admins}
      loading={loading}
      members={supportGroupDetails.members}
      numberOfMembers={supportGroupDetails.numberOfMembers}
      userIsJoined={supportGroupDetails.userIsJoined}
      onLoading={supportGroupDetails.onLoading}
    />
  );
};
export default SupportGroupDetails;
