/**
 * Bookmarked Posts View
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import { fetchBookmarkedPosts } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import Timeline from '@shared/components/timeline/Timeline';
import { TimeLines } from '@data/timeline/timeline.model';
import { AsyncThunk } from '@reduxjs/toolkit';
import MakePostDialogProvider from '@shared/components/make-post/MakePostDialogProvider';
import { MakePostDialogOpeningModeAction } from '@shared/components/make-post/models/MakePostAction';

const BookmarkedPosts: React.FC<
  React.PropsWithChildren<React.PropsWithChildren>
> = () => {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] =
    React.useState<MakePostDialogOpeningModeAction>({
      mode: 'close',
    });

  const handleEditPost = (feedId) => () => {
    setOpenDialog({ mode: 'open', id: `p-${feedId}` });
  };

  const handleCloseMakePostDialog = React.useCallback(
    () => () => {
      setOpenDialog({ mode: 'close' });
    },
    [],
  );

  return (
    <WithSideBar>
      <span className="w-full mt-5 mb-4 h3 flex-col-center">
        {t(TextKeys.MyBookmarks)}
      </span>
      <div className="flex justify-center w-full pb-4">
        <Timeline
          timelineFetcher={
            fetchBookmarkedPosts as unknown as AsyncThunk<
              never,
              { page: number },
              never
            >
          }
          handleEditPost={handleEditPost}
          selector={TimelineSelectors.bookmarked.selectIds}
          hasMoreSelector={TimelineSelectors.bookmarked.hasMoreSelector}
          pageSelector={TimelineSelectors.bookmarked.pageSelector}
          timeline={TimeLines.BOOKMARKS}
          emptyListMessage={t(TextKeys.NoBookmarks)}
        >
          <MakePostDialogProvider
            open={openDialog}
            closeDialog={handleCloseMakePostDialog()}
          />
        </Timeline>
      </div>
    </WithSideBar>
  );
};

export default BookmarkedPosts;
