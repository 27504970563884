import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  joinSupportGroup,
  leaveSupportGroup,
} from '@data/support-groups/supportGroup.thunk';
import useAppDispatch from '@data/useAppDispatch';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';
import { ButtonPriority } from '../../../../../models/constants';

const useGroupCardActions = (
  groupId,
  isEmptyCard,
  onCreateGroup,
  isMyGroup = false,
) => {
  const navigate = useNavigate();
  const navigateToGroupDetails = React.useCallback(
    (id) => navigate(endpointIDReplacer(Endpoints.GROUP_DETAILS, id)),
    [navigate],
  );

  const dispatch = useAppDispatch();

  const [buttons, setButtons] = React.useState<
    {
      label: string;
      action: () => () => void;
      priority: ButtonPriority;
    }[]
  >([]);

  React.useEffect(() => {
    if (isEmptyCard) {
      setButtons([
        {
          label: 'Create',
          action: () => onCreateGroup,
          priority: ButtonPriority.PRIMARY,
        },
      ]);
    } else if (isMyGroup) {
      setButtons([
        {
          label: 'More Info',
          action: () => () => navigateToGroupDetails(groupId),
          priority: ButtonPriority.SECONDARY,
        },
        {
          label: 'Leave',
          action: () => () => {
            dispatch(leaveSupportGroup({ groupId }));
          },
          priority: ButtonPriority.PRIMARY,
        },
      ]);
    } else {
      setButtons([
        {
          label: 'More Info',
          action: () => () => navigateToGroupDetails(groupId),
          priority: ButtonPriority.SECONDARY,
        },
        {
          label: 'Join',
          action: () => () => {
            dispatch(joinSupportGroup({ groupId }));
          },
          priority: ButtonPriority.PRIMARY,
        },
      ]);
    }
  }, [
    dispatch,
    groupId,
    isEmptyCard,
    isMyGroup,
    navigateToGroupDetails,
    onCreateGroup,
  ]);

  const onClick = React.useCallback(
    () => () => {
      if (isEmptyCard) return;
      if (isMyGroup)
        navigate(endpointIDReplacer(Endpoints.GROUP_ACTIVITY, groupId));
      else navigate(endpointIDReplacer(Endpoints.GROUP_DETAILS, groupId));
    },
    [groupId, isEmptyCard, isMyGroup, navigate],
  );

  return { buttons, onClick };
};

export default useGroupCardActions;
