import HighlightedTabs from '@shared/components/tabs/HighlightedTabs';
import * as React from 'react';
import { useContext } from 'react';
import { InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  ContentListFilterContext,
  ContentListFilterContextValue,
} from '@view/content-management-system/context/ContentListFilterContext';
import useIsMobile from '@hooks/use-breakpoint/useIsMobile';

const ContentListTabs: React.FC<unknown> = () => {
  const { search, tabs } = useContext(
    ContentListFilterContext,
  ) as ContentListFilterContextValue;

  const [tabIndex, setTabIndex] = React.useState(0);
  const [searchKeyword, setSearchKeyword] = React.useState('');

  const isMobile = useIsMobile();

  React.useEffect(() => {
    tabs.setValue(tabIndex);
  }, [tabIndex, tabs]);

  React.useEffect(() => {
    search.setValue(searchKeyword);
  }, [search, searchKeyword]);

  const renderSearchBar = () => {
    return (
      <Paper
        component="form"
        className="subtitle2"
        sx={{
          p: '2px 2px',
          display: 'flex',
          alignItems: 'center',
          width: 300,
          height: 34,
          borderRadius: '100px',
          backgroundColor: '#fff',
        }}
      >
        <SearchIcon
          className="text-on-primary"
          sx={{
            p: '10px',
            width: isMobile ? 20 : 40,
            height: 40,
          }}
        />
        <InputBase
          onChange={(e) => setSearchKeyword(e.target.value)}
          value={searchKeyword}
          sx={{ ml: 1, flex: 1 }}
          placeholder={isMobile ? 'Search' : 'Search projects'}
          inputProps={{ 'aria-label': isMobile ? 'Search' : 'Search projects' }}
        />
      </Paper>
    );
  };

  return (
    <div className="flex items-center justify-between w-full mt-10 mb-1">
      <HighlightedTabs
        tabs={tabs.options}
        selectedTab={tabIndex}
        onTabChanged={setTabIndex}
        className="ml-5"
      />
      {renderSearchBar()}
    </div>
  );
};

export default ContentListTabs;
