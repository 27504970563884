// @flow
import * as React from 'react';
import { TextKeys } from '@lib/i18n/configureI18n';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CardSelection from '@shared/templates/card-selection/CardSelection';
import { AppSelector } from '@data/app/app.selector';
import useCommunitySelection from './hooks/useCommunitySelection';
import CommunitiesListEmptyView from './components/CommunitiesListEmptyView';

const CommunitySelection: React.FC<unknown> = (props) => {
  const { t } = useTranslation();
  const communities = useSelector(AppSelector.selectAvailableCommunities);
  const [selectedCommunity, setSelectedCommunity] = React.useState<
    string | undefined
  >();
  const { onLogout, setActiveCommunity } = useCommunitySelection();

  return (
    <>
      <Helmet>
        <title>{t(TextKeys.CommunitySelectionPageTitle)}</title>
      </Helmet>
      <CardSelection
        options={communities}
        activeOption={selectedCommunity}
        setActiveOption={setSelectedCommunity}
        handleOnSubmit={setActiveCommunity(selectedCommunity ?? '')}
        title={t(TextKeys.WelcomeToApp)}
        helperText={t(TextKeys.SelectCommunityHelper)}
        onCancel={{
          label: t(TextKeys.Logout),
          action: onLogout(),
        }}
        EmptyView={<CommunitiesListEmptyView onReturn={onLogout()} />}
      />
    </>
  );
};
export default CommunitySelection;
