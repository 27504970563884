import { createAsyncThunk } from '@reduxjs/toolkit';

export interface APIData {
  userId: number;
  deviceId: string;
  community: string;

  baseUrl?: string;
}

export const getApiCallData = createAsyncThunk(
  'shared/getAuth',
  async (_, { getState }): Promise<APIData> => {
    const state = getState() as any;
    const userId = state.app?.user?.id;
    const deviceId = state.app?.auth?.deviceId;
    const community = state.app?.activeCommunity?.key;
    const baseUrl = state.app?.selectedApp?.apiUrl;

    return { userId, deviceId, community, baseUrl };
  },
);

export const getAuthTokens = createAsyncThunk(
  'shared/getAuthTokens',
  async (_, { getState }): Promise<{ authToken: string; appToken: string }> => {
    const state = getState() as any;
    const authToken = state.app?.auth?.authToken;
    const appToken = state.app?.auth?.appToken;

    return { authToken, appToken };
  },
);

export default { getApiCallData };
