export enum ButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  RESET = 'reset',
}

export enum ButtonPriority {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  NONE = 'NONE',
}

export enum ButtonVariant {
  TEXTPRIMARY = 'textPrimary',
  TEXTSECONDARY = 'textSecondary',
  TEXTTERTIARY = 'textTertiary',
  TEXTERROR = 'texterror',
}

export default {
  buttonTypes: ButtonType,
  buttonPriority: ButtonPriority,
};
