import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { APIData } from '@data/shared/shared.thunk';
import { URLUtils } from '@utils/index';
import { ApiEndpoint } from '@utils/types';
import { Dispatch } from 'redux';

const createAsyncThunkWithAuth = <T = any, K = any>(
  type: string,
  func: (
    args: T & APIData,
    urlGenerator: (
      endpoint: ApiEndpoint,
      params?: Record<string, string | number>,
    ) => string,
    dispatch: Dispatch<any>,
    state: any,
  ) => Promise<K>,
): AsyncThunk<K, T, Record<string, unknown>> => {
  return createAsyncThunk(type, async (a: T, thunkAPI): Promise<K> => {
    const state = thunkAPI.getState() as any;
    const userId = state.app?.user?.id;
    const deviceId = state.app?.auth?.deviceId;
    const community = state.app?.activeCommunity?.key;
    const baseUrl = state.app?.selectedApp?.apiUrl;

    const authData = { userId, deviceId, community, baseUrl };
    const urlGenerator = (
      endpoint: ApiEndpoint,
      params?: Record<string, string | number>,
    ): string => {
      return URLUtils.generateApiRoute2(endpoint, {
        ...(params || {}),
        ...authData,
      });
    };
    return func(
      {
        ...authData,
        ...a,
      },
      urlGenerator,
      thunkAPI.dispatch as Dispatch<any>,
      thunkAPI.getState(),
    );
  });
};

export default createAsyncThunkWithAuth;
