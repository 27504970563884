import React from 'react';
import { AppBar as MaterialAppBar, Toolbar } from '@mui/material';
import Menu from '@mui/icons-material/Menu';
// import {black} from '@shared/styles';
import { useSelector } from 'react-redux';
import { AppSelector } from '@data/app/app.selector';
import CommunitySelector from './components/community-selector/CommunitySelector';
import Logo from '../../../../components/logo';
import NotificationButton from '../notification-drawer/NotificationButton';

export interface AppBarProps {
  openMobileMenu: () => void;
}

/**
 * Top App Bar
 *
 * @param props
 */
const AppBar: React.FC<AppBarProps> = ({ openMobileMenu }) => {
  const { title } = useSelector(AppSelector.selectActiveCommunity);
  return (
    <MaterialAppBar
      style={{ zIndex: 999 }}
      className="z-[1301]"
      position="fixed"
      id="app-nav-bar"
    >
      <Toolbar className="fixed w-full bg-white shadow-[0_1px_1px_4px_rgba(0,0,0,0.07)] h-[60px]">
        <div className="items-center justify-center hidden pr-6 lg:flex h-[60px]">
          <Logo />
        </div>
        <div className="block lg:hidden mr-3.5">
          <Menu style={{ color: '#000' }} onClick={openMobileMenu} />
        </div>
        <CommunitySelector activeCommunityName={title} />
        <NotificationButton />
      </Toolbar>
    </MaterialAppBar>
  );
};

export default AppBar;
