// @flow
import * as React from 'react';
import { Card } from '@mui/material';
import { ReactComponent as GroupSvg } from '@assets/icons/group.svg';
import { ReactComponent as HideSVG } from '@assets/icons/hide.svg';
import { ReactComponent as DefaultCoverSVG } from '@assets/images/default-images/group_cover.svg';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import { SupportGroup } from '@data/support-groups/supportGroups.model';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import useGroupCardActions from './hooks/useGroupCardActions';
import Members from './components/Members';
import { ButtonPriority } from '../../../../models/constants';

type GroupCardProps = {
  supportGroup?: SupportGroup;
  isEmptyCard?: boolean;
  onCreateGroup?: () => void;
  loading?: boolean;
};

const GroupCard: React.FC<GroupCardProps> = (props) => {
  const { supportGroup, onCreateGroup, isEmptyCard = false, loading } = props;

  const id = React.useId();
  const isHidden = supportGroup?.status.isHidden ?? false;
  const { t } = useTranslation();
  const [loadingCoverImage, setLoadingCoverImage] = React.useState(false);

  const { buttons, onClick } = useGroupCardActions(
    supportGroup?.id,
    isEmptyCard,
    onCreateGroup,
    supportGroup?.status.userIsJoined,
  );

  const renderButtons = () => {
    return (
      <div className="absolute flex items-center justify-center w-full bottom-5">
        {!loading ? (
          buttons.map((button, index) => {
            return (
              <Button
                variant={
                  button.priority === ButtonPriority.PRIMARY
                    ? 'contained'
                    : 'outlined'
                }
                color="primary"
                className={`!w-[130px] !h-[40px] ${
                  buttons.length - 1 === index ? '' : '!mr-2'
                }`}
                key={`${id}-${index}`}
                onClick={button.action()}
              >
                {button.label}{' '}
              </Button>
            );
          })
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              width={130}
              height={40}
              className="mr-2"
            />
            <Skeleton variant="rectangular" width={130} height={40} />
          </>
        )}
      </div>
    );
  };

  const renderHiddenIcon = () => {
    if (!isHidden) return null;
    return (
      <div className="absolute bg-white rounded-full top-2 left-2 w-[40px] h-[40px]">
        <HideSVG className="w-[40px] h-[40px] !fill-[#101E2D]" />
      </div>
    );
  };

  const renderGroupTitle = () => {
    if (loading) return <Skeleton variant="text" width={200} />;
    if (isEmptyCard)
      return (
        <p className="mt-1 font-bold h3 text-brand-primary mb-0.5 !leading-6">
          {t(TextKeys.YourOwnGroup)}
        </p>
      );
    return (
      <p className="mt-1 h3 text-on-primary mb-0.5 !leading-6">
        {supportGroup?.info.title}
      </p>
    );
  };

  const renderHeader = () => {
    if (loading) return <Skeleton variant="text" width={100} />;
    return (
      <div className="flex items-center mt-4 tags !text-on-secondary !fill-on-secondary">
        <GroupSvg className="w-5 mr-2" />{' '}
        {isEmptyCard ? t(TextKeys.CreateYourOwn) : t(TextKeys.Group)}
      </div>
    );
  };

  const renderCardBody = () => {
    if (loading)
      return <Skeleton variant="rectangular" width="100%" height={80} />;
    return (
      <p className="mt-1 caption !text-base text-grey-dark !font-light !leading-[18px] ellipsis3">
        {isEmptyCard
          ? t(TextKeys.CreateYourGroupDescription)
          : supportGroup?.info.description}
      </p>
    );
  };

  const renderGroupImage = () => {
    if (loading)
      return <Skeleton variant="rectangular" width="100%" height={125} />;
    if (supportGroup?.info.coverImage && !isEmptyCard)
      return (
        <img
          onClick={onClick()}
          src={supportGroup?.info.coverImage}
          onLoad={() => setLoadingCoverImage(false)}
          // onLoadStart={() => setLoadingCoverImage(true)}
          alt=""
          className={`object-cover w-full h-[125px] ${
            loadingCoverImage ? 'hidden' : 'block'
          }`}
        />
      );
    return <DefaultCoverSVG className="object-cover w-full h-[125px]" />;
  };

  return (
    <Card className="relative w-full mt-4 bg-white cursor-pointer lg:w-[425px] md:max-w-[49%] h-[330px] rounded-md">
      <Members
        numberOfMembers={supportGroup?.members.numberOfMembers || 0}
        isEmptyCard={isEmptyCard}
        loading={loading}
        className="top-[17px] right-[13px]"
      />
      {renderGroupImage()}
      {renderHiddenIcon()}
      <div className="pl-4 pr-4" onClick={onClick()}>
        {renderHeader()}
        {renderGroupTitle()}
        {renderCardBody()}
      </div>
      {renderButtons()}
    </Card>
  );
};

export default GroupCard;
