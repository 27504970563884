import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import useNavigateKey, {
  NavigateKeys,
} from '@hooks/use-navigate-key/UseNavigateKey';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { SelectionOption } from './models/SelectionOption';
import SelectionCard from './components/card/SelectionCard';
import LoadingPage from '../../components/loading-page';
import HeaderContent from './components/header/HeaderContent';
import WithHeader from '../with-header/WithHeader';

export interface CardSelectionProps {
  activeOption?: string;
  options: Record<string, SelectionOption>;
  recentOptions?: string[];
  setActiveOption: (key: string) => void;
  handleOnSubmit: (loadingId?: number) => void;
  title?: string;
  helperText?: string;
  onCancel?: {
    label: string;
    action: () => void;
  };
  EmptyView?: React.ReactNode;
  loadingCards?: boolean;
  submitting?: boolean;
}

const CardSelection: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<CardSelectionProps>>
> = ({
  activeOption,
  options,
  setActiveOption,
  handleOnSubmit,
  title,
  helperText,
  onCancel,
  EmptyView,
  loadingCards,
  submitting,
  recentOptions = [],
}) => {
  const [filteredKeys, setFilteredKeys] = useState<string[]>([]);

  const handleOnCardClick = useCallback(
    (key: string) => {
      setActiveOption(key);
    },
    [setActiveOption],
  );

  const handleOnClick = useCallback(
    () => () => {
      handleOnSubmit();
    },
    [handleOnSubmit],
  );

  const navigate = (current: number, change: number, max: number): number => {
    if (current === -1) return 0;
    if (current + change < max && current + change >= 0)
      return current + change;
    return current;
  };

  const handleNavigateKeys = useCallback(
    (key: NavigateKeys) => {
      const selected = filteredKeys.indexOf(activeOption || '');
      if (key === NavigateKeys.RIGHT)
        setActiveOption(
          filteredKeys[navigate(selected, 1, Object.keys(filteredKeys).length)],
        );
      if (key === NavigateKeys.LEFT)
        setActiveOption(
          filteredKeys[
            navigate(selected, -1, Object.keys(filteredKeys).length)
          ],
        );
      if (key === NavigateKeys.ENTER && selected !== -1) handleOnClick()();
    },
    [filteredKeys, activeOption, setActiveOption, handleOnClick],
  );

  useNavigateKey(handleNavigateKeys);

  useEffect(() => {
    setFilteredKeys(
      Object.keys(options).filter(
        (k) =>
          options[k].displayName !== undefined &&
          recentOptions.indexOf(k) === -1,
      ),
    );
  }, [options, recentOptions]);

  const renderOptions = (keys, list): React.ReactNode => {
    return (
      <div className="flex flex-wrap justify-center w-full m-auto -mt-12 sm:max-w-5xl">
        {keys.map((key) => {
          const option = list[key];
          if (!option) return null;
          return (
            <SelectionCard
              key={key}
              id={key}
              img={option.logo}
              displayName={option.displayName}
              isActive={activeOption === key}
              onClick={handleOnCardClick}
            />
          );
        })}
      </div>
    );
  };

  return (
    <WithHeader
      HeaderContent={
        <HeaderContent
          title={title}
          helperText={Object.keys(options).length === 0 ? '' : helperText}
        />
      }
    >
      {loadingCards ? (
        <LoadingPage />
      ) : (
        <div className="flex flex-col pb-4">
          {Object.keys(options).length === 0 && EmptyView ? (
            EmptyView
          ) : (
            <div className="w-full flex-col-center">
              {recentOptions.length > 0 && (
                <div className="flex flex-col w-full  sm:max-w-5xl">
                  <h5 className="mb-12 ml-12 h5">Recently visited:</h5>
                  {renderOptions(recentOptions, options)}
                </div>
              )}
              {recentOptions.length > 0 && (
                <h5 className="w-full mb-20 h5 ml-[100px] sm:max-w-5xl">
                  All apps:
                </h5>
              )}
              {renderOptions(filteredKeys, options)}
              <div className="max-w-xs m-auto min-w-260 mt-18 flex-col-center">
                <LoadingButton
                  disabled={submitting || !activeOption}
                  onClick={handleOnClick()}
                  variant="contained"
                  color="primary"
                  loading={submitting}
                  className="w-full bg-brand-primary"
                >
                  GO
                </LoadingButton>
                {onCancel && (
                  <Button
                    onClick={onCancel?.action}
                    variant="outlined"
                    className="w-full !mt-3"
                  >
                    {onCancel?.label}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </WithHeader>
  );
};

CardSelection.defaultProps = {
  activeOption: undefined,
  title: '',
  helperText: '',
  onCancel: undefined,
  EmptyView: undefined,
  loadingCards: false,
  submitting: false,
  recentOptions: [],
};

export default React.memo(CardSelection);
