import { Content } from '@data/contents/Contents.model';
import React from 'react';
import ContentCard from '@view/discover-page/components/ContentCard';
import IconButton from '@mui/material/IconButton';
import RightArrow from '@mui/icons-material/ArrowForwardIos';

const ContentGroup: React.FC<{
  title: string;
  contentList: Content[];
}> = ({ title, contentList }) => {
  const [expanded, setExpanded] = React.useState<false>(false);

  const showMore = React.useCallback(() => {
    if (contentList?.length <= 3) return null;
    if (expanded) return null;
    return (
      <IconButton
        onClick={() => setExpanded(!expanded)}
        className="absolute top-0 right-0 p-0 bg-white border border-solid w-[86px] h-[31px] rounded-[50px] text-[#4D565F] caption !border-secondary-background"
      >
        See all
        <RightArrow className="h-3 -mr-2 text-on-secondary" />
      </IconButton>
    );
  }, [contentList?.length, expanded]);

  return (
    <div className="relative">
      <h1 className="mt-5 h1">{title}</h1>
      {showMore()}
      <div className="flex flex-wrap items-center justify-start w-full mt-4">
        {contentList?.map((content, _) => {
          if (_ > 2 && !expanded) return null;
          return (
            <ContentCard content={content} key={content.title} index={_} />
          );
        })}
      </div>
    </div>
  );
};

export default ContentGroup;
