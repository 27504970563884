import * as React from 'react';
import { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import { useSelector } from 'react-redux';
import CardSelection from '@shared/templates/card-selection/CardSelection';
import { getApps, setActiveApp } from '@data/app/app.thunk';
import useAppDispatch from '@data/useAppDispatch';
import { AppSelector } from '@data/app/app.selector';
import Endpoints from '@view/routes/endpoints';
import { CuratioApps } from './utils/appSelection.utils';

/**
 * App Server Selection
 */
const AppSelection: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [nextApp, setNextApp] = useState<CuratioApps>();
  const [appsAreLoaded, setAppsAreLoaded] = useState<
    'NO_LOADED' | 'LOADING' | 'LOADED'
  >('NO_LOADED');
  const [submitting, setSubmitting] = useState(false);

  const apps = useSelector(AppSelector.selectApps);
  const recentAppsKeys = useSelector(AppSelector.selectRecentApps);

  React.useEffect(() => {
    if (appsAreLoaded === 'NO_LOADED') setAppsAreLoaded('LOADING');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (appsAreLoaded === 'LOADING') {
      dispatch(getApps())
        .then(() => {
          setAppsAreLoaded('LOADED');
        })
        .catch(() => {
          setTimeout(() => {
            setAppsAreLoaded('NO_LOADED');
          }, 5000);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appsAreLoaded]);

  const handleAppSelection = useCallback(
    () => (key: string) => {
      setNextApp(key as CuratioApps);
    },
    [setNextApp],
  );

  const handleSubmit = useCallback(
    () => () => {
      if (nextApp) {
        setSubmitting(true);
        dispatch(setActiveApp(nextApp)).then(() => {
          navigate(Endpoints.AUTH);
          setSubmitting(false);
        });
      }
    },
    [dispatch, navigate, nextApp],
  );

  const getAppOptions = useCallback((_apps) => {
    return Object.fromEntries(
      Object.keys(_apps).map((key) => [
        key,
        {
          displayName: _apps[key].name,
          logo: _apps[key].imgUrl,
        },
      ]),
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>{t(TextKeys.AppSelectionPageTitle)}</title>
      </Helmet>
      <CardSelection
        options={getAppOptions(apps)}
        activeOption={nextApp}
        setActiveOption={handleAppSelection()}
        handleOnSubmit={handleSubmit()}
        title={t(TextKeys.WelcomeToApp)}
        helperText={!appsAreLoaded ? '' : t(TextKeys.SelectAppHelper)}
        loadingCards={!appsAreLoaded}
        submitting={submitting}
        recentOptions={recentAppsKeys}
      />
    </>
  );
};

export default React.memo(AppSelection);
