import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Content, Tag } from './Contents.model';
import {
  bookmarkContent,
  createCategory,
  createTag,
  deleteCategory,
  deleteContent,
  deleteTag,
  fetchCategories,
  fetchContents,
  fetchContentsByCategory,
  fetchTags,
  getContent,
  markContent,
  markSeriesAsCompleted,
  moveToNextStep,
  publishContent,
  saveArticle,
  saveSeries,
  startSeries,
  unbookmarkContent,
  unPublishContent,
  updateCategory,
  updateTag,
} from './Contents.thunk';
import {
  convertContentResponse,
  convertContentsListResponse,
  convertTagResponse,
  convertTagsResponse,
} from './Contents.factory';

export const contentAdapter = createEntityAdapter<Content>({
  selectId: (content) => content.id,
  sortComparer: (a, b) => (b.id > a.id ? 1 : -1),
});

export const tagAdapter = createEntityAdapter<Tag>({
  selectId: (tag) => tag.id,
  sortComparer: (a, b) => (b.id > a.id ? 1 : -1),
});

const getInitialState = () => ({
  contents: contentAdapter.getInitialState(),
  tags: tagAdapter.getInitialState(),
  categories: tagAdapter.getInitialState(),
});

const upsertContentMatcher = (action) => {
  return (
    action.type === getContent.fulfilled.type ||
    action.type === saveArticle.fulfilled.type ||
    action.type === saveSeries.fulfilled.type ||
    action.type === publishContent.fulfilled.type ||
    action.type === unPublishContent.fulfilled.type ||
    action.type === markContent.fulfilled.type ||
    action.type === bookmarkContent.fulfilled.type ||
    action.type === unbookmarkContent.fulfilled.type ||
    action.type === startSeries.fulfilled.type ||
    action.type === moveToNextStep.fulfilled.type ||
    action.type === markSeriesAsCompleted.fulfilled.type
  );
};

const contentSlice = createSlice({
  name: 'contents',
  initialState: {
    contents: contentAdapter.getInitialState(),
    tags: tagAdapter.getInitialState(),
    categories: tagAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchContents.fulfilled, (state, action) => {
        if (!action.meta.arg.saveToStore) return;
        contentAdapter.setAll(
          state.contents,
          convertContentsListResponse(action.payload as any[]),
        );
      })
      .addCase(fetchContentsByCategory.fulfilled, (state, action) => {
        contentAdapter.setAll(
          state.contents,
          convertContentsListResponse(action.payload as any[]),
        );
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        tagAdapter.setAll(
          state.categories,
          convertTagsResponse(action.payload),
        );
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        tagAdapter.setAll(
          state.tags,
          convertTagsResponse(
            action.payload.tags.filter((tag) => tag.category === 'content'),
          ),
        );
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        tagAdapter.upsertOne(
          state.categories,
          convertTagResponse(action.payload),
        );
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        tagAdapter.upsertOne(
          state.categories,
          convertTagResponse(action.payload),
        );
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        // @ts-ignore
        tagAdapter.removeOne(state.categories, action.payload.id);
      })
      .addCase(createTag.fulfilled, (state, action) => {
        tagAdapter.upsertOne(state.tags, convertTagResponse(action.payload));
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        tagAdapter.upsertOne(state.tags, convertTagResponse(action.payload));
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        // @ts-ignore
        tagAdapter.removeOne(state.tags, action.payload.id);
      })
      .addCase(deleteContent.fulfilled, (state, action) => {
        contentAdapter.removeOne(
          state.contents,
          (action.payload as { id: string }).id,
        );
      })
      .addMatcher(upsertContentMatcher, (state, action) => {
        const update = convertContentResponse(action.payload);
        Object.keys(update).forEach((key) =>
          update[key] === undefined ? delete update[key] : {},
        );
        contentAdapter.upsertOne(state.contents, {
          ...(state.contents.entities[action.payload.id] || {}),
          ...update,
        });
      }),
});

export default {
  reducers: contentSlice.reducer,
  getInitialState,
};
