// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import { Card } from '@mui/material';

import WithSideBar from '@shared/templates/with-side-bar/WithSideBar';
import Timeline from '@shared/components/timeline/Timeline';
import { fetchAEPosts } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import { TimeLines } from '@data/timeline/timeline.model';
import { LoadingButton } from '@mui/lab';
import LoadingPage from '@shared/components/loading-page';
import { AsyncThunk } from '@reduxjs/toolkit';
import useAEReport from './hooks/useAEReport';

const AdverseEventsPage: React.FC<any> = () => {
  const { t } = useTranslation();

  const { AEProvider, onSubmit, submitting, disableSubmitButton } =
    useAEReport();

  const renderTimeLine = React.useCallback(() => {
    if (submitting) return <LoadingPage />;
    return (
      <AEProvider>
        <Timeline
          timelineFetcher={
            fetchAEPosts as unknown as AsyncThunk<
              never,
              { page: number },
              never
            >
          }
          selector={TimelineSelectors.adverseEvent.selectIds}
          hasMoreSelector={TimelineSelectors.adverseEvent.hasMoreSelector}
          pageSelector={TimelineSelectors.adverseEvent.pageSelector}
          timeline={TimeLines.ADVERSE_EVENT}
          emptyListMessage="No Adverse Events"
        />
      </AEProvider>
    );
  }, [AEProvider, submitting]);

  return (
    <WithSideBar>
      <div className="flex justify-center w-full h-full pb-4">
        <div className="relative w-full h-auto mt-5 flex-col-center max-w-[540px]">
          <a
            className="absolute right-0 uppercase cursor-pointer text-on-secondary subtitle2 top-[5px]"
            href="/adverse-events/reviewed"
          >
            {t(TextKeys.ViewCaseIDS)}
          </a>
          <span className="w-full mb-4 flex-col-center h3 text-on-primary">
            {t(TextKeys.ReviewPotentialAdverseEvents)}
          </span>
          <Card className="w-full h-full mt-1 max-w-[540px]">
            {renderTimeLine()}
          </Card>
          <LoadingButton
            disabled={disableSubmitButton}
            className="mt-5 mb-5"
            color="primary"
            loading={submitting}
            variant="contained"
            onClick={onSubmit()}
          >
            Submit Review
          </LoadingButton>
        </div>
      </div>
    </WithSideBar>
  );
};

export default AdverseEventsPage;
