import React from 'react';

import LoadingPage from '@shared/components/loading-page';
import LoadingSpinner from './LoadingSpinner';

export enum LoadingType {
  LOADING_PAGE,
  SPINNER,
}

const Loading: React.FC<React.PropsWithChildren<{ type?: LoadingType }>> = ({
  type = LoadingType.LOADING_PAGE,
}) => {
  if (type === LoadingType.LOADING_PAGE) return <LoadingPage />;
  return <LoadingSpinner />;
};

export default Loading;
