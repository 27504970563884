// @flow
import * as React from 'react';
import Button from '@mui/material/Button';

type HighlightedTabsProps = {
  tabs: string[];
  onTabChanged: (index: number) => void;
  selectedTab: number;
  className?: string;
};

const HighlightedTabs: React.FC<HighlightedTabsProps> = ({
  tabs,
  selectedTab,
  onTabChanged,
  className,
}) => {
  return (
    <div className={`w-full flex justify-start ${className}`}>
      {tabs.map((tab, index) => {
        return (
          <Button
            className={`!h-[40px]  mr-6 ${
              index === selectedTab ? '!bg-[#D3E5EC]' : ''
            }`}
            onClick={() => onTabChanged(index)}
            key={tab}
          >
            {tab}
          </Button>
        );
      })}
    </div>
  );
};
export default HighlightedTabs;
