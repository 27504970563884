import * as React from 'react';
import { reviewAE } from '@data/timeline/timeline.thunk';
import useAppDispatch from '@data/useAppDispatch';

export type AEContextType = {
  onApprove: (id: number) => void;
  onReject: (
    id: number,
    details?: { comment?: string; screenshot?: any },
  ) => void;
  onRevert: (id: number) => void;
};

export const AEContext: React.Context<AEContextType | null> =
  React.createContext<AEContextType | null>(null);

type useAEReportType = {
  AEProvider: ({ children }: { children: React.ReactNode }) => JSX.Element;
  onSubmit: () => () => void;
  submitting: boolean;
  disableSubmitButton: boolean;
};

const useAEReport = (): useAEReportType => {
  const [reviews, setReviews] = React.useState<
    { status: 'AE' | 'NotAE'; id: number }[]
  >([]);
  const [review, setReview] = React.useState<
    | {
        status: 'AE' | 'NotAE' | 'remove';
        id: number;
        comment?: string;
        screenshot?: string;
      }
    | undefined
  >(undefined);
  const [status, setStatus] = React.useState<
    'reviewing' | 'submitting' | 'pending'
  >('reviewing');
  const dispatch = useAppDispatch();

  const onApprove = React.useCallback(
    (id: number) => {
      setReview({ status: 'NotAE', id });
    },
    [setReview],
  );

  const onReject = React.useCallback(
    (id: number, details: { comment?: string } = {}) => {
      // @todo check it if comment wroks instead of comments
      setReview({ status: 'AE', id, ...details });
    },
    [setReview],
  );

  const onRevert = React.useCallback((id: number) => {
    setReview({ status: 'remove', id });
  }, []);

  React.useEffect(() => {
    if (review) {
      if (review.status === 'remove') {
        setReviews((pre) => pre.filter((r) => r.id !== review.id) || []);
      } else {
        setReviews((pre) => {
          const oldReview =
            (pre.filter((r) => r.id === review.id) || [])[0] || {};
          return [
            ...pre.filter((r) => r.id !== review.id),
            { ...oldReview, ...review } as {
              status: 'AE' | 'NotAE';
              id: number;
            },
          ];
        });
      }
      setReview(undefined);
    }
  }, [review]);

  React.useEffect(() => {
    if (status === 'pending') setReviews([]);
    if (status === 'submitting') {
      dispatch(reviewAE({ reviews })).then(() => {
        setStatus('reviewing');
      });
      setStatus('pending');
    }
  }, [status, reviews, dispatch]);

  const providerContext = React.useMemo(
    () => ({
      onApprove,
      onReject,
      onRevert,
    }),
    [onApprove, onReject, onRevert],
  );

  const AEProvider = React.useCallback(
    ({ children }): JSX.Element => (
      <AEContext.Provider value={providerContext}>
        {children}
      </AEContext.Provider>
    ),
    [providerContext],
  );

  return {
    AEProvider,
    onSubmit: () => (): void => setStatus('submitting'),
    submitting: status !== 'reviewing',
    disableSubmitButton: reviews.length === 0,
  };
};

export default useAEReport;
