/**
 * Staging temporarily needs two of stronger together for discover testing
 type App = {
  id: number;
  name: string;
  version: string;
  display_name: string;
  apiUrl: string;
};

 type Apps = {
  Curatio: App;
  COVID19C: App;
  MS121: App;
  // ThaliMe: App;
  SCD: App;
  SCDFA: App;
  SCDMA: App;
  SCDIN: App;
  SCDEU: App;
  Demo: App;
  Calquence: App;
  Janssen: App;
  Dupixent: App;
  HeartLife: App;
  Lynparza: App;
};
 */
const baseConfig = {
  appName: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION,
  platform: process.env.REACT_APP_PLATFORM,
};
type Config = typeof baseConfig & { expressApiUrl: string; apps };
const config: { local: Config; staging: Config; production: Config } = {
  local: {
    ...baseConfig,
    expressApiUrl: 'http://webdev.curatio.me',
    apps: {
      Curatio: {
        id: 1,
        name: 'Curatio',
        version: '4.17.0',
        display_name: 'Curatio',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      COVID19C: {
        id: 9,
        name: 'COVID19C',
        version: '4.17.0',
        display_name: 'Stronger Together',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      MS121: {
        id: 6,
        name: 'MS121',
        version: '4.17.0',
        display_name: 'MS One to One',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      // ThaliMe: {
      //   id: 3,
      //   name: 'ThaliMe',
      //   version: '4.17.0',
      //   display_name: 'Thalime',
      //   apiUrl: 'https://dev.curatio.me/api/v4',
      // },
      SCD: {
        id: 5,
        name: 'SCD',
        version: '4.17.0',
        display_name: 'SCD Companion',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDFA: {
        id: 7,
        name: 'SCDFA',
        version: '4.17.0',
        display_name: 'SCD Companion Arabic - Female',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDMA: {
        id: 8,
        name: 'SCDMA',
        version: '4.17.0',
        display_name: 'SCD Companion Arabic - Male',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDIN: {
        id: 11,
        name: 'SCDIN',
        version: '4.17.0',
        display_name: 'SCD Companion - India',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDEU: {
        id: 15,
        name: 'SCDEU',
        version: '4.17.0',
        display_name: 'SCD Europe',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Demo: {
        id: 10,
        name: 'Demo',
        version: '4.17.0',
        display_name: 'Next Gen Patient Support',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Calquence: {
        id: 12,
        name: 'Calquence',
        version: '4.17.0',
        display_name: 'CALQUENCEConnect',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Janssen: {
        id: 16,
        name: 'Janssen1',
        version: '4.17.0',
        display_name: 'Janssen',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Dupixent: {
        id: 14,
        name: 'Dupixent',
        version: '4.17.0',
        display_name: 'Freedom Friend App',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      HeartLife: {
        id: 17,
        name: 'HeartLife',
        version: '4.17.0',
        display_name: 'My HeartLife',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Lynparza: {
        id: 19,
        name: 'Lynparza',
        version: '4.18.4',
        display_name: 'Lynparza & Me app',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
    },
  },
  staging: {
    ...baseConfig,
    expressApiUrl: 'https://web-staging.appspot.com',
    apps: {
      Curatio: {
        id: 1,
        name: 'Curatio',
        version: '4.17.0',
        display_name: 'Curatio',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      COVID19C: {
        id: 9,
        name: 'COVID19C',
        version: '4.17.0',
        display_name: 'Stronger Together',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      COVID19C2: {
        id: 9,
        name: 'COVID19C2',
        version: '4.19.0',
        display_name: 'Stronger Together (Staging)',
        apiUrl: 'https://staging.curatio.me/api/v4',
      },
      MS121: {
        id: 6,
        name: 'MS121',
        version: '4.17.0',
        display_name: 'MS One to One',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      // ThaliMe: {
      //   id: 3,
      //   name: 'ThaliMe',
      //   version: '4.17.0',
      //   display_name: 'Thalime',
      //   apiUrl: 'https://dev.curatio.me/api/v4',
      // },
      SCD: {
        id: 5,
        name: 'SCD',
        version: '4.17.0',
        display_name: 'SCD Companion',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDFA: {
        id: 7,
        name: 'SCDFA',
        version: '4.17.0',
        display_name: 'SCD Companion Arabic - Female',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDMA: {
        id: 8,
        name: 'SCDMA',
        version: '4.17.0',
        display_name: 'SCD Companion Arabic - Male',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDIN: {
        id: 11,
        name: 'SCDIN',
        version: '4.17.0',
        display_name: 'SCD Companion - India',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      SCDEU: {
        id: 15,
        name: 'SCDEU',
        version: '4.17.0',
        display_name: 'SCD Europe',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Demo: {
        id: 10,
        name: 'Demo',
        version: '4.17.0',
        display_name: 'Next Gen Patient Support',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Calquence: {
        id: 12,
        name: 'Calquence',
        version: '4.17.0',
        display_name: 'CALQUENCEConnect',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Janssen: {
        id: 16,
        name: 'Janssen1',
        version: '4.17.0',
        display_name: 'Janssen',
        apiUrl: 'https://staging.curatio.me/api/v4',
      },
      Dupixent: {
        id: 14,
        name: 'Dupixent',
        version: '4.17.0',
        display_name: 'Freedom Friend App',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      HeartLife: {
        id: 17,
        name: 'HeartLife',
        version: '4.17.0',
        display_name: 'My HeartLife',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
      Lynparza: {
        id: 19,
        name: 'Lynparza',
        version: '4.18.4',
        display_name: 'Lynparza & Me app',
        apiUrl: 'https://dev.curatio.me/api/v4',
      },
    },
  },
  production: {
    ...baseConfig,
    expressApiUrl: 'https://admin.curatio.me',
    apps: {
      Curatio: {
        id: 1,
        name: 'Curatio',
        version: '4.17.0',
        display_name: 'Curatio',
        apiUrl: 'https://prod-ca-4.curatio.me/api/v4',
      },
      COVID19C: {
        id: 9,
        name: 'COVID19C',
        version: '4.17.0',
        display_name: 'Stronger Together',
        apiUrl: 'https://prod-ca-4.curatio.me/api/v4',
      },
      MS121: {
        id: 6,
        name: 'MS121',
        version: '4.17.0',
        display_name: 'MS One to One',
        apiUrl: 'https://prod-ca-1.curatio.me/api/v4',
      },
      // ThaliMe: {
      //   id: 3,
      //   name: 'ThaliMe',
      //   version: '4.17.0',
      //   display_name: 'Thalime',
      //   apiUrl: 'https://prod-us-1.curatio.me/api/v4',
      // },
      SCD: {
        id: 5,
        name: 'SCD',
        version: '4.17.0',
        display_name: 'SCD Companion',
        apiUrl: 'https://prod-us-2.curatio.me/api/v4',
      },
      SCDFA: {
        id: 7,
        name: 'SCDFA',
        version: '4.17.0',
        display_name: 'SCD Companion Arabic - Female',
        apiUrl: 'https://prod-eu-1.curatio.me/api/v4',
      },
      SCDMA: {
        id: 8,
        name: 'SCDMA',
        version: '4.17.0',
        display_name: 'SCD Companion Arabic - Male',
        apiUrl: 'https://prod-eu-1.curatio.me/api/v4',
      },
      SCDIN: {
        id: 11,
        name: 'SCDIN',
        version: '4.17.0',
        display_name: 'SCD Companion - India',
        apiUrl: 'https://prod-in-1.curatio.me/api/v4',
      },
      SCDEU: {
        id: 15,
        name: 'SCDEU',
        version: '4.17.0',
        display_name: 'SCD Europe',
        apiUrl: 'https://prod-eu-1.curatio.me/api/v4',
      },
      Demo: {
        id: 10,
        name: 'Demo',
        version: '4.17.0',
        display_name: 'Next Gen Patient Support',
        apiUrl: 'https://staging.curatio.me/api/v4',
      },
      Calquence: {
        id: 12,
        name: 'Calquence',
        version: '4.17.0',
        display_name: 'CALQUENCE & Me app',
        apiUrl: 'https://prod-ca-2.curatio.me/api/v4',
      },
      Janssen: {
        id: 16,
        name: 'Janssen1',
        version: '4.17.0',
        display_name: 'Janssen',
        apiUrl: 'https://prod-ca-3.curatio.me/',
      },
      Dupixent: {
        id: 14,
        name: 'Dupixent',
        version: '4.17.0',
        display_name: 'Freedom Friend App',
        apiUrl: 'https://prod-ca-1.curatio.me/api/v4',
      },
      HeartLife: {
        id: 17,
        name: 'HeartLife',
        version: '4.17.0',
        display_name: 'My HeartLife',
        apiUrl: 'https://prod-ca-2.curatio.me/api/v4',
      },
      Lynparza: {
        id: 19,
        name: 'Lynparza',
        version: '4.18.4',
        display_name: 'Lynparza & Me app',
        apiUrl: 'https://prod-ca-2.curatio.me/api/v4',
      },
    },
  },
};
const hostname = window?.location?.host || '';
let environment: keyof typeof config = 'local';
if (hostname.includes('webdev')) {
  environment = 'staging';
} else if (hostname.includes('admin')) {
  environment = 'production';
}
export default config[environment];
