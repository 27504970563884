import * as React from 'react';
import { ReactComponent as LogoIconSvg } from '@assets/logo_icon.svg';

const LoadingPage: React.FC<
  React.PropsWithChildren<React.PropsWithChildren<unknown>>
> = () => {
  return (
    <div className="flex h-full">
      <div className="w-full pt-1 m-auto text-center max-w-[120px] h-[120px] animate-spin fill-white">
        <LogoIconSvg />
      </div>
    </div>
  );
};

export default LoadingPage;
