import React from 'react';
import Sidebar from './components/sidebar/Sidebar';
import AppBar from './components/app-bar/AppBar';
import WithLightbox from '../with-light-box/WithLightbox';
import MenuDrawer from './components/menu-drawer/MenuDrawer';

const WithSideBar: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  return (
    <WithLightbox>
      <div style={{ display: 'flex' }}>
        <MenuDrawer
          isOpen={isMobileMenuOpen}
          onClose={() => setIsMobileMenuOpen(false)}
        />
        <AppBar openMobileMenu={() => setIsMobileMenuOpen(true)} />
        <div className="flex w-full overflow-x-hidden scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-secondary bg-lighter h-[100vh] pt-[60px]">
          <Sidebar />
          <main className="w-full sm:flex-1 lg:ml-76">{children}</main>
        </div>
      </div>
    </WithLightbox>
  );
};

export default WithSideBar;
