import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { NotificationSelector } from '@data/notifications/Notification.selector';
import {
  Notification,
  NotificationType,
} from '@data/notifications/Notification.model';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Endpoints, { endpointIDReplacer } from '@view/routes/endpoints';

const useNotification = (key: EntityId) => {
  const notification: Notification | undefined = useSelector((state: any) =>
    NotificationSelector.selectById(state.notifications, key),
  );
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    if (notification?.type === NotificationType.sgrReviewReminder) {
      navigate(Endpoints.GROUP_REVIEWS);
    } else if (notification?.type === NotificationType.sgrReview) {
      navigate(Endpoints.GROUP_REVIEWS);
    } else if (notification?.type === NotificationType.share) {
      navigate(endpointIDReplacer(Endpoints.POST, notification.asset?.[0]?.id));
    } else if (notification?.type === NotificationType.newSeriesAvailable) {
      navigate(
        endpointIDReplacer(
          Endpoints.DISCOVER_PREVIEW,
          notification.referenceIds?.[0],
        ),
      );
    } else if (notification?.type === NotificationType.eventAttended) {
      navigate(
        endpointIDReplacer(
          Endpoints.GROUP_DETAILS,
          notification.referenceIds?.[0],
        ),
      );
    } else if (notification?.type === NotificationType.eventReminder1) {
      navigate(
        endpointIDReplacer(
          Endpoints.GROUP_DETAILS,
          notification.referenceIds?.[0],
        ),
      );
    } else if (notification?.type === NotificationType.eventReminder2) {
      navigate(
        endpointIDReplacer(
          Endpoints.GROUP_DETAILS,
          notification.referenceIds?.[0],
        ),
      );
    } else if (notification?.type === NotificationType.pollEnded) {
      navigate(endpointIDReplacer(Endpoints.POST, notification.asset?.[0]?.id));
    } else if (notification?.type === NotificationType.mentionedInPost) {
      navigate(endpointIDReplacer(Endpoints.POST, notification.asset?.[0]?.id));
    } else if (notification?.type === NotificationType.like) {
      navigate(endpointIDReplacer(Endpoints.POST, notification.asset?.[0]?.id));
    } else if (notification?.type === NotificationType.resharedItemSeries) {
      navigate(
        endpointIDReplacer(
          Endpoints.DISCOVER_PREVIEW,
          notification.referenceIds?.[0],
        ),
      );
    } else if (notification?.type === NotificationType.makeFriend) {
      navigate(endpointIDReplacer(Endpoints.PROFILE_ID, notification.user.id));
    } else if (notification?.type === NotificationType.reshare) {
      navigate(endpointIDReplacer(Endpoints.POST, notification.asset?.[0]?.id));
    } else if (notification?.type === NotificationType.newArticleAvailable) {
      navigate(
        endpointIDReplacer(
          Endpoints.DISCOVER_PREVIEW,
          notification.referenceIds?.[0],
        ),
      );
    } else if (notification?.type === NotificationType.comment) {
      navigate(endpointIDReplacer(Endpoints.POST, notification.asset?.[0]?.id));
    } else if (
      notification?.type === NotificationType.pvReview ||
      notification?.type === NotificationType.pvReviewReminder
    ) {
      navigate(Endpoints.ADVERSE_EVENTS);
    }
  }, [
    navigate,
    notification?.asset,
    notification?.referenceIds,
    notification?.type,
    notification?.user.id,
  ]);

  return {
    avatar: notification?.user.avatar,
    message: notification?.message,
    isUnread: notification?.isUnread,
    time: moment(new Date((notification?.createdAt || 0) * 1000)).fromNow(),
    onClick,
  };
};

export default useNotification;
