export enum BreakpointName {
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  MAX = 'max',
}

export type BreakpointValues = Record<BreakpointName, number>;

const value = {
  [BreakpointName.MAX]: 1600,
  [BreakpointName.XXLARGE]: 1440,
  [BreakpointName.XLARGE]: 1280,
  [BreakpointName.LARGE]: 1024,
  [BreakpointName.MEDIUM]: 768,
  [BreakpointName.SMALL]: 576,
  [BreakpointName.XSMALL]: 0,
};

const breakpoints: {
  name: typeof BreakpointName;
  value: { [key in BreakpointName]: number };
} = {
  name: BreakpointName,
  value,
};

export default breakpoints;
