import Fingerprint2 from 'fingerprintjs2';

const fingerPrintGenerator = (): Promise<string> => {
  return Fingerprint2.getPromise().then((components) => {
    const values = components.map((component) => component.value);
    return Fingerprint2.x64hash128(values.join(''), 31);
  });
};

export default {
  fingerPrintGenerator,
};
