import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Card } from '@mui/material';

const SeriesViewSkeleton: React.FC = () => {
  const renderSteps = React.useCallback(() => {
    return (
      <div className="flex-1 pl-6 pr-6 overflow-auto">
        {[...Array(3).fill(0)].map((step, index) => {
          return (
            <div
              key={`series-step-${index}`}
              className="flex items-center justify-start w-full p-4 h-[112px] rounded-[8px]"
            >
              <Skeleton
                variant="rectangular"
                className="object-cover w-24 h-24 rounded-[8px]"
              />
              <div className="flex flex-col justify-center flex-1 h-full ml-4">
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                <div className="flex items-center justify-start w-full mt-4 caption text-[#4D565F]">
                  <Skeleton variant="rectangular" className="w-4 h-4 mr-2" />
                  <Skeleton variant="text" className="w-8 h-4 mr-2" />
                  <Skeleton variant="text" className="w-4 h-4 mr-2" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  return (
    <Card className="w-full h-full sm:w-[66%] md:w-1/2 xl:w-1/3 max-w-[760px]">
      <div className="flex flex-col h-full">
        <Skeleton
          variant="rectangular"
          className="object-cover w-full h-[20%] max-h-[260px]"
        />
        <div className="relative pl-6 pr-6">
          <div className="flex items-center justify-start w-full mt-4 caption text-[#4D565F]">
            <Skeleton variant="rectangular" className="w-4 h-4 mr-2" />
            <Skeleton variant="text" className="w-8 h-4 mr-2" />
            <Skeleton variant="text" className="w-4 h-4 mr-2" />
          </div>
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
        </div>
        {renderSteps()}
      </div>
    </Card>
  );
};

export default SeriesViewSkeleton;
