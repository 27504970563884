import React from 'react';
import ErrorView from '@view/error-page/ErrorView';
import { ErrorType } from '../../../../models/constants/error';
// import { ErrorType } from '@shared/constants/error';
// import Error from '../../../error/components/Error';

export interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

export interface State {
  hasError: boolean;
  error: Error | null;
}

/**
 * Globally catch error
 */
class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error });
  }

  getErrorType = () => {
    const { error } = this.state;
    if (error) {
      if (error.message === 'Network Error') {
        return ErrorType.SERVER_ERROR;
      }
      if (error.message === 'not found') {
        return ErrorType.NOT_FOUND;
      }
    }
    return ErrorType.CLIENT_ERROR;
  };

  render() {
    const { children } = this.props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { hasError } = this.state;
    if (hasError) {
      return <ErrorView type={this.getErrorType()} />;
    }
    return children;
  }
}

export default ErrorBoundary;
