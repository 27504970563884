// @flow
import * as React from 'react';
import { IconButton } from '@mui/material';
import HDivider from '../divider/HDivider';

type OvalTabsProps = {
  selectedOption: string;
  options: string[];
  onChange: (tab: string) => void;
};

const OvalTabs: React.FC<OvalTabsProps> = ({
  options,
  selectedOption,
  onChange,
}) => {
  return (
    <>
      <div className="flex w-full pl-2">
        {options.map((option) => (
          <IconButton
            key={`option-${option}`}
            className={`${
              selectedOption === option
                ? 'h6 !border-brand-primary text-brand-primary'
                : 'caption border-on-secondary text-on-secondary'
            } rounded-full pt-2 pb-2 pr-4 pl-4 cursor-pointer
                             border mt-2 mb-2 mr-1 ml-1 relative`}
            onClick={() => onChange(option)}
          >
            {option}
            <span
              className={`w-3 h-[2px] bg-brand-primary rounded-md absolute -bottom-1 left-[40%]
                            ${selectedOption === option ? 'block' : 'hidden'}`}
            />
          </IconButton>
        ))}
      </div>
      <HDivider />
    </>
  );
};
export default OvalTabs;
