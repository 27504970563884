import { Backdrop, IconButton, Input } from '@mui/material';
import React, { Suspense } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';
import Button from '@mui/material/Button';
import useOutsideAlerter from '@hooks/use-outside-alerter/useOutSideAlerter';
import * as linkify from 'linkifyjs';
import { getEmbedRock } from '@data/shared/atachment.thunk';
import useAppDispatch from '@data/useAppDispatch';
import useLink from '@hooks/use-link/useLink';
import useIsMobile from '@hooks/use-breakpoint/useIsMobile';
import { Draft, DraftActions, DraftContext } from '../../hooks/useDraftActions';

type makePostBodyEditorProps = {
  hasLink: boolean;
  showEmojiMenu: boolean;
  closeEmojiMenu: () => void;
};

const MakePostBodyEditor: React.FC<makePostBodyEditorProps> = ({
  hasLink,
  showEmojiMenu,
  closeEmojiMenu,
}) => {
  const { setBody } = React.useContext(DraftContext) as DraftActions;
  const defaultValue = React.useContext(DefaultValueContext) as Draft;

  const placeHolder = 'Add a description to what you’re sharing';
  const EmojiPicker = React.lazy(() => import('emoji-picker-react'));

  const EmojiPickerRef = React.useRef(null);
  useOutsideAlerter(EmojiPickerRef, closeEmojiMenu);

  const [isExtended, setIsExtended] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue.body);

  const [link, setLink] = React.useState('');
  const [embedLinkData, setEmbedLinkData] = React.useState<any>(null);
  const [hasLinkData, setHasLinkData] = React.useState(false);

  const [selection, setSelection] = React.useState<
    [number, number] | undefined
  >();

  const { LinkPreview } = useLink({
    title: embedLinkData?.title,
    url: embedLinkData?.url,
    description: embedLinkData?.description,
  });

  const dispatch = useAppDispatch();

  const isMobile = useIsMobile();

  const COMPACT_HEIGHT = 140;
  const EXTENDED_HEIGHT = 240;

  const computeHeight = React.useCallback(
    () =>
      `${
        isExtended || isMobile
          ? EXTENDED_HEIGHT + (hasLinkData && !isMobile ? 200 : 0)
          : COMPACT_HEIGHT + (hasLinkData && !isMobile ? 200 : 0)
      }px`,
    [isExtended, isMobile, hasLinkData],
  );

  const handleExtend = () => () => setIsExtended(!isExtended);

  const renderExtendButton = () => (
    <IconButton
      style={{ borderRadius: 0 }}
      onClick={handleExtend()}
      className="hidden w-full sm:flex h-[21px] !bg-lighter-background"
      aria-label="extend"
    >
      {!isExtended ? (
        <KeyboardArrowDownIcon />
      ) : (
        <KeyboardArrowUpOutlinedIcon />
      )}
    </IconButton>
  );

  React.useEffect(() => {
    setBody(value as string);
    const links = linkify.find(value || '') || [];
    setLink(links[0]?.href || '');
  }, [setBody, value]);

  React.useEffect(() => {
    setHasLinkData(false);
    if (link === '') setEmbedLinkData(null);
    else
      dispatch(getEmbedRock(link)).then((res) => {
        if (res.title) {
          setEmbedLinkData(res);
          setHasLinkData(true);
        }
      });
  }, [dispatch, link]);

  const addEmoji = React.useCallback(
    (e, emojiObject) => {
      if (selection) {
        setValue(
          (value) =>
            `${value?.slice(0, selection[0])}${e.emoji}${value?.slice(
              selection[1],
            )}`,
        );
      } else {
        setValue((value) => `${value}${e.emoji}`);
      }
    },
    [selection],
  );

  const changeSelection = React.useCallback((e) => {
    // @ts-ignore
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
  }, []);

  const handleChangeBody = React.useCallback(
    () => (e) => {
      setValue(e.target?.value);
      changeSelection(e);
    },
    [changeSelection],
  );

  return (
    <div
      className="flex flex-col w-full max-h-[300px]"
      style={{
        height: computeHeight(),
      }}
    >
      <Input
        disableUnderline
        autoFocus
        placeholder={placeHolder}
        fullWidth
        multiline
        value={value}
        onKeyDown={changeSelection}
        onClick={changeSelection}
        onChange={handleChangeBody()}
        classes={{
          input: "!text-sm !font-normal !leading-5 !font-['Roboto'] h-full",
          root: ' justify-start !items-start overflow-auto !pl-4 !pr-4',
        }}
        className="flex justify-start flex-1 w-full !items-start"
      />
      {hasLinkData ? '' : renderExtendButton()}
      {hasLinkData && <LinkPreview />}
      <Backdrop
        ref={EmojiPickerRef}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={showEmojiMenu}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <EmojiPicker onEmojiClick={addEmoji} />
          <Button>Close</Button>
        </Suspense>
      </Backdrop>
    </div>
  );
};

export default MakePostBodyEditor;
