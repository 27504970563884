import * as React from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from '@data/useAppDispatch';
import {
  createSupportGroup,
  updateSupportGroup,
} from '@data/support-groups/supportGroup.thunk';
import { SupportGroup } from '@data/support-groups/supportGroups.model';
import SupportGroupSelector from '@data/support-groups/supportGroup.selector';

const useCreateGroup = (mode: 'edit' | 'create', groupId?: number) => {
  const supportGroup: SupportGroup | undefined = useSelector((state: any) =>
    SupportGroupSelector.selectById(state.supportGroups.all, `all-${groupId}`),
  );

  const [cover, _setCover] = React.useState<string | undefined>(
    supportGroup?.info.coverImage || '',
  );
  const [title, _setTitle] = React.useState<string>(
    supportGroup?.info.title || '',
  );
  const [description, _setDescription] = React.useState<string>(
    supportGroup?.info.description || '',
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (supportGroup) {
      _setCover(supportGroup?.info.coverImage || '');
      _setTitle(supportGroup?.info.title || '');
      _setDescription(supportGroup?.info.description || '');
    }
  }, [supportGroup]);

  const setCover = () => (cover?: string) => {
    _setCover(cover);
  };

  const setTitle = () => (e) => {
    _setTitle(e.target.value);
  };

  const setDescription = () => (e) => {
    _setDescription(e.target.value);
  };

  const onSubmit = () => () => {
    if (!cover || !title || !description) return;
    setLoading(true);
    if (mode === 'edit')
      dispatch(
        updateSupportGroup({
          groupId: String(groupId || 0),
          cover,
          title,
          description,
        }),
      ).then(() => {
        setLoading(false);
        setShowDialog(false);
      });
    else {
      dispatch(createSupportGroup({ cover, title, description }))
        .then(() => {
          setLoading(false);
          setShowDialog(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onCancel = () => () => {
    setShowDialog(false);
  };

  const isValid = cover !== '' && title !== '' && description !== '';

  return {
    mode,
    setCover,
    setTitle,
    setDescription,
    onSubmit,
    onCancel,
    open: showDialog,
    openDialog: () => setShowDialog(true),
    loading,
    isValid,
    cover,
    title,
    description,
  };
};

export default useCreateGroup;
