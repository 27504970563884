import { Notification } from './Notification.model';

export const convertNotificationsResponse = (
  response: any[],
  unreadNotifications?: number[],
): Notification[] => {
  return (response || []).map((notification) => {
    return {
      id: notification.id,
      createdAt: notification.created_at,
      message: notification.text,
      type: notification.type,
      user: {
        id: notification.by_user,
        name: notification.by_user_nick_name,
        avatar: notification.by_user_img_url,
      },
      isUnread: unreadNotifications?.includes(notification.id) ?? false,
      referenceIds: notification.reference_ids,
      asset: notification.asset,
    };
  });
};
