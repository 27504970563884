import React from 'react';
import { fetchMySupportGroups } from '@data/support-groups/supportGroup.thunk';
import { useSelector } from 'react-redux';
import SupportGroupSelector from '@data/support-groups/supportGroup.selector';
import useAppDispatch from '@data/useAppDispatch';

const useGroups = () => {
  const myGroups = useSelector(SupportGroupSelector.selectMyGroups);
  const dispatch = useAppDispatch();

  // Load support groups
  const [loadGroups, setLoadGroups] = React.useState(false);
  React.useEffect(() => {
    if (myGroups.length > 0) return;
    if (!loadGroups)
      dispatch(fetchMySupportGroups({ page: 1 })).finally(() => {
        setLoadGroups(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadGroups, myGroups]);

  return useSelector(SupportGroupSelector.selectMyGroups);
};

export default useGroups;
