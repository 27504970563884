import { RouteProps as DefaultProps } from 'react-router-dom';
import { FC, ReactNode } from 'react';

export enum RouteType {
  PROTECTED = 'protected',
  AUTH = 'auth',
  PUBLIC = 'public',
}

export type RouteProps = {
  type: RouteType;
  exact?: boolean;
  Component: FC<{ children?: ReactNode }>;
} & DefaultProps;
