import React from 'react';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';
import { Draft } from '@shared/components/make-post/hooks/useDraftActions';
import MakePostTags from '@shared/components/make-post/components/make-post-tags/MakePostTags';
import MakePostEditorTabs from './MakePostEditorTabs';
import MakePostTitleEditor from './MakePostTitleEditor';
import MakePostBodyEditor from './MakePostBodyEditor';
import { FeedTypes } from '../../../../../../models/Feed';
import MakePoll from '../make-poll/MakePoll';

const MakePostEditor: React.FC<{
  showEmojiMenu: boolean;
  closeEmojiMenu: () => void;
}> = ({ showEmojiMenu, closeEmojiMenu }) => {
  const defaultValue = React.useContext(DefaultValueContext) as Draft;
  const [selectedType, setSelectedType] = React.useState<FeedTypes>(
    defaultValue.type as FeedTypes,
  );

  return (
    <div className="flex-1 w-full">
      <MakePostEditorTabs
        setSelectedType={setSelectedType}
        defaultValue={defaultValue.type as FeedTypes}
      />
      <MakePostTitleEditor selectedType={selectedType} />
      {selectedType === FeedTypes.POLL ? (
        <MakePoll />
      ) : (
        <MakePostBodyEditor
          hasLink={false}
          showEmojiMenu={showEmojiMenu}
          closeEmojiMenu={closeEmojiMenu}
        />
      )}
      <MakePostTags />
    </div>
  );
};

export default MakePostEditor;
