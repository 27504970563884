import ImageCropUpload from '@shared/components/Image-crop-upload/ImageCropUpload';
import * as React from 'react';
import {
  EventDraftAction,
  EventDraftContext,
  EventDraftValidationAction,
} from '@view/create-event/hooks/useDraftEvent';
import { EventDefaultValueContext } from '@view/create-event/hooks/useDefaultValues';

const CreateEventCoverUploader: React.FC<{
  validate: (value: boolean) => void;
}> = ({ validate }) => {
  const defaultValues = React.useContext(EventDefaultValueContext);
  const [cover, setCover] = React.useState<string>(defaultValues?.cover || '');
  const draftContext = React.useContext(EventDraftContext) as EventDraftAction &
    EventDraftValidationAction;

  React.useEffect(() => {
    draftContext.setCover(cover || '');
    validate(
      draftContext.validateCover({
        cover,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cover]);

  return <ImageCropUpload setImage={setCover} defaultImage={cover} />;
};

export default CreateEventCoverUploader;
