import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { SocialEvent } from './socialEvents.model';
import {
  deleteSocialEvent,
  fetchMySocialEvents,
  fetchRecommendedSocialEvents,
  fetchSocialEvent,
  fetchSocialEventParticipants,
  joinSocialEvent,
  leaveSocialEvent,
} from './socialEvents.thunk';
import {
  convertSocialEventParticipantsResponse,
  convertSocialEventResponse,
  convertSocialEventsResponse,
} from './socialEvents.factory';

export const socialEventAdapter = {
  myEvents: createEntityAdapter<SocialEvent>(),
  recommendedEvents: createEntityAdapter<SocialEvent>(),
};

const getInitialState = () => ({
  myEvents: socialEventAdapter.myEvents.getInitialState(),
  recommendedEvents: socialEventAdapter.recommendedEvents.getInitialState(),
});

const sharedReducer = {
  updateEvent: (state, id, changes) => {
    const mySocialEvent = state.myEvents.entities[id];
    const recommendedSocialEvent = state.recommendedEvents.entities[id];

    if (mySocialEvent || recommendedSocialEvent)
      (mySocialEvent
        ? socialEventAdapter.myEvents
        : socialEventAdapter.recommendedEvents
      ).updateOne(mySocialEvent ? state.myEvents : state.recommendedEvents, {
        id,
        changes: {
          ...(mySocialEvent || recommendedSocialEvent),
          ...changes,
        },
      });
    else
      (changes.is_member
        ? socialEventAdapter.myEvents
        : socialEventAdapter.recommendedEvents
      ).addOne(
        changes.is_member ? state.myEvents : state.recommendedEvents,
        changes,
      );
  },
};

const socialEventSlice = createSlice({
  name: 'socialEvent',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchMySocialEvents.fulfilled, (state, action) => {
        socialEventAdapter.myEvents.setAll(
          state.myEvents,
          convertSocialEventsResponse(action.payload.social_events),
        );
      })
      .addCase(fetchRecommendedSocialEvents.fulfilled, (state, action) => {
        socialEventAdapter.recommendedEvents.setAll(
          state.recommendedEvents,
          convertSocialEventsResponse(action.payload.social_events),
        );
      })
      .addCase(fetchSocialEventParticipants.fulfilled, (state, action) =>
        sharedReducer.updateEvent(state, action.meta.arg.eventId, {
          members: convertSocialEventParticipantsResponse(action.payload),
        }),
      )
      .addCase(leaveSocialEvent.fulfilled, (state, action) =>
        sharedReducer.updateEvent(state, action.meta.arg.eventId, {
          userIsJoined: false,
        }),
      )
      .addCase(joinSocialEvent.fulfilled, (state, action) =>
        sharedReducer.updateEvent(state, action.meta.arg.eventId, {
          userIsJoined: true,
        }),
      )
      .addCase(fetchSocialEvent.fulfilled, (state, action) => {
        const socialEvent = action.payload;
        sharedReducer.updateEvent(state, socialEvent.id, {
          ...convertSocialEventResponse(socialEvent),
        });
      })
      .addCase(deleteSocialEvent.fulfilled, (state, action) => {
        socialEventAdapter.myEvents.removeOne(
          state.myEvents,
          action.meta.arg.eventId,
        );
        socialEventAdapter.recommendedEvents.removeOne(
          state.recommendedEvents,
          action.meta.arg.eventId,
        );
      }),
});

export default {
  reducers: socialEventSlice.reducer,
  adapter: socialEventAdapter,
};
