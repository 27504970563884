import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { SocialEvent } from '@data/social-events/socialEvents.model';
import endpoints, { endpointIDReplacer } from '@view/routes/endpoints';

export type SocialEventDetails = {
  day: string;
  month: string;
  year: string;
  title: string;
  description: string;
  cover: string;
  start: string;
  end: string;
  timezone: string;
};

const useSocialEvent = (
  event?: SocialEvent,
):
  | (SocialEventDetails & {
      onClick: () => () => void;
    })
  | null => {
  const navigate = useNavigate();

  if (!event) return null;

  return {
    day: moment(event?.time.startTime).format('D'),
    month: moment(event?.time.startTime).format('MMM'),
    year: moment(event?.time.startTime).format('YYYY'),
    title: event?.info.title,
    description: event?.info.description,
    cover: event?.info.cover,
    start: moment(event?.time.startTime).format('hh:mm A'),
    end: moment(event?.time.endTime).format('hh:mm A'),
    onClick: () => () =>
      navigate(endpointIDReplacer(endpoints.SOCIAL_EVENT, event?.id)),
    timezone: moment
      .tz(
        event?.time?.endTime,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      )
      .zoneAbbr(),
  };
};

export default useSocialEvent;
