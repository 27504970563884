import * as React from 'react';

const HeaderContent: React.FC<
  React.PropsWithChildren<
    React.PropsWithChildren<{ title?: string; helperText?: string }>
  >
> = ({ title, helperText }) => {
  return (
    <div className="w-full pt-0 pl-5 pr-5 mx-auto my-0 text-center sm:pt-16 max-w-screen-lg">
      <h1 className="p-0 m-0 h1">{title}</h1>
      <span className="block mt-5 h2">{helperText}</span>
    </div>
  );
};
export default HeaderContent;
