import React from 'react';

export interface ContentListFilterContextValue {
  search: {
    setValue: (value: string) => void;
  };
  tabs: {
    options: string[];
    setValue: (value: number) => void;
  };
  sorts: {
    options: {
      label: string;
      ratio: number;
      key: string;
    }[];
    setValue: (s?: { index: number; direction: 'a' | 'd' }) => void;
  };
}

export const ContentListFilterContext: React.Context<ContentListFilterContextValue | null> =
  React.createContext<ContentListFilterContextValue | null>(null);

export const tabOptions = ['DRAFTS', 'PUBLISHED'];
