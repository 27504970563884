// @flow
import * as React from 'react';
import { ReactComponent as EmptyViewSvg } from '@assets/images/no_community.svg';
import { useTranslation } from 'react-i18next';
import { TextKeys } from '@lib/i18n/configureI18n';
import Button from '@mui/material/Button';

type CommunitiesListEmptyViewProps = {
  onReturn: () => void;
};

const CommunitiesListEmptyView: React.FC<CommunitiesListEmptyViewProps> = ({
  onReturn,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full  flex-col-center">
      <div className="w-full max-w-[330px]">
        <EmptyViewSvg className="w-full mt-2" />
      </div>
      <p className="mt-2 body2  max-w-[330px] mb-[80px]">
        {t(TextKeys.NoCommunities)}
      </p>
      <Button color="primary" variant="contained" onClick={onReturn}>
        {t(TextKeys.Return)}
      </Button>
    </div>
  );
};
export default CommunitiesListEmptyView;
