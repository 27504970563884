import React from 'react';
import {
  Draft,
  PollDraft,
} from '@shared/components/make-post/hooks/useDraftActions';
import { useSelector } from 'react-redux';
import TimelineSelectors from '@data/timeline/timeline.selector';
import { Feed } from '@data/timeline/timeline.model';
import {
  FeedPublishStatus,
  FeedSharingOption,
} from '../../../../../models/Feed';

export type DefaultValue = (Draft | PollDraft) & {
  pubStatus?: FeedPublishStatus;
};

export const DefaultValueContext = React.createContext<DefaultValue | null>(
  null,
);

const useDefaultValue = (id?: string) => {
  const post = useSelector((state: any) => {
    return TimelineSelectors.selectPost(state, id);
  });

  const getSelectedSharingOption = (post?: Feed): string => {
    const key = post?.info.visibility[0];
    if (key === FeedSharingOption.PUBLIC) return FeedSharingOption.PUBLIC;
    if (key === FeedSharingOption.CIRCLE) return 'your circle';
    if (key === FeedSharingOption.SELF) return 'only you';
    if (key?.includes('#'))
      return `${key?.split('#')[1]?.split('-')[0]} group members`;
    return '';
  };

  const getDefaultAttachments = (post?: Feed): string[] => {
    return post?.attachments?.files?.map((attachment) => attachment.link) || [];
  };

  const getDefaultValue = (post?: Feed): DefaultValue => ({
    title: post?.info.title || '',
    selectedSharingOption: getSelectedSharingOption(post),
    type: post?.info.category,
    body: post?.info.description || '',
    pubStatus: post?.info.pubStatus,
    attachments: getDefaultAttachments(post),
    options: post?.poll?.options,
    endTime: post?.poll?.endTime || '',
    tags: post?.tags || [],
    pollId: post?.poll?.id,
  });

  const defaultDraftValue: DefaultValue | null = getDefaultValue(post);

  return defaultDraftValue;
};

export default useDefaultValue;
