import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Profile } from './Profile.model';
import { fetchProfile } from './Profile.thunk';
import { convertProfileResponse } from './Profile.factory';

export const ProfileAdapter = createEntityAdapter<Profile>({
  selectId: (profile) => profile.id,
  sortComparer: (a, b) => (a.id < b.id ? -1 : 1),
});

const ProfileSlice = createSlice({
  name: 'profiles',
  initialState: ProfileAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      ProfileAdapter.upsertOne(
        state,
        convertProfileResponse(
          action.payload.profile,
          action.payload.community,
          action.payload.interest_tags,
          action.payload.primary_diagnoses,
        ),
      );
    }),
});

export default {
  reducers: ProfileSlice.reducer,
  adapter: ProfileAdapter,
};
