import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAppDispatch from '@data/useAppDispatch';
import { loginUser } from '@data/app/app.thunk';
import { useSnackbar } from 'notistack';

export interface userCredential {
  email: string;
  password: string;
}

export interface AuthController {
  handleSubmit: () => () => void;
  error?: {
    email: boolean;
    password: boolean;
    message: string;
  };
  onChangePassword: () => (e) => void;
  onChangeEmail: () => (e) => void;
  disabledSubmitButton: boolean;
  loading: boolean;
  handleForgotPasswordClick: () => () => void;
}

const useAuth = (): AuthController => {
  const [password, setPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<
    | {
        email: boolean;
        password: boolean;
        message: string;
      }
    | undefined
  >(undefined);
  const [disabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => () => {
    if (!loading) {
      if (email === '' || password === '') {
        setError({
          email: email === '',
          password: password === '',
          message:
            // eslint-disable-next-line no-nested-ternary
            email === '' && password === ''
              ? 'Email and password are required'
              : email === ''
              ? 'Email is required'
              : 'Password is required',
        });
        return;
      }
      setLoading(true);
      dispatch(
        loginUser({
          email,
          password,
        }),
      )
        .then((response) => {
          // TODO check  urgent
          setLoading(false);
          if (response.user) {
            navigate('/');
          }
        })
        .catch((error) => {
          setLoading(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  };

  const onChangePassword = () => (e) => {
    setPassword(e.target.value);
  };

  const onChangeEmail = () => (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPasswordClick = () => () => {
    navigate(`/forgot-password`);
  };

  return {
    handleSubmit,
    onChangePassword,
    onChangeEmail,
    error,
    disabledSubmitButton: disabled,
    loading,
    handleForgotPasswordClick,
  };
};

export default useAuth;
