import * as React from 'react';
import useAppDispatch from '@data/useAppDispatch';
import { submitReviews } from '@data/timeline/timeline.thunk';

export const ReviewPostContext = React.createContext<{
  onApprove: (i: number) => void;
  onReject: (i: number) => void;
  onRevert: (i: number) => void;
  onSubmit: (i: number) => void;
  submitting: boolean;
  reviews: { status: 'accepted' | 'rejected'; id: number }[];
  reset: () => void;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onApprove: (i: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onReject: (i: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRevert: (i: number) => {}, // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: (i: number) => {},
  submitting: false,
  reviews: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  reset: () => {},
});

const useReviewPosts = () => {
  const [reviews, setReviews] = React.useState<
    { status: 'accepted' | 'rejected'; id: number }[]
  >([]);
  const [review, setReview] = React.useState<
    { status: 'accepted' | 'rejected' | 'remove'; id: number } | undefined
  >(undefined);
  const [status, setStatus] = React.useState<
    'reviewing' | 'submitting' | 'pending'
  >('reviewing');

  const dispatch = useAppDispatch();

  const onApprove = React.useCallback((id: number) => {
    setReviews((reviews) => [
      ...(reviews.filter((r) => r.id !== id) as {
        status: 'rejected';
        id: number;
      }[]),
      { status: 'accepted', id },
    ]);
  }, []);

  const onReject = React.useCallback((id: number) => {
    setReviews((reviews) => [
      ...(reviews.filter((r) => r.id !== id) as {
        status: 'accepted';
        id: number;
      }[]),
      { status: 'rejected', id },
    ]);
  }, []);

  const onRevert = React.useCallback((id: number) => {
    setReviews((reviews) => reviews.filter((r) => r.id !== id));
  }, []);

  React.useEffect(() => {
    console.log(reviews);
  }, [reviews]);

  React.useEffect(() => {
    if (status === 'submitting') {
      dispatch(submitReviews({ reviews })).then(() => {
        setStatus('reviewing');
      });
      setStatus('pending');
    }
    if (status === 'pending') {
      setReviews([]);
    }
  }, [dispatch, reviews, status]);

  const onSubmit = React.useCallback(
    () => () => setStatus('submitting'),
    [setStatus],
  );

  const onInstanceSubmit = React.useCallback(
    () => (id: number) => {
      dispatch(submitReviews({ reviews: [{ id, status: 'accepted' }] })).then(
        () => {
          setStatus('reviewing');
        },
      );
      setStatus('pending');
    },
    [dispatch],
  );

  const reset = React.useCallback(() => {
    setReviews([]);
  }, []);

  return {
    onApprove,
    onReject,
    onRevert,
    onSubmit: onInstanceSubmit(),
    submitting: status !== 'reviewing',
    reviews,
    reset,
  };
};

export const ReviewPostProvider = ({ children }) => {
  const contextValues = useReviewPosts();
  return (
    <ReviewPostContext.Provider value={contextValues}>
      {children}
    </ReviewPostContext.Provider>
  );
};

export const useReviewPostContext = () => React.useContext(ReviewPostContext);

export default useReviewPosts;
