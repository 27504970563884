// @flow
import * as React from 'react';
import { Button, Dialog } from '@mui/material';
import moment, { Moment } from 'moment-timezone';
import TimePicker from '@shared/components/make-post/components/schedule-post-dialog/components/TimePicker';

type SetTimeDialogProps = {
  open: boolean;
  label?: string;
  setTime: (time: Moment) => void;
  onConfirm: () => void;
  timeZone?: string;
};

const SetTimeDialog: React.FC<SetTimeDialogProps> = (props) => {
  const { open, label, setTime, onConfirm, timeZone } = props;
  const [time, _setTime] = React.useState<{ hour; minute; timezone }>();

  const handleConfirm = () => () => {
    if (time) {
      setTime(
        moment(new Date())
          .set('hour', time.hour)
          .set('minute', time.minute)
          .set('second', 0)
          .tz(time.timezone),
      );
      onConfirm();
    }
  };

  return (
    <Dialog open={open} onClose={onConfirm}>
      <div className="w-[540px] h-[240px] pr-[30px] pl-[30px]">
        <h3 className="mt-6 mb-4 h3">{label}</h3>
        <TimePicker defaultValue={{ timeZone }} setTime={_setTime} />
        <div className="flex justify-end w-full mt-4">
          <Button
            className="w-[150px]"
            color="primary"
            variant="contained"
            onClick={handleConfirm()}
          >
            confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default SetTimeDialog;
