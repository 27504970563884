import { combineReducers } from 'redux';

import timelineRedux from '@data/timeline/timeline.reducer';
import supportGroupsRedux from '@data/support-groups/supportGroup.reducer';
import socialEvents from '@data/social-events/socialEvents.reducer';
import user from '@data/app/app.reducer';
import myCircle from '@data/my-circle/MyCircle.reducer';
import Chat from '@data/chat/Chat.reducer';
import Notification from '@data/notifications/Notification.reducer';
import Contents from '@data/contents/Contents.reducer';
import Profiles from '@data/profiles/Profile.reducer';
import { logoutUser } from '@data/app/app.thunk';

const rootReducer = () =>
  combineReducers({
    app: user?.reducers,
    chat: Chat.reducers,
    myCircle: myCircle?.reducers,
    notifications: Notification?.reducers,
    supportGroups: supportGroupsRedux?.reducers,
    socialEvents: socialEvents?.reducers,
    timeline: timelineRedux?.reducers,
    contents: Contents?.reducers,
    profiles: Profiles?.reducers,
  });

export default () => (state, action) => {
  const minVersion = process.env.REACT_APP_MIN_VERSION || '0.0.0';

  if (
    action.type === logoutUser.fulfilled.type ||
    action.type === logoutUser.rejected.type ||
    !state?.app?.version ||
    state?.app?.version < minVersion
  ) {
    return rootReducer()(undefined, action);
  }
  return rootReducer()(state, action);
};
