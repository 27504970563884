import { Profile } from './Profile.model';

export const getStory = (
  response: any,
  community: string,
): string | undefined => {
  try {
    return response.story[`${community}_story`].user_story;
  } catch (e) {
    return undefined;
  }
};

export const convertProfileResponse = (
  response: any,
  community: string,
  interests: any[],
  diagnoses: any[],
): Profile => {
  return {
    id: response.user_id,
    age: {
      from: response.age_from,
      to: response.age_to,
    },
    country: response.country,
    avatar: response.img_url,
    karma: response.karma,
    nickname: response.nick_name,
    story: getStory(response, community),
    interests: interests.map((interest) => interest.name),
    diagnoses: diagnoses.map((diagnose) => diagnose.text),
  };
};
