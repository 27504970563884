import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip, TextField } from '@mui/material';
import { TagsContext } from '@hooks/useTags';
import {
  Draft,
  DraftActions,
  DraftContext,
} from '@shared/components/make-post/hooks/useDraftActions';
import { DefaultValueContext } from '@shared/components/make-post/hooks/useDefaultValue';

const MakePostTags: React.FC = () => {
  const { setTags } = React.useContext(DraftContext) as DraftActions;
  const defaultValue = React.useContext(DefaultValueContext) as Draft;

  const { tags, loadTags } = React.useContext(TagsContext);

  const [tagsLoaded, setTagsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!tagsLoaded) {
      loadTags();
      setTagsLoaded(true);
    }
  }, [loadTags, tagsLoaded]);

  const [selectedTags, setSelectedTags] = React.useState(
    defaultValue.tags || ([] as string[]),
  );

  React.useEffect(() => {
    setTags(selectedTags || []);
  }, [setTags, selectedTags]);

  const handleChangeTags = (event, newValue) => {
    const selectedTags = newValue
      .map((tag) => {
        return tags.find((t) => t.name === tag)?.id;
      })
      .filter((tag) => tag);

    setSelectedTags(selectedTags);

    // adding created tags (NOT SUPPORTED ANYMORE)
    // if (newValue.length > selectedTags.length) {
    //   const newTags = newValue.filter(
    //     (tag) => !tags.find((t) => t.name === tag),
    //   );
    //
    //   newTags.forEach((tag) => {
    //     dispatch(
    //       createTag({
    //         name: tag,
    //       } as Tag),
    //     ).then((res) => {
    //       setSelectedTags([...selectedTags, res.id]);
    //     });
    //   });
    // }
  };

  const getDefaultTags = () => {
    return (
      defaultValue.tags
        ?.map((tag) => {
          return tags.find((t) => String(t.id) === tag)?.name || '';
        })
        .filter((tag) => tag) || []
    );
  };

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={tags.map((option) => option.name)}
      // freeSolo
      className="p-2"
      defaultValue={getDefaultTags()}
      onChange={handleChangeTags}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            className={`${
              getTagProps({ index }).className
            } !text-sm !font-normal !leading-5 !font-['Roboto']`}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Tags"
          placeholder="Add tags to what you’re sharing"
        />
      )}
    />
  );
};

export default MakePostTags;
