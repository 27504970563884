import useAppDispatch from '@data/useAppDispatch';
import React from 'react';
import { fetchTags } from '@data/contents/Contents.thunk';
import { useSelector } from 'react-redux';
import { ContentsSelectors } from '@data/contents/Contents.selectors';
import { Tag } from '@data/contents/Contents.model';
import { AppSelector } from '@data/app/app.selector';

export const TagsContext = React.createContext<{
  tags: Tag[];
  loadTags: () => void;
} | null>(null);
const useTags = () => {
  const dispatch = useAppDispatch();

  const isUserAuthenticated = useSelector(AppSelector.isUserAuthenticated);

  const selectIsCommunitySelected = useSelector(
    AppSelector.selectIsCommunitySelected,
  );

  const tags = useSelector((state: any) =>
    ContentsSelectors.tags.selectAll(state.contents),
  );

  const loadTags = React.useCallback(() => {
    if (isUserAuthenticated && selectIsCommunitySelected) {
      dispatch(fetchTags());
    }
  }, [dispatch, isUserAuthenticated, selectIsCommunitySelected]);

  return [tags, loadTags] as [Tag[], typeof loadTags];
};

export default useTags;
