import React, { ReactNode } from 'react';
import { RouteProps, RouteType } from '@view/routes/types';
import { Route, Routes } from 'react-router-dom';
import RedirectSite from '@view/routes/RedirectSite';
import { CuratioApp } from '@view/app-selection/utils/appSelection.utils';
import routes from '@view/routes/routes';
import * as Sentry from '@sentry/react';

type AppRoutesProps = {
  isLoggedIn: boolean;
  app: CuratioApp;
  checkAuth: (Component: React.FC) => any;
};

const AppRoutes: React.FC<AppRoutesProps> = ({
  isLoggedIn,
  app,
  checkAuth,
}) => {
  const appRoutes = React.useMemo(() => routes(), []);
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  return (
    <SentryRoutes>
      {appRoutes.map<ReactNode>(
        ({ Component, type, ...rest }: RouteProps, i) => {
          // String(i) is a cheap way to get around the rule to not use
          // index as key. Will solve this problem later.

          if (type === RouteType.PROTECTED && Component) {
            return (
              <Route key={String(i)} {...rest} element={checkAuth(Component)} />
            );
          }

          if (type === RouteType.AUTH && Component)
            return (
              <Route
                key={String(i)}
                {...rest}
                element={
                  !isLoggedIn || !app ? <Component /> : RedirectSite('/')
                }
              />
            );

          return <Route key={String(i)} element={<Component />} {...rest} />;
        },
      )}
    </SentryRoutes>
  );
};

export default React.memo(AppRoutes);
