import Timeline from '@shared/components/timeline/Timeline';
import { fetchTimeline } from '@data/timeline/timeline.thunk';
import TimelineSelectors from '@data/timeline/timeline.selector';
import { TimeLines } from '@data/timeline/timeline.model';
import MakePostContainer from '@shared/components/make-post/components/make-post-container/MakePostContainer';
import * as React from 'react';
import { RefObject } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';
import { MakePostDialogOpeningModeAction } from '@shared/components/make-post/models/MakePostAction';
import MakePostDialogProvider from '@shared/components/make-post/MakePostDialogProvider';

const MainTimeline: React.FC<{
  scroller: RefObject<HTMLDivElement> | null;
}> = ({ scroller }) => {
  const [openDialog, setOpenDialog] =
    React.useState<MakePostDialogOpeningModeAction>({
      mode: 'close',
    });

  const handleEditPost = React.useCallback(
    (id: string) => () => {
      setOpenDialog({ id, mode: 'open' });
    },
    [],
  );

  const handleOpenMakePostDialog = React.useCallback(
    () => (mode?: 'photo' | 'video') => {
      setOpenDialog({ mode: mode || 'open' });
    },
    [],
  );

  const handleCloseMakePostDialog = React.useCallback(
    () => () => {
      setOpenDialog({ mode: 'close' });
    },
    [],
  );

  return (
    <Timeline
      timelineFetcher={
        fetchTimeline as unknown as AsyncThunk<never, { page: number }, never>
      }
      handleEditPost={handleEditPost}
      selector={TimelineSelectors.published.selectIds}
      hasMoreSelector={TimelineSelectors.published.hasMoreSelector}
      pageSelector={TimelineSelectors.published.pageSelector}
      timeline={TimeLines.AGGREGATED}
      scroller={scroller}
    >
      <MakePostContainer onClick={handleOpenMakePostDialog()} />
      <MakePostDialogProvider
        open={openDialog}
        closeDialog={handleCloseMakePostDialog()}
      />
    </Timeline>
  );
};

MainTimeline.defaultProps = {
  scroller: null,
};

export default MainTimeline;
