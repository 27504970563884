import useAppDispatch from '@data/useAppDispatch';
import { fetchFriendsBySearch } from '@data/my-circle/MyCircle.thunk';
import React from 'react';

const useFriends = (searchKeywords: string) => {
  const [friends, setFriends] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!searchKeywords) {
      setFriends([]);
      return;
    }
    setLoading(true);

    dispatch(fetchFriendsBySearch({ search: searchKeywords }))
      .then((res) => {
        setFriends(
          res.users.map((user) => {
            return {
              name: user.profile.nick_name,
              avatar: user.profile.img_url,
              id: user.id,
            };
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, searchKeywords]);

  return { friends, loading };
};

export default useFriends;
