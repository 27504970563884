import { createSelector } from '@reduxjs/toolkit';
import { notificationAdapter } from './Notification.reducer';

export const NotificationSelector = {
  ...notificationAdapter.getSelectors(),
  getUnreadCount: createSelector(
    (state) => state.notifications,
    (notifications) => notifications.unreadCount,
  ),
};
