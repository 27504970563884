import useAppDispatch from '@data/useAppDispatch';
import * as React from 'react';
import {
  fetchContents,
  fetchContentsByCategory,
} from '@data/contents/Contents.thunk';
import { LoadingState } from '../../../models/Loading';

const useLoadContents = (args?: { fetchByCategory?: boolean }) => {
  const dispatch = useAppDispatch();

  const [loadContents, setLoadContents] = React.useState<LoadingState>(
    LoadingState.IDLE,
  );

  React.useEffect(() => {
    if (loadContents === LoadingState.IDLE) {
      setLoadContents(LoadingState.LOADING);
      (args?.fetchByCategory
        ? dispatch(fetchContentsByCategory({}))
        : dispatch(fetchContents({ saveToStore: true }))
      )
        .then(() => {
          setLoadContents(LoadingState.SUCCEEDED);
        })
        .catch(() => {
          setLoadContents(LoadingState.FAILED);
        });
    }
  }, [args?.fetchByCategory, dispatch, loadContents]);

  return loadContents;
};

export default useLoadContents;
