// @flow
import * as React from 'react';
import { SupportGroupMember } from '@data/support-groups/supportGroups.model';
import { SocialEventMember } from '@data/social-events/socialEvents.model';
import { DropDownItem } from '@shared/components/dropDown-menu/DropDownMenu';
import Info from './components/Info';
import GroupEventView from './GroupEventView';

type GroupEventViewProps = {
  type?: 'group' | 'event';
  title?: string;
  cover?: string;
  description?: string;
  onJoin: () => () => void;
  onLeave: () => () => void;
  time?: {
    startTime: string;
    endTime: string;
  };
  loading?: boolean;
  numberOfMembers?: number;
  admins?: string[];
  admin?: string;
  members?: SupportGroupMember[] | SocialEventMember[];
  userIsJoined?: boolean;
  location?: string;
  onLoading?: boolean;
  userIsAdmin?: boolean;
  options?: DropDownItem[];
  onOptionClick?: (option: DropDownItem) => void;
};

const GroupEventDetailsView: React.FC<GroupEventViewProps> = ({
  cover,
  loading,
  type,
  title,
  description,
  numberOfMembers,
  admins,
  admin,
  members,
  userIsJoined,
  onJoin,
  onLeave,
  onLoading,
  time,
  location,
  userIsAdmin,
  options,
  onOptionClick,
}) => {
  return (
    <GroupEventView cover={cover} loading={loading}>
      <Info
        type={type}
        title={title}
        description={description}
        numberOfMembers={numberOfMembers}
        admins={admins}
        admin={admin}
        members={members}
        userIsJoined={userIsJoined}
        onJoin={onJoin}
        onLeave={onLeave}
        onLoading={onLoading}
        time={time}
        location={location}
        loading={loading}
        userIsAdmin={userIsAdmin}
        options={options}
        onOptionClick={onOptionClick}
      />
    </GroupEventView>
  );
};

GroupEventDetailsView.defaultProps = {
  type: 'group',
  title: 'unknown title',
  cover: '',
  description: '',
  time: {
    startTime: 'unset starting time',
    endTime: 'unset ending time',
  },
  loading: false,
  numberOfMembers: 0,
  admins: [],
  admin: undefined,
  members: [],
  userIsJoined: false,
  location: 'unknown location',
  onLoading: false,
  userIsAdmin: false,
  options: [],
  onOptionClick: undefined,
};
export default GroupEventDetailsView;
