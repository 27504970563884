import * as React from 'react';
import { Card } from '@mui/material';
import { SocialEvent } from '@data/social-events/socialEvents.model';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import EventInfo from '@view/home-page/components/event-view/EventInfo';
import useSocialEvent, { SocialEventDetails } from './hooks/useSocialEvent';

type EventCardProps = {
  event?: SocialEvent;
  loading?: boolean;
};

const EventCard: React.FC<EventCardProps> = ({ event, loading }) => {
  const details = useSocialEvent(event);

  const renderLoading = React.useCallback(() => {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width="100%" height={86} />
        <Skeleton variant="text" className="w-full" />
        <Skeleton variant="text" className="w-full" />
      </Stack>
    );
  }, []);

  return (
    <Card
      className="w-full cursor-pointer sm:w-[258px] h-[182px] m-1.5"
      onClick={details?.onClick()}
    >
      {loading || details === null ? (
        renderLoading()
      ) : (
        <EventInfo details={details as SocialEventDetails} />
      )}
    </Card>
  );
};

EventCard.defaultProps = {
  event: undefined,
  loading: false,
};

export default EventCard;
