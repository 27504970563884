import { Content } from '@data/contents/Contents.model';

import React, { useState } from 'react';
import {
  ContentListFilterContext,
  tabOptions,
} from '@view/content-management-system/context/ContentListFilterContext';
import { useSelector } from 'react-redux';
import { ContentsSelectors } from '@data/contents/Contents.selectors';
import useIsMobile from '@hooks/use-breakpoint/useIsMobile';
import useLoadContents from '@view/discover-page/hooks/useLoadContents';
import { LoadingState } from '../../../models/Loading';

type useContentListFilterReturnType = {
  ContentListFilterProvider: React.FC<React.PropsWithChildren<unknown>>;
  filteredList: Content[];
  loadContents: LoadingState;
};

const useContentListFilter = (): useContentListFilterReturnType => {
  const [filteredList, setFilteredList] = useState<Content[]>([]);

  const [search, setSearch] = useState<string>('');
  const [tab, setTab] = useState<number>(0);
  const [sort, setSort] = useState<
    | {
        index: number;
        direction: 'a' | 'd';
      }
    | undefined
  >(undefined);

  const unfilteredList = useSelector((state: any) =>
    ContentsSelectors.contents.selectAll(state.contents),
  );

  const loadContents = useLoadContents();

  const isMobile = useIsMobile();

  const sortOptions = React.useMemo(
    () => [
      {
        label: 'Type',
        ratio: isMobile ? 0.33 : 0.12,
        key: 'type',
      },
      {
        label: 'Title',
        ratio: isMobile ? 0.33 : 0.58,
        key: 'title',
      },
      {
        label: 'created',
        ratio: 0.1,
        key: 'createdAt',
      },
      // {
      //   label: 'published',
      //   ratio: 0.1,
      //   key: 'publishedAt',
      // },
    ],
    [isMobile],
  );

  React.useEffect(() => {
    setFilteredList(
      unfilteredList
        .filter((content: Content) => {
          return (
            content.status?.toUpperCase().at(0) ===
            tabOptions[tab].toUpperCase().at(0)
          );
        })
        .filter((content: Content) => {
          if (search) {
            return content.title?.toLowerCase().includes(search.toLowerCase());
          }
          return true;
        })
        .sort((a: Content, b: Content) => {
          const s = sort !== undefined ? sortOptions[sort.index] : undefined;
          if (!s) return a.id > b.id ? 1 : -1;
          if (sort?.direction === 'a')
            return a[s.key].toUpperCase() > b[s.key].toUpperCase() ? 1 : -1;
          return a[s.key].toUpperCase() < b[s.key].toUpperCase() ? 1 : -1;
        }),
    );
  }, [unfilteredList, search, tab, sort, sortOptions]);

  const contentListFilterProviderValue = React.useMemo(
    () => ({
      search: {
        setValue: setSearch,
      },
      tabs: {
        options: tabOptions,
        setValue: setTab,
      },
      sorts: {
        options: sortOptions,
        setValue: setSort,
      },
    }),
    [sortOptions],
  );

  const ContentListFilterProvider = React.useMemo(
    (): React.FC<React.PropsWithChildren<unknown>> =>
      ({ children }) => {
        return (
          <ContentListFilterContext.Provider
            value={contentListFilterProviderValue}
          >
            {children}
          </ContentListFilterContext.Provider>
        );
      },
    [contentListFilterProviderValue],
  );

  return {
    ContentListFilterProvider,
    filteredList,
    loadContents,
  };
};

export default useContentListFilter;
