const getAnimationStyle = () => ({
  display: 'inline-block',
  margin: '5px auto 0',
  fontSize: 10,
  position: 'relative',
  textIndent: '-9999em',
  borderTop: `3px solid rgba(0, 170, 211, 0.1)`,
  borderRight: `3px solid rgba(0, 170, 211, 0.1)`,
  borderBottom: `3px solid rgba(0, 170, 211, 0.1)`,
  borderLeft: `3px solid #00AAD3`,
  transform: 'translateZ(0)',
  animation: `spin 1.1s infinite linear`,
  borderRadius: '50%',
  width: '100%',
  height: '100%',
  // @ts-ignore
  '&:after': {
    borderRadius: '50%',
    width: '10em',
    height: '10em',
  },
});

export default getAnimationStyle;
