// @flow
import * as React from 'react';
import Header from './components/Header';
import WithSideBar from '../with-side-bar/WithSideBar';

type GroupEventViewProps = {
  cover?: string;
  loading?: boolean;
};

const GroupEventView: React.FC<React.PropsWithChildren<GroupEventViewProps>> = (
  props,
) => {
  const { cover, children, loading } = props;

  return (
    <WithSideBar>
      <div className="flex flex-col items-center w-full h-full overflow-auto sm:overflow-hidden">
        <Header cover={cover} loading={loading} />
        {children}
      </div>
    </WithSideBar>
  );
};

GroupEventView.defaultProps = {
  cover: '',
  loading: false,
};

export default React.memo(GroupEventView);
