// @flow
import { TextKeys } from '@lib/i18n/configureI18n';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { IconButton, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HDivider from '@shared/components/divider/HDivider';
import { EntityId } from '@reduxjs/toolkit';

type HeaderProps = {
  onSearch: (search: string) => void;
  selectConversation: (conversationId: EntityId | null) => void;
};

const Header: React.FC<HeaderProps> = ({ onSearch, selectConversation }) => {
  const { t } = useTranslation();

  const renderSearchBar = () => {
    return (
      <div className="flex w-full h-6 ml-3 mr-2 border border-secondary-background rounded-md">
        <SearchIcon className="h-5 mb-2 ml-1 mr-2 mt-0.5 text-on-secondary" />
        <Input
          disableUnderline
          autoFocus
          placeholder="Search friends"
          fullWidth
          inputProps={{
            maxLength: 80,
          }}
          onChange={(e) => onSearch(e.target.value)}
          classes={{
            input: '!text-on-primary !text-[14px]',
          }}
          className="flex-1 h-full flex-col-center"
        />
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between w-full pt-2 pb-2 pl-4 pr-4 h-[40px]">
        <h6 className="h6 text-on-primary">{t(TextKeys.Chat)}</h6>
        {renderSearchBar()}

        <IconButton
          className="w-4 h-4 text-white rounded-md bg-brand-primary flex-col-center !p-3"
          onClick={() => selectConversation(null)}
        >
          <AddIcon />
        </IconButton>
      </div>
      <HDivider />
    </>
  );
};

export default Header;
